import React, { useState, useContext } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";

// icons
import SearchIcon from "@material-ui/icons/Search";

// styles
import "../../styles/Admin.css";

// dependends
import EditUser from "./EditUser";
import CreateUser from "./CreateUser";
import CreateRole from "../Roles/CreateRole";
import EditRole from "../Roles/EditRole";
import CreateLocation from "../../Location/CreateLocation";
import EditLocation from"../../Location/EditLocation";
import { AlertContext } from "../../../Context/AlertContextProvider";
import { Autocomplete } from "@material-ui/lab";

const ManageUsers = (props) => {
  //   let { users } = props;
  const [users, setUsers] = useState(props.users);
  const [apiUsers] = useState(props.users);
  const [roles, setRoles] = useState(props.roles)
  const [locations, setLocations] = useState(props.locations);
  const [apiRoles] = useState(props.roles);
  const [apiLocations] = useState(props.locations);
  const [editUser, setEditUser] = useState({});
  const [editRole, setEditRole] = useState({});
  const [editLocation, setEditLocation] = useState({});
  const [userValue, setUserValue] = useState("");
  const [roleValue, setRoleValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [open] = useState(false);
  const { setUserEditOpen, userEditOpen } = useContext(
    AlertContext
  );
  const [value, setValue] = useState(0);
  const accessTypeCds = {a: "Admin", rw:"Read/Write", ro:"read Only", c:"Customer"};

  const handleUserTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabs = () => {
    return users ? (
        <div>
        <AppBar position="static" color="default">
            <Tabs
            value={value}
            onChange={handleUserTabChange}
            TabIndicatorProps={{style: {backgroundColor: "#FF682C", color: "#FF682C"}}}
            variant="scrollable"
            scrollButtons="auto"
            className="favorites__scroll-menu"
            >
                <Tab className="favorites_tabs" label="All"/>
                {Object.keys(accessTypeCds).map((cd, index) => {
                  return (
                        <Tab className="favorites_tabs" 
                        label={accessTypeCds[cd]} 
                        key={index} 
                        value={cd} 
                        />
                  )
                })}
            </Tabs>
        </AppBar>
    </div>
    ) 
    :
    (<div></div>)
  };

  const renderUsers = (users) => {
    return users ? (
      users.map((user, index) => {
        return value === 0 ? (
          <React.Fragment key={index}>
            <div className="admin__users-container-full">
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <ListItem onClick={() => handleUser(user)} key={user.userId} button>
                    <ListItemIcon>
                      <Avatar alt="profile picture" src={user.picture} />
                    </ListItemIcon>
                    <ListItemText
                      primary={user.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {user.userName}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={5}>
                <ListItem>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="h6"
                            color="textPrimary"
                          >
                            {user.last_login_dttm != null ? String(user.last_login_dttm).split(" ")[0] : "Never"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </div>

            {/* Mobile */}

            <div className="admin__users-container-mobile">
              <List>
                <ListItem onClick={() => handleUser(user)} key={user.userId} button>
                  <ListItemIcon>
                    <Avatar alt="profile picture" src={user.picture} />
                  </ListItemIcon>
                  <ListItemText primary={user.name} secondary={user.userName} />
                </ListItem>
                {/* <Divider /> */}
              </List>
            </div>
          </React.Fragment>
        ) :
        user.accessTypeCode === value && (
          <React.Fragment key={index}>
            <div className="admin__users-container-full">
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <ListItem onClick={() => handleUser(user)} key={user.userId} button>
                    <ListItemIcon>
                      <Avatar alt="profile picture" src={user.picture} />
                    </ListItemIcon>
                    <ListItemText
                      primary={user.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {user.userName}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={5}>
                <ListItem>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="h6"
                            color="textPrimary"
                          >
                            {user.last_login_dttm != null ? String(user.last_login_dttm).split(" ")[0] : "Never"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </div>

            {/* Mobile */}

            <div className="admin__users-container-mobile">
              <List>
                <ListItem onClick={() => handleUser(user)} key={user.userId} button>
                  <ListItemIcon>
                      <Avatar alt="profile picture" src={user.picture} />
                  </ListItemIcon>
                  <ListItemText primary={user.name} secondary={user.userName} />
                </ListItem>
                <Divider />
              </List>
            </div>
          </React.Fragment>
        );
      })
    ) : (
      <div>No Users</div>
    );
  };

  const renderRoles = (roles) => {
    return roles ? (
      roles.map((role) => {
        return (
          <div key={role.role_id}>
            <ListItem onClick={() => handleRole(role)} button>
              <ListItemText
                primary={role.role_name}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {role.role_description}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            {/* <Divider /> */}
          </div>
        );
      })
    ) : (
      <div>No Roles</div>
    );
  };

  const renderLocations = (locations) => {
    return locations ? (
      locations.map((location) => {
        return (
          <ListItem onClick={() => handleLocation(location)}  key={location.location_id} button>
            <ListItemText
              primary={location.location_name}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {location.location_description}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        );
      })
    ) : (
      <div>No Locations</div>
    );
  };

  const handleChange = (filter) => {
    if(!filter?.userId) return;
    setUserValue(filter ? filter.userId : 0);
  };

  const handleInputChange = (filter) => {
    const filterValue = filter.toLowerCase();
    const tempUsers = apiUsers.filter((user) =>
      user.name.toLowerCase().includes(filterValue)
    );
    setUsers(tempUsers ?? []);
  }

  const handleRoleSearch = (filter) => {
    setRoleValue(filter);
    const filterValue = filter.toLowerCase();
    const tempRoles = apiRoles.filter((role) =>
      role.role_name.toLowerCase().includes(filterValue)
    );
    setRoles(tempRoles);
  };

  const handleLocationSearch = (filter) => {
    setLocationValue(filter);
    const filterValue = filter.toLowerCase();
    const tempLocations = apiLocations.filter((location) =>
      location.location_name.toLowerCase().includes(filterValue)
    );
    setLocations(tempLocations);
  };

  const handleUser = (user) => {
    setEditUser(user);
    setEditRole("");
    setUserEditOpen(true);
  };

  const handleRole = (role) => {
    setEditRole(role);
    setEditUser("");
    setUserEditOpen(true);
  };

  const handleLocation = (location) => {
    setEditLocation(location);
    setEditUser("");
    setUserEditOpen(true);
  };

  return (
    // users
    <div>
      <div className="admin___users-content">
        <div className="admin__user-list">
          <Paper>
          <Typography
            className="header-text"
            variant="h4">
            Users
          </Typography>
          {renderTabs()}
            <List
              className="admin__users"
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItem>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText>
                  <Autocomplete
                    freeSolo
                    value={userValue}
                    options={users}
                    getOptionSelected={(option, value) => option.userId === value}
                    getOptionLabel={(option) => option.name || ""}
                    className="admin__search-users"
                    renderOption={(option) => (
                      <span>
                        <b>{option.name}</b>
                        <br></br>
                        {option.userName}
                        <br></br>
                        {option.email}
                      </span>
                    )}
                    renderInput={(params) => <TextField {...params} label="User Search" inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}/>}
                    onInputChange={(e, value) => handleInputChange(value)}
                  />
                </ListItemText>
                <CreateUser open={open} roles={props.roles} />
              </ListItem>
              <Grid container className="header-bg">
                <Grid item xs={6} sm={4} md={6} lg={3}>
                <div className="user__header-text">
                <Typography variant="h5">
                    User
                  </Typography>
                </div>
                </Grid>
                <Grid item xs={3} sm={4} md={4} lg={6}>
                <div className="user__header-text-last">
                  <Typography variant="h5">
                    Last Login
                  </Typography>
                </div>
                </Grid>
              </Grid>
              <div className="admin__users-list">{renderUsers(users)}</div>
            </List>
          </Paper>
        </div>

        {/* Roles */}
        <div style={{ width: 10 }}></div>
        <div className="admin__role-list">
          <Paper>
            <Typography
              className="header-text"
              variant="h4">
              Roles
            </Typography>
            <List
              className="admin__users"
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItem>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText>
                  <TextField
                    type="text"
                    label="Search"
                    value={roleValue}
                    onChange={(e) => handleRoleSearch(e.target.value)}
                    className="admin__search-users"
                  />
                </ListItemText>
                <CreateRole open={open} />
              </ListItem>
              <Grid container className='header-bg'>
                <Grid item xs={12}>
                <div className="user__header-text">
                <Typography variant="h5">
                    Title/Description
                  </Typography>
                </div>
                </Grid>
                {/* <Grid item xs={6}>
                <div className="user__header-text"></div>
                </Grid> */}
              </Grid>
              <div className="admin__users-list">{renderRoles(roles)}</div>
            </List>
          </Paper>
        </div>

        {/* Locations */}
        <div style={{ width: 10 }}></div>
        <div className="admin__location-list">
          <Paper>
          <Typography
            className="header-text"
            variant="h4">
            Locations
          </Typography>
            <List
              className="admin__users"
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItem>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText>
                  <TextField
                    type="text"
                    label="Search"
                    value={locationValue}
                    onChange={(e) => handleLocationSearch(e.target.value)}
                    className="admin__search-users"
                  />
                </ListItemText>
                <CreateLocation open={open} />
              </ListItem>
              <Grid container className="header-bg">
                <Grid item xs={12}>
                <div className="user__header-text">
                <Typography variant="h5">
                    Name/Description
                  </Typography>
                </div>
                </Grid>
                {/* <Grid item xs={6}>
                <div className="user__header-text"></div>
                </Grid> */}
              </Grid>
              <div className="admin__users-list">{renderLocations(locations)}</div>
            </List>
          </Paper>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}> 
        {editUser.userId && <EditUser user={editUser} roles={roles} open={userEditOpen}/>}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          {editRole.role_id && <EditRole user={users} role={editRole} open={userEditOpen}/>}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          {editLocation.location_id && <EditLocation user={users} location={editLocation} open={userEditOpen}/>}
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageUsers;
