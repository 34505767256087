import React, { useState } from "react";
import { Link } from "react-router-dom";

// MUI
import { Divider, 
Table, 
TableRow,
TableCell,
TableBody,
Button,
ListItem,
List,
ListItemText,
ListItemIcon,
Typography,
ListSubheader
 } from "@material-ui/core";

// Icons
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DescriptionIcon from '@material-ui/icons/Description';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WidgetsIcon from '@material-ui/icons/Widgets';
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import LinkIcon from '@material-ui/icons/Link';
import StyleIcon from '@material-ui/icons/Style';

// styles
import "../../styles/FileHistory.css";

// Actions
import { getProductsByFilename } from "../../../Actions";

const FileHistory = (props) => {
    const [value, setValue] = useState(props.value);
    const [bodyData, setBodyData] = useState(props.body);
    const [header, setHeader] = useState(props.header);
    const [listOpen, setListOpen] = useState(undefined);

    const handleClick = (index) => {
        listOpen === index ? setListOpen(undefined) : setListOpen(index);
    };

    const handleSelectedFile = (fileName) => {
        getProductsByFilename(encodeURIComponent(fileName))
            .then((result) => {
                setBodyData(result.data.latest);
                setHeader(['Product Page', 'Manufacturer Name', 'Style Number', 'Style Name', 'Material Class Code']);
                setValue(0);
            });
    };

    const body = () => {
        if (value === 1) {
        return (
            <TableBody>
        {bodyData.map((data, i ) => {
            return (
                <TableRow key={i}>
                    <TableCell className="table-cell">{data.partner}</TableCell>
                    <TableCell className="table-cell"> 
                        <Button 
                            className="files-by-partner-button"
                            variant="contained" 
                            onClick={() => handleSelectedFile(data.filename)}>{data.filename === undefined ?  "" : data.filename.includes("/") ? data.filename.split("/")[1].slice(-13) : data.filename.slice(-13)}
                        </Button>
                    </TableCell>
                    <TableCell className="table-cell">{data.productCounter}</TableCell>
                    <TableCell className="table-cell">{data.skuCounter}</TableCell>
                    <TableCell className="table-cell">{data.lastUpdateDateTime}</TableCell>
                </TableRow>
        )})}</TableBody>)
    } else {
        return (
            <TableBody>
        {bodyData.map((data, i ) => {
            return (
                <TableRow key={i}>
                    <TableCell className="table-cell"><a className="link" target="_blank" rel="noopener noreferrer" href={`/product/${data.productUUID}`}>View Product</a></TableCell>
                    <TableCell className="table-cell">{data.manufacturerName}</TableCell>
                    <TableCell className="table-cell">{data.styleNumber}</TableCell>
                    <TableCell className="table-cell">{data.styleName}</TableCell>
                    <TableCell className="table-cell">{data.materialClassCode}</TableCell>
                </TableRow>
        )})}</TableBody>)
    }};

    return (
        <div>
            <div className="fh__container-full">
                <Table>
                    <TableBody>
                        <TableRow>
                        {header.map((data, i) => {  
                            return (
                                    <TableCell className="header-cell" key={i}>{data}</TableCell>
                        )})}
                                    </TableRow>
                        
                    </TableBody>
                </Table> 
                <Divider style={{height:5}} />
                <Table>
                    {body()}
                </Table>   
            </div>

             <div className="fh__container-mobile">
                    <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                    <ListSubheader component="div" id="nested-list-subheader" className="fh__list-sub-header">
                        <div style={{width:"100%"}}>
                            <Typography variant="h6">
                                B2B Pricing Updates
                            </Typography>
                        </div>
                    </ListSubheader>
                }
                >
                {value === 0 ? bodyData.map((prod, index) => {
                    return (
                    <List key={index}>
                        <ListItem button onClick={() => handleClick(index)} className="fh__list-menu">
                        <ListItemIcon>
                        <Button ><InsertDriveFileIcon style={{color:"#FF682C"}}/></Button>
                        </ListItemIcon>
                            <ListItemText primary={prod.styleName} />
                        {listOpen === index ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                {listOpen === index ? 
                <Collapse in={true} timeout="auto" unmountOnExit>
                <List style={{backgroundColor:"#FFF"}}>
                <ListItem>
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText prinary="Manufacturer" secondary={prod.manufacturerName} />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <StyleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Style Number" secondary={prod.styleNumber}/>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <StyleIcon />
                    </ListItemIcon>
                    <ListItemText primary="UUID" secondary={prod.productUUID}/>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <StyleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Material Class" secondary={prod.materialClassCode}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                    <LinkIcon />
                    </ListItemIcon>
                    <Link to={`/product/${prod.productUUID}`} className="checkout__links" target="blank">
                    <ListItemText primary={"View product"} />
            </Link>
        </ListItem>
                </List>
                </Collapse> :
                <></>}
                    </List>
                )}) :
                bodyData.map((prod, index) => {
                    return (
                    <List key={index}>
                        <ListItem button onClick={() => handleClick(index)} className="fh__list-menu">
                        <ListItemIcon>
                        <Button ><InsertDriveFileIcon style={{color:"#FF682C"}}/></Button>
                        </ListItemIcon>
                            <ListItemText primary={prod.partner} />
                        {listOpen === index ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                {listOpen === index ? 
                <Collapse in={true} timeout="auto" unmountOnExit>
                <List style={{backgroundColor:"#FFF"}}>
                <ListItem>
                    <ListItemIcon>
                        <DescriptionIcon onClick={() => handleSelectedFile(prod.filename)}/>
                    </ListItemIcon>
                    <ListItemText primary="File Name"  />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <WidgetsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Product Count" secondary={prod.productCounter}/>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <WidgetsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sku Count" secondary={prod.skuCounter}/>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Updated" secondary={prod.lastUpdateDateTime}/>
                </ListItem>
                </List>
                </Collapse> :
                <></>}
                    </List>
                )})
                }
                </List>
                </div>                        
        </div>
    )
};

export default FileHistory;