import React, { useEffect, useState } from "react";

// Actions 
import { getUserCheckoutList, approveCheckout } from "../../Actions";

// Styles
import "../styles/ManageSamples.css";

// Icons
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

// MUI 
import {
Paper,
Tab,
Tabs,
Box} from "@material-ui/core";

// Dependencies
import PendingCheckout from "./PendingCheckout";
import CheckedOut from "./CheckedOut";
import PendingReturn from "./PendingReturn";
import CustomerFavorites from "../Samples/CustomerFavorites"

const ManageSamples = () => {

    const [checkoutList, setCheckoutList] = useState();
    const [value, setValue] = useState(0);
    const [tabValue, setTabValue] = useState("checkoutPending")

    useEffect(() => {
        getUserCheckoutList()
        .then((result) => {
            setCheckoutList(result.data.checkout_status);
        })
    },[value])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <div>{children}</div>
              </Box>
            )}
          </div>
        );
      }

    return (
        <div>
        <Paper className="checkout__tab-controller">
                <Tabs 
                TabIndicatorProps={{style: {backgroundColor: "#FF682C", color: "#FF682C"}}}
                value={tabValue} 
                onChange={handleTabChange} 
                aria-label="simple tabs example"
                className="manage-sample-tabs-full-screen"
                centered
                >
                    <Tab label="Checkouts Pending" value="checkoutPending" className="manage-sample-tabs"/>
                    <Tab label="Checked Out" value="checkedout" className="manage-sample-tabs"/>
                    <Tab label="Returns Pending" value="returnPending" className="manage-sample-tabs"/>
                    <Tab label="Customer Favorites" value="customerFavorites" className="manage-sample-tabs"/>
                </Tabs>

                <Tabs 
                TabIndicatorProps={{style: {backgroundColor: "#FF682C", color: "#FF682C"}}}
                value={tabValue} 
                onChange={handleTabChange} 
                aria-label="simple tabs example"
                className="manage-sample-tabs-mobile"
                orientation="vertical"
                centered
                >
                    <Tab label="Checkouts Pending" value="checkoutPending" className="manage-sample-tabs"/>
                    <Tab label="Checked Out" value="checkedout" className="manage-sample-tabs"/>
                    <Tab label="Returns Pending" value="returnPending" className="manage-sample-tabs"/>
                    <Tab label="Customer Favorites" value="customerFavorites" className="manage-sample-tabs"/>
                </Tabs>
            </Paper>
        {/* // Pending Checkouts */}
        <div className="manage-samples-content">
            <TabPanel value={tabValue} index="checkoutPending" style={{marginLeft: -20, marginRight: -20}}>
            {/* <div className="checkout-list"> */}
                    <PendingCheckout pendingList={checkoutList}/>
            {/* </div> */}
            </TabPanel>
            <TabPanel value={tabValue} index="checkedout" style={{marginLeft: -20, marginRight: -20}}>
            {/* <div className="checkout-list"> */}
                <CheckedOut checkedOutList={checkoutList}/>
            {/* </div> */}
            </TabPanel>
            <TabPanel value={tabValue} index="returnPending" style={{marginLeft: -20, marginRight: -20}}>
            {/* <div className="checkout-list"> */}
                <PendingReturn checkedOutList={checkoutList}/>
            {/* </div> */}
            </TabPanel>
            <TabPanel value={tabValue} index="customerFavorites" style={{marginLeft: -20, marginRight: -20}}>
              <CustomerFavorites/>
            </TabPanel>
        </div>
    </div>
    );
};

export default ManageSamples;