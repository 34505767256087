import React, { useEffect, useState } from "react";
import { baseUrl } from "../../Contants";
import axios from "axios";

// Dependencies
import Favorites from "./Favorites";
import Checkouts from "./Checkouts";

// MUI
import {
  Tab,
  Tabs,
  Paper,
  Box,
  Fab,
} from "@material-ui/core";

// Styles
import { CustomerHomeStyles } from "../styles/CustomerHome";
import ContactCard from "../LeadManagement/ContactCard";
import { Camera } from "@material-ui/icons";
import ScanningModal from "../LandingPage/Scanner/ScanningModal";

const CustomerHome = () => {
  const [selectedTab, setSelectedTab] = useState("Favorites");
  const [contactCardConfig, setContactCardConfig] = useState(undefined);
  const [tabArray, setTabArray] = useState(["Favorites", "Sample Checkouts"]);
  const [showScannerModal, setShowScannerModal] = useState(false);
  const CustHomeClasses = CustomerHomeStyles();

  useEffect(() => {
    const getContactCardConfig = async () => {
      const result = await axios.get(`${baseUrl}/api/account/rsa`);

      if (result.status !== 200) {
        setContactCardConfig(undefined);
        return;
      }

      if (Object.keys(result.data.rsa)?.length && !tabArray.includes("Contact Sales")) {
        setTabArray(prev => {
          return ([...prev, "Contact Sales"])
        });
      }
      setContactCardConfig({ ...result.data.rsa });
    }
    
    getContactCardConfig();
  }, [])

  const changeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const modifiedTabName = index.toLowerCase().replace(" ", "-");

    return (
      <Box
        p={3}
        role="tabpanel"
        hidden={value !== index}
        id={"wrapped-tabpanel-" + modifiedTabName}
        aria-labelledby={"wrapped-tab-" + modifiedTabName}
        {...other}
      >
        {children}
      </Box>
    );
  }

  const renderTabs = () => {
    const tabs = tabArray?.map(tab => {
      const modifiedTabName = tab.toLowerCase().replace(" ", "-");

      return (
        <Tab
          key={tab}
          value={tab}
          label={tab}
          id={"wrapped-tab-" + modifiedTabName}
          aria-controls={"wrapped-tabpanel-" + modifiedTabName}
        />
      )
    });

    return (
      <Paper>
        {/* Full screen menu */}
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#FF682C", color: "#FF682C" } }}
          className={CustHomeClasses.header}
          value={selectedTab}
          onChange={changeTab}
          centered
        >
          {tabs}
        </Tabs>
      </Paper>
    )
  };

  let contactTab;

  if (contactCardConfig && tabArray.find(i => i === "Contact Sales")) {
    contactTab = (
      <TabPanel value={selectedTab} index="Contact Sales">
        <ContactCard contactCardConfig={contactCardConfig} header={"Contact Sales"} />
      </TabPanel>
    );
  }

  return (
    <div>
      {renderTabs()}
      <TabPanel value={selectedTab} index="Favorites">
        <Favorites />
      </TabPanel>
      <TabPanel value={selectedTab} index="Sample Checkouts">
        <Checkouts />
      </TabPanel>
      {contactTab}
      <Fab size="medium" variant="extended" style={{zIndex: "20", position: "fixed", bottom: "0px", right: "0px", padding: "0px 12px", color: "#28C4FC", backgroundColor: "#142E3E", margin: "20px"}} onClick={() => setShowScannerModal(true)}>
        <Camera fontSize="small" style={{marginRight: "5px"}}/> 
        Scan QR
      </Fab> 
      <ScanningModal isOpen={showScannerModal} handleOnClose={() => setShowScannerModal(false)}/>
    </div>
  )
};

export default CustomerHome;
