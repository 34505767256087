import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../../Contants";

// styles
import "../styles/LeadManagement.css";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";

import { AlertContext } from "../../Context/AlertContextProvider";

export default function ChangeStatusDialog(props) {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusError, setStatusError] = useState();

  const getStatuses = async () => {
    const result = await axios.get(`${baseUrl}/api/leads/status`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error retrieving statuses");
      handleAlertOpen();
      setLeadStatuses([]);
      return;
    }

    setStatusError(undefined);
    setLeadStatuses([...result.data.userStatusList]);
  }

  const updateStatus = async (lead_id, status_id) => {
    if (!lead_id || !status_id) {
      setMessageType("error");
      setMessage("Error updating status");
      handleAlertOpen();
      return;
    }

    const result = await axios.post(`${baseUrl}/api/leads/${lead_id}/status/${status_id}`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error updating status");
      handleAlertOpen();
      return;
    }

    setMessageType("success");
    setMessage("Status updated!");
    handleAlertOpen();
    props.refreshParent();
    props.handleOnClose();
  }

  useEffect(() => {
    if (!props.isStatusDialogOpen) return;

    getStatuses();
  }, [props.isStatusDialogOpen])

  useEffect(() => {
    setSelectedStatus(props.lead?.user_status_id ?? "");
  }, [props.lead])

  const statusOptions = leadStatuses.map(status => {
    return (
      <MenuItem value={status.user_status_id} key={"status-" + status.status_cd}>
        <Typography>{status.status}</Typography> 
      </MenuItem>
    )
  });

  return (
    <>
      <Dialog
        open={props.isStatusDialogOpen}
        onClose={props.handleOnClose}
        aria-labelledby="New Note Dialog"
        aria-describedby="New Note Dialog"
      >
        <DialogTitle id="new-note-dialog-title">Lead Status</DialogTitle>
        <DialogContent className="lead-management__dialog-content">
          <FormControl variant="filled" style={{minWidth: "190px", width: "100%"}}>
            <InputLabel id="status-list-label">Status List</InputLabel>
            <Select
              labelId="status-list-label"
              id="status-list"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              {statusOptions}
            </Select>
          </FormControl>
          { statusError ? (
              <Typography style={{margin: "20px 0px"}} paragraph>{statusError}</Typography>
            ) : undefined
          }
        </DialogContent>
        <DialogActions style={{padding: "15px 24px"}}>
          <Button 
            variant="outlined"
            onClick={props.handleOnClose}
          >
            Cancel
          </Button>  
          <Button 
            variant="outlined" 
            onClick={() => updateStatus(props.lead.lead_id, selectedStatus)} 
            style={{marginLeft: "20px", color: "rgba(9, 150, 42, 0.7)"}}
          >
            Update Status
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}