import React, { createContext, useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// MUI
import InfoIcon from "@material-ui/icons/Info";

export const InfoButtonContext = createContext();

export const InfoButtonContextProvider = (props) => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [messages, setMessage] = useState("");

  useEffect(() => {}, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const messageHeader = props.buttonLabel ? props.buttonLabel : "What is this?";
  return (
    <InfoButtonContext.Provider
      value={{ handleClickOpen, handleClose, setMessage }}
    >
      {props.children}
      { messageHeader === "Modification Details" ? <InfoIcon onClick={handleClickOpen} style={{color: "#FF682C"}}></InfoIcon> :
      <Button onClick={handleClickOpen} style={{color: "#FF682C"}}>
        {messageHeader}
      </Button>
      }
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{props.heading}</DialogTitle>
        <DialogContent>
          {props.messages.map((message) => (
            <DialogContentText
              id="alert-dialog-slide-description"
              key={message}
            >
              {message}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{color: "#FF682C"}}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </InfoButtonContext.Provider>
  );
};

export function useAlert() {
  const context = useContext(InfoButtonContext);
  if (!context)
    throw new Error(
      "`useAlert()` must be called inside an `AlertProvider` child."
    );

  return context;
}
