import React, { useState, createContext, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../Contants";
import { AppContext } from "./AppContextProvide";
import { HubSpokeContext } from "./HubSpokeContextProvider";

export const CompanyStatsContext = createContext();

export const CompanyStatsContextProvider = ({ children }) => {
  const { user } = useContext(AppContext);
  const [companyStats, setCompanyStats] = useState([]);
  const [days, setDays] = useState(30);
  const { selectedSpoke } = useContext(HubSpokeContext);

  const isLoggedIn = user.user_id;

  useEffect(() => {
    async function getCompanyStats() {
      const result = await axios(`${baseUrl}/api/stats/company?days=${days}&siteName=${selectedSpoke}`);
      setCompanyStats(result.data.data);
    }
    if (isLoggedIn) {
      getCompanyStats();
    }
  }, [isLoggedIn, days, selectedSpoke]);

  return (
    <CompanyStatsContext.Provider value={{ companyStats, setDays }}>
      {children}
    </CompanyStatsContext.Provider>
  );
};
