import React, { useContext } from "react";
import { Pie } from "react-chartjs-2";

// styles
import "../../styles/Stats.css";

// MUI
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// icons
import GetAppIcon from "@material-ui/icons/GetApp";

// table config
import { MostProductsViewedConfig } from "../../config";
import { download, JsonToCsvConverter } from "../../../Util/utilsFunctions";
import CustomTable from "../../Custom/CustomTable";
import CustomSelect from "../../Custom/CustomSelect";
import { ProductStatsContext } from "../../../Context/ProductsStatsContextProvider";

const MostViewedProducts = (props) => {
  const { mostViewedProducts } = props;
  const { setDays } = useContext(ProductStatsContext);
  const options = [7, 30, 60, 90, 120];

  const handleDownload = (data) => {
    const csvData = JsonToCsvConverter(data);
    const date = new Date();
    const filename = 'Most-Viewed-Products_' + date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    download(filename, csvData);
  };

  const legenOptions = {
    legend: {
      display: false,
      position: "bottom",
    },
  };

  const renderPieChart = (productsData) => {
    if (productsData.length) {
      const data = {
        labels: productsData.map((p) => p.trade_name),
        datasets: [
          {
            data: productsData.map((p) => p.times_viewed),
            backgroundColor: productsData.map((p) => {
              const r = Math.floor(Math.random() * 200);
              const g = Math.floor(Math.random() * 200);
              const b = Math.floor(Math.random() * 200);
              const color = `rgba(${r}, ${g}, ${b}, 0.5)`;
              return color;
            }),
          },
        ],
      };
      return <Pie options={legenOptions} data={data} />;
    } else {
      return <div>Loading...</div>;
    }
  };

  return (
    <div className="stats__mvp-container">
      <Paper elevation={0}>
        <Typography align="center" variant="h5" className="stats__mvp-header">
          Most Viewed Products
        </Typography>
        <Divider />
        <Paper elevation={0}>
          <CustomSelect
            option={30}
            data={options}
            setUpdate={setDays}
            property={"Days"}
          />
        </Paper>
        <div className="stats__mvp-stats">
          <div className="stats__mvp-pie stats__mvp-pie-mvp">
            {renderPieChart(mostViewedProducts)}
          </div>
          <div className="stats__mvp-table stats__mvp-table-mvp">
            <CustomTable
              data={mostViewedProducts}
              headers={MostProductsViewedConfig}
            />
          </div>
        </div>
        <Divider />
        <Paper className="stats__mvp-data-download-container">
          <Button
            variant="contained"
            className="stats__mvp-data-download-button"
            onClick={(e) => handleDownload(mostViewedProducts)}
            endIcon={<GetAppIcon />}
          >
            Download
          </Button>
        </Paper>
      </Paper>
    </div>
  );
};

export default MostViewedProducts;
