import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from 'react-dropzone';

//MUI
import { Paper, 
  makeStyles, 
  Button, 
  FormHelperText,
  Grid,  
  FormControl, 
  NativeSelect,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography
  } from "@material-ui/core";
  import Autocomplete from "@material-ui/lab/Autocomplete";
import FolderIcon from '@material-ui/icons/Folder';
import GetAppIcon from '@material-ui/icons/GetApp';

// Style
import "../styles/Uploads.css";

// Context
import { ProgressContext } from "../../Context/ProgressContextProvider";

// Manual uploads
import { getDistributorList, 
  getPreview, 
  confirmManualUpload, 
  getTemplate, 
  getSpokes,
  uploadToSpokes } from "../../Actions"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ManualUpload = () => {
    const [uploadStatus, setUploadStatus] = useState("")
    const [open, setOpen] = useState(false);
    const [openDisclaimer, setOpenDisclaimer] = useState(false);
    const [items, setItems] = useState([]);
    const [header, setHeader] = useState([]);
    const [file, setFile] = useState();
    const [companyList, setCompanyList] = useState([]);
    const [selectedCo, setSelectedCo] = useState('');
    const [convertedFile, setConvertedFile] = useState();
    const [spokeList, setSpokeList] = useState([]);
    const [selectedSpokes, setSelectedSpokes] = useState([]);
    const [allSitesChecked, setAllSitesChecked] = useState(false);
    // const [headers, setHeaders] = useState();
    const classes = useStyles();
    const { handleProgressOpen, handleProgressClose } = useContext(ProgressContext);

    // dropzone
    const onDrop = acceptedFiles => {
      if (!selectedCo) {
        setUploadStatus("Please Select a Template Type.");
        setOpen(true);
      } else {
        const file = acceptedFiles[0];
        setFile(file);
        handleProgressOpen();
        // send data to node for processing.
        const formData = new FormData();
        formData.append("dist", selectedCo);
        formData.append("priceList", file);
        getPreview(formData)
        .then((result) => {
          //console.log("Success", result)
          // setConvertedFile(result.data.dataPreview);
          setConvertedFile(result.data);
          //header
          setHeader(Object.values(result.data.dataPreview[0]));
          //body
          setItems(Object.values(result.data.dataPreview.slice(1)));
          handleProgressClose();
        })
        .catch((err) => {
          setUploadStatus("Something went wrong. " + err.response.data.err.message);
          setOpen(true);
          handleProgressClose();
        });
      }
    }
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: '.xls, .xlsx, .csv, .png, .pdf'})

    // populate dropdown
    useEffect(() => {
      const cList = new Promise((resolve) => {
        resolve(getDistributorList());
      });

      cList.then((d) => {
        setCompanyList(d.data.data);
      });

      // const tHeader = new Promise((resolve) => {
      //   resolve(getTemplateHeader());
      // });

      // tHeader.then((d) => {
      //   setHeaders(d.data.spreadsheet);
      // });
      getSpokes()
      .then((result) => {
        setSpokeList(result.data.spokeList.map(a => a.name));
      })
      .catch((err) => {
        console.log(err);
      });
    },[selectedCo])

    const handleChange = (event) => {
      setSelectedCo(event.target.value);
    };

    const uploadDisclaimer = () => {
      const disclaimer = "Are you sure?"
      const noDist = "Please select a Distributor/Manufacturer from the dropdown list."
      const noFile = "Please select a file."
      if (selectedCo == null) {
        setUploadStatus(noDist);
        setOpen(true);
      } else if (file == null) {
        setUploadStatus(noFile);
        setOpen(true);
      } else {
        setUploadStatus(disclaimer);
        setOpenDisclaimer(true);
      }
    }

    const handleSubmit = () => {
      const success = "Your pricing data has been updated."
      const failed = "Something went wrong. Please contact support at support@showroompricing.com"
      handleProgressOpen();
      setOpenDisclaimer(false);
      if (spokeList.length > 0) {
      uploadToSpokes(selectedSpokes, convertedFile)
      .then((result) => {
        if (result.statusText === "OK"){
          setUploadStatus(success);
          setOpen(true);
          handleProgressClose();
        }
      })
      .catch((err) => {
        setUploadStatus(failed);
        setOpen(true);
        console.log(err);
        handleProgressClose();
      });
    } else {
      confirmManualUpload(convertedFile)
      .then((result) => {
        if (result.statusText === "OK"){
          setUploadStatus(success);
          setOpen(true);
          handleProgressClose();
        }
      })
      .catch((err) => {
        setUploadStatus(failed);
        setOpen(true);
        console.log(err);
        handleProgressClose();
      });
    };
    };

    const closeDisclaimer = () => {
      setOpenDisclaimer(false);
    }

    const handleClose = () => {
      setOpen(false);
      window.location.reload();
    };

    const handleXLSX = async () => {
      await getTemplate()
      .then((result) => {
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([new Uint8Array(result.data.template.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        a.download = 'manual_upload.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // var temp = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
        // + encodeURIComponent(headers); 
        // var a = document.createElement('a');  
        // a.href = temp;  
        // a.a = "manual_upload.xlsx";  
        // document.body.appendChild(download);  
        // a.click();
        // document.body.removeChild(download);
      })
    }

    const handleSetSite = (event, value) => {
      setSelectedSpokes(value);
    }

    const handleAllSitesChecked = (event) => {
      setAllSitesChecked(event.target.checked);
      if(allSitesChecked === false) {
        setSelectedSpokes(spokeList);
      } else {
        setSelectedSpokes([]);
      }  
    }
    return (
    <div>
      <Paper className="upload-title">
        <FolderIcon className="upload-icon"/>
        Price List Import
      </Paper>
      <Paper id="bg-paper" elevation={10}>
        <Grid container direction="row" justify='space-around' alignContent="center">
        {/* <Grid>
            <Card id="instructions">
              <div className="card-content">
              <h1 className="file-upload-instructions">Manual price template</h1>
                <p>1. Download Template.</p>
                <p>2. Enter data into spreadsheet.</p>
                <p>3. Select '_SHOWROOM PRICING_' from the 'Template Type' dropdown list.</p>
                <p>4. Drop the file in the box or click to select a file.</p>
                <p>5. Scroll down the page and verify information is correct.</p>
                <p>6. Press the SUBMIT button.</p>
              </div>
              <Button
                id="btn"
                onClick={handleXLSX}
                color="primary"
                autoFocus
              >Download Template
              </Button>
            </Card>
          </Grid> */}
          <Grid className="instructions-card">
            <Card className="import-instructions-paper" elevation={10}>
              { spokeList.length > 0 ?
              <div className="card-content">
              <h1 className="file-upload-instructions">File import instructions</h1>
              <p>1. Get negotiated file from distributor or download our template to enter your own data.</p>
              <p>2. Select Template Type from the dropdown list. Use '_SHOWROOM PRICING_' if you downloaded our template.</p>
              <p>3. Drop the file in the box or click to select the file.</p>
              <p>4. Select target sites.</p>
              <p>5. Scroll down the page and verify information is correct.</p>
              <p>6. Press the SUBMIT button.</p>
            </div> :
              <div className="card-content">
                <h1 className="file-upload-instructions">File import instructions</h1>
                <p>1. Get negotiated file from distributor or download our template to enter your own data.</p>
                <p>2. Select Template Type from the dropdown list. Use '_SHOWROOM PRICING_' if you downloaded our template.</p>
                <p>3. Drop the file in the box or click to select the file.</p>
                <p>4. Scroll down the page and verify information is correct.</p>
                <p>5. Press the SUBMIT button.</p>
              </div> }
              <Button
                className="btn"
                onClick={handleXLSX}
                autoFocus
                endIcon={<GetAppIcon className="uploads__download-icon"/>}
                variant="contained"
              >Download Template
              </Button>
            </Card>
          </Grid>
          <Grid>
            <Paper className="upload-paper" elevation={10}>
            <FormControl className={classes.formControl}>
              <Grid container direction="row" justify="space-around" alignContent="center">
                <Grid>
                  <NativeSelect 
                    onChange={handleChange}
                    defaultValue={'Template Type'}>
                      <option disabled>Template Type</option>
                      {companyList.map((company, index) => {
                        return (
                          <option key={index} value={company.distributor_name}> {company.distributor_name} </option>
                        )
                      })}
                  </NativeSelect>
                  <FormHelperText className="upload-helper-txt">Select Template Type</FormHelperText>
                </Grid>
              </Grid>
            </FormControl>
              {/* <Button
                id="btn"
                onClick={handleXLSX}
                color="primary"
                autoFocus
              >Select Template
              </Button> */}
            </Paper>
            <Paper className="dropzone-paper" elevation={10}>
            <div id="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p></p> :
                  <p>Click to Select File or Drop File Here...</p>
              }
            </div>
            </Paper>
            { spokeList.length > 0 ?
          <Paper className="spoke-container" elevation={10}>
            <Grid container spacing={0}>
              <Grid item xs={12} className="spoke-header"> 
                <Typography variant="h5" className="spoke-header">Select target sites</Typography>
              </Grid>
              <Grid item xs={6} className="spoke-checkbox">
                <FormControlLabel
                  control={<Checkbox checked={allSitesChecked} onChange={handleAllSitesChecked} className="checkbox"/>}
                  label="Select all Sites"
                />
              </Grid>
              <Grid item xs={6} className="spoke-body">
                <Autocomplete
                  multiple
                  options={spokeList}
                  getOptionLabel={option => option}
                  value={selectedSpokes}
                  onChange={handleSetSite}
                  renderInput={params => <TextField {...params} label={"Sites"} />}
                />
                </Grid>
            </Grid>
          </Paper>  :
        <></> }
            <Paper className="upload-btn-paper" elevation={10}>
              <div className="review-txt">
                Review before submitting data:
              </div>
            <Button
              className="btn"
              onClick={uploadDisclaimer}
              autoFocus
              variant="contained"
            >Submit
            </Button>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <div>
      </div>
        <table className="table_container">
          <thead>
          <tr>
          {header.map((h, index) => (
              <th id="header" key={index}>{h}</th>
          ))}
          </tr>
        </thead>
        <tbody>
          {items.map((d, i) => (
            <tr className="verify-upload-table" key={i}>
              {
                d.map((col, c) =>
                <td className="columns" key={c}>{col}</td>
                )
              }
              </tr>
          ))}
        </tbody>
      </table>
      <Dialog
        open={openDisclaimer}
        onClose={closeDisclaimer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Price Update Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {uploadStatus}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={closeDisclaimer}
            variant="contained"
            autoFocus
            className="alert-button"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            className="alert-button"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Price Update Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {uploadStatus}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            variant="contained"
            autoFocus
            className="alert-button"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManualUpload;