import { Modal, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Scanner from "./Scanner";

export default function ScanningModal(props) {
  const [isScannerOn, setIsScannerOn] = useState(false);

  useEffect(() => {
    if (props.isOpen) setIsScannerOn(true); 
  }, [props.isOpen])

  return (
    <Modal 
      open={props.isOpen} 
      onClose={() => setIsScannerOn(false)}
      aria-labelledby="customer notes modal"
      aria-describedby="modal for managing customer notes"
    >
      <Paper 
        style={{
          position: "absolute", 
          top: "50%", 
          left: "50%", 
          transform: "translate(-50%, -50%)", 
          backgroundColor: "#FFFFFF",
          maxHeight: "90vh",
          width: "90vw",
          maxWidth: "600px",
          borderRadius: "5px",
          padding: "0px",
          overflow: "hidden"
        }}
      >
        <Scanner isOpen={isScannerOn} handleOnClose={() => props.handleOnClose()}/>
      </Paper>
    </Modal>
  )
}