import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../Contants";

// styles
import "../styles/LeadManagement.css";

// MUI
import {
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Checkbox,
  Paper,
  TextField,
} from "@material-ui/core";

import { AlertContext } from "../../Context/AlertContextProvider";

// Components
import ContactCard from "./ContactCard";

function ValidatedRow(props) {
  const [inputError, setInputError] = useState();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputError(undefined);
    setInputValue(props.value);
  }, [props.value])

  useEffect(() => {
    if (inputError) props.toggleContactInfoVisibility(props.attribute, false);
  }, [inputError])

  const validateField = (newValue) => {
    let errMessage;

    setInputValue(newValue);

    if (newValue && props.regex && !props.regex.test(newValue)) {
      errMessage = "Current value does not match the required formatting";
    
      if (props.format) errMessage += " (ex. " + props.format + ")";
      
      setInputError(errMessage);
      return;
    }

    setInputError(errMessage);
    props.updateField(props.attribute, newValue);
  }

  return (
    <TableRow>
      <TableCell className="lead-management__td" colSpan={2}>
        <TextField 
          id={props.id} 
          label={props.label}
          variant="filled" 
          placeholder={props.format}
          value={inputValue} 
          error={inputError ? true : false}
          helperText={inputError}
          onChange={(e) => validateField(e.target.value)}
        />
      </TableCell>
      <TableCell className="lead-management__td">
        <Checkbox 
          checked={props.show} 
          disabled={!inputValue}
          onChange={() => props.toggleContactInfoVisibility(props.attribute, !props.show)}
        />
      </TableCell>
    </TableRow>
  )
}

const ContactInfo = (props) => {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();
  const [contactCardConfig, setContactCardConfig] = useState({
    showContactCard: false,
    image: {
        show: false,
        imageUrl: ""
    },
    name: {
        show: false,
        alternateName: ""
    },
    email: {
        show: false,
        alternateEmail: ""
    },
    phone: {
        show: false,
        alternatePhone: ""
    }
  });

  const putContactConfig = async () => {
    const contactConfig = { ...contactCardConfig };

    if (!contactCardConfig.showContactCard) {
      contactConfig.image.show = contactConfig.email.show = contactConfig.name.show = contactConfig.phone.show = false;
      return;
    } 

    if (!contactConfig.image.show && !contactConfig.email.show && !contactConfig.name.show && !contactConfig.phone.show) {
      setError("Atleast one of the attributes above needs to be selected to show your contact info to customers. If you don't want to show contact information, please deselect the top checkbox.");
      return
    }

    const result = await axios.put(`${baseUrl}/api/rsa/config`, {config: contactCardConfig});

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error updating contact config");
      handleAlertOpen();
      return;
    }

    setError(undefined);
    setMessageType("success");
    setMessage("Contact config updated!");
    handleAlertOpen();
  }

  useEffect(() => {
    async function getContactConfig() {
      const result = await axios(`${baseUrl}/api/rsa/config`);
      setContactCardConfig(result.data.config);
      setRefresh(false);
    }

    if (refresh) getContactConfig();
  }, [refresh])

  const toggleContactInfoVisibility = (attribute, newValue) => {
    const newContactCardConfig = { ...contactCardConfig };

    if (newContactCardConfig[attribute].show === undefined) {
      newContactCardConfig[attribute] = newValue;
    } else {
      newContactCardConfig[attribute].show = newValue;
    }

    setContactCardConfig(newContactCardConfig);
  }

  const updateAlternateContactInfo = (attribute, newValue) => {
    const newContactCardConfig = { ...contactCardConfig };

    if (!newValue && newContactCardConfig[attribute].show) {
      newContactCardConfig[attribute].show = false;
    } 

    switch(attribute) {
      case "image":
        newContactCardConfig[attribute].imageUrl = newValue;
        break;
      case "name":
        newContactCardConfig[attribute].alternateName = newValue;
        break;
      case "phone":
        newContactCardConfig[attribute].alternatePhone = newValue;
        break;
      case "email":
        newContactCardConfig[attribute].alternateEmail = newValue;
        break;
    }

    setContactCardConfig(newContactCardConfig);
  }

  return (
    <Grid container spacing={2}>
      <Grid item lg={7} md={6} sm={12}>
        <Paper>
          <Table className="lead-management__table">
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} style={{padding: "0px"}}>
                  <Typography variant="h4" style={{color: "#28C4FC", backgroundColor: "#142E3E", padding: "16px"}}>
                    Contact Information
                  </Typography>
                </TableCell>           
              </TableRow>

              <TableRow>
                <TableCell className="lead-management__td" colSpan={2}>
                  <Typography variant="h6" style={{overflowWrap: "break-word"}}>
                    Show Contact Card to Customers?
                  </Typography>
                </TableCell>
                <TableCell className="lead-management__td">
                  <Checkbox checked={contactCardConfig.showContactCard} onChange={() => toggleContactInfoVisibility("showContactCard", !contactCardConfig.showContactCard)}/>
                </TableCell>
              </TableRow>
            { contactCardConfig.showContactCard ? (
                <>
                  <TableRow >
                    <TableCell colSpan={3}>
                      <Typography>
                        Please use the text fields and selections below to set what contact information should be visible to customers.
                      </Typography>
                    </TableCell>        
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="h5" style={{overflowWrap: "break-word"}}>
                        Attribute
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" style={{overflowWrap: "break-word"}}>
                        Show?
                      </Typography>
                    </TableCell>
                  </TableRow>
                  
                  {/* <TableRow>
                    <TableCell className="lead-management__td" colSpan={2}>
                      <Typography variant="h6" style={{overflowWrap: "break-word"}}>
                        Image
                      </Typography>
                      <div>
                        {
                          contactCardConfig.image.imageUrl ? (
                            <Avatar 
                              style={{
                                display: "inline-block", 
                                verticalAlign: "middle", 
                                margin: "16px 16px 16px 0px", 
                                textAlign: "center", 
                                height: "50px", 
                                width: "50px",
                              }} 
                              src={contactCardConfig.image.imageUrl}
                            ></Avatar>
                          ) : undefined
                        }
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          style={{display: "none"}}
                        />
                        <label htmlFor="contained-button-file">
                          <Button variant="contained" component="span" style={{margin: "10px 0px"}}>
                            Upload Image
                          </Button>
                        </label>
                      </div>
                      
                    </TableCell>
                    <TableCell className="lead-management__td">
                      <Checkbox 
                        checked={contactCardConfig.image.show} 
                        disabled={!contactCardConfig.image.imageUrl}
                        onChange={() => toggleContactInfoVisibility("image", !contactCardConfig.image.show)}
                      />
                    </TableCell>
                  </TableRow> */}

                  <ValidatedRow
                    id="contact-name" 
                    label="Name" 
                    attribute="name"
                    regex={/^\D+(\s\D+)*$/}
                    format="First Last"
                    value={contactCardConfig.name.alternateName}
                    updateField={updateAlternateContactInfo}
                    show={contactCardConfig.name.show}
                    toggleContactInfoVisibility={toggleContactInfoVisibility}
                  />

                  <ValidatedRow
                    id="contact-email" 
                    label="Email" 
                    attribute="email"
                    regex={/^\S+@\S+\.\S+$/}
                    format="example@domain.com"
                    value={contactCardConfig.email.alternateEmail}
                    updateField={updateAlternateContactInfo}
                    show={contactCardConfig.email.show}
                    toggleContactInfoVisibility={toggleContactInfoVisibility}
                  />

                  <ValidatedRow
                    id="contact-phone" 
                    label="Phone Number" 
                    attribute="phone"
                    regex={/^(\D*\d){10,11}$/}
                    format="10-11 digits"
                    value={contactCardConfig.phone.alternatePhone}
                    updateField={updateAlternateContactInfo}
                    show={contactCardConfig.phone.show}
                    toggleContactInfoVisibility={toggleContactInfoVisibility}
                  />
                </>
              ) : undefined
            }
            {
              error ? (
                <TableRow style={{color: "red"}}>
                  <TableCell colSpan={3}>
                    <Typography>
                      {error}
                    </Typography>
                  </TableCell>         
                </TableRow>
              ) : undefined
            }
              <TableRow>
                <TableCell colSpan={3} style={{border: "none"}}>
                  <Button 
                    variant="outlined" 
                    onClick={() => setRefresh(true)}
                  >
                  Cancel
                  </Button>
                  <Button 
                    variant="outlined" 
                    style={{
                      color: "rgba(9, 150, 42, 0.7)", 
                      marginLeft: "30px"
                    }}
                    onClick={() => putContactConfig()}
                  >
                  Update
                  </Button>
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>
      </Grid>
      {
        contactCardConfig.showContactCard ? (
          <Grid item lg={5} md={6} sm={12}>
            <ContactCard contactCardConfig={contactCardConfig} header={"Contact Card Preview"}/>
          </Grid>
        ) : undefined
      }
    </Grid>
  )
}

export default ContactInfo;