import React, { useContext, useEffect, useState } from "react";
import { ProductDisclaimerContext } from "../../../Context/ProductDisclaimerContextProvider";
import { TaxDisclaimerContext } from "../../../Context/TaxDisclaimerContextProvider";
import {InfoButtonContextProvider} from "../../Shared/InfoButton";

// MUI
// import Paper from "@material-ui/core/Paper";
// import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from"@material-ui/icons/ExpandMore";
import { Button, Grid, Typography, TextField, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { updateProductDisclaimer, updateTaxDisclaimer } from "../../../Actions";
import { AlertContext } from "../../../Context/AlertContextProvider";

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Switch from '@material-ui/core/Switch';

// Styles
import "../../styles/MND.css";

// const infoButtonLabel = "How does this apply?"
// const detailsButtonHeader = "Modification Details"
// const productInfoButtonHeader = "Disclaimer:"
const productInfoButtonMessage = ["This disclaimer will be shown at the bottom of the product page that your customer will see.",
"Put things in here to communicate with the customer such as if taxes are included or excluded in the Retail Price."]
// const taxInfoButtonHeader = "Disclaimer:"
const taxInfoButtonMessage = ["This disclaimer will be shown under the retail price on the product page."]

const DisclaimerUpdate = () => {
  const { disclaimer, setUpdate } = useContext(ProductDisclaimerContext);
  const { tDisclaimer, setTaxDisclaimerUpdate } = useContext(TaxDisclaimerContext);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const [productDisclaimer, setProductDisclaimer] = useState("");
  const [taxDisclaimer, setTaxDisclaimer] = useState("");
  // const [switchState, setSwitchState] = useState(true);
  
  useEffect(() => {
    // const taxDisclaimerPromise = new Promise((resolve) => {
    //   resolve(getTaxDisclaimer());
    // })
    // taxDisclaimerPromise.then((d) => {
    //   //console.log(d.data)
    //   if (Number(d.data.data[0].active) === 1) {
    //     setSwitchState(true);
    //   }
    //   if (Number(d.data.data[0].active )=== 0) {
    //     setSwitchState(false);
    //   }
    // });

    if (disclaimer.length) {
      setProductDisclaimer(disclaimer[0].product_disclaimer);
    }

    if (tDisclaimer.length) {
      setTaxDisclaimer(tDisclaimer[0].disclaimer)
    }
  }, [disclaimer, tDisclaimer]);

  const handleUpdateProductDisclaimer = (e) => {
    setProductDisclaimer(e.target.value);
  };

  const handleUpdateTaxDisclaimer = (e) => {
    setTaxDisclaimer(e.target.value);
  };

  const handleProductDisclaimerUpdate = () => {
    updateProductDisclaimer(productDisclaimer)
      .then(() => {
        setMessage("Successfully updated product disclaimer");
        setMessageType("success");
        handleAlertOpen();
        setUpdate(true);
      })
      .catch(() => {
        setMessage("Error updating product disclaimer");
        setMessageType("error");
        handleAlertOpen();
      });
  };

  const handleTaxDisclaimerUpdate = () => {
    updateTaxDisclaimer(taxDisclaimer)
      .then(() => {
        setMessage("Successfully updated tax disclaimer");
        setMessageType("success");
        handleAlertOpen();
        setTaxDisclaimerUpdate(true);
      })
      .catch(() => {
        setMessage("Error updating tax disclaimer");
        setMessageType("error");
        handleAlertOpen();
      });
  };

  // const handleProductDisclaimerSubmit = (e) => {
  //   e.preventDefault()
  //   handleProductDisclaimerUpdate()
  // }

  // const handleTaxDisclaimerSubmit = (e) => {
  //   e.preventDefault()
  //   handleTaxDisclaimerUpdate()
  // }

  // const handleChange = (event) => {
  //   setSwitchState(event.target.checked)
  //   if (Number(event.target.checked) === 1) {
  //     setTaxDisclaimerPosition("1")
  //   }
  //   if (Number(event.target.checked) === 0) {
  //     setTaxDisclaimerPosition("0");
  //   }
  // };

  const renderProductDisclaimerDetails = (data) => {
    if (data.length) {
      const disclaimerData = data[0]
      return ( 
      [`LAST UPDATED BY:  \xa0\xa0\xa0\xa0\xa0  ${disclaimerData.name}`,
        `LAST MODIFIED USER:  \xa0\xa0\xa0\xa0\xa0 ${disclaimerData.user_name}`,
        `LAST MODIFIED:  \xa0\xa0\xa0\xa0\xa0 ${disclaimerData.last_modified_dttm}`]
      )};
  };

  const renderTaxDisclaimerDetails = (data) => {
    if (data.length) {
      const disclaimerData = data[0]
      return ( 
        [`LAST UPDATED BY: \xa0\xa0\xa0\xa0\xa0 ${disclaimerData.name}`,
        `LAST MODIFIED USER: \xa0\xa0\xa0\xa0\xa0 ${disclaimerData.user_name}`,
        `LAST MODIFIED: \xa0\xa0\xa0\xa0\xa0 ${disclaimerData.last_modified_dttm}`]
      )};
  };

  const renderProductDisclaimer = (data) => {
    if(data.length) {
      return (
        <div>
          <div className="mnd__header">
            <Typography
              className="header-text"
              variant="h4">
              Product Disclaimer
            </Typography>
          </div>
          <Grid container className="MND__disclaimer-bg">
            <Grid item xs={8} className="MND__disclaimer-grids">
             <TextField
              variant="filled"
              name="productdisclaimer"
              label="Product Disclaimer"
              placeholder="Product Disclaimer"
              multiline
              value={productDisclaimer}
              onChange={handleUpdateProductDisclaimer}
              size="small"
              />
              <InfoButtonContextProvider 
                buttonLabel="Modification Details" 
                messages={renderProductDisclaimerDetails(disclaimer)}
              />
            </Grid>
            <Grid item xs ={4} className="MND__disclaimer-grids">
            <Button
              className="admin__tax-update-btn"
              variant="contained"
              onClick={handleProductDisclaimerUpdate}
            >
              Update
            </Button>
            </Grid>
            <Grid item xs={12} style={{marginTop:10}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Product Disclaimer</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                {productInfoButtonMessage}

                </Typography>
              </AccordionDetails>
            </Accordion>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      <div>Loading...</div>;
    }
  };

  // const renderProductDisclaimer = (data) => {
  //   if (data.length) {
  //     return (
  //       <Paper elevation={1} className="mnd__disclaimer-paper">
  //         <div className="mnd__header">
  //         <Typography
  //           className="header-text"
  //           variant="h4">
  //           Product Disclaimer
  //         </Typography>
  //       </div>
  //       <form onSubmit={handleProductDisclaimerSubmit}>
  //       <TableContainer component={Paper}>
  //     <Table className="class-margin-details" aria-label="simple table">
  //       <TableHead>
  //       </TableHead>
  //       <TableBody>
  //           <TableRow className="mnd__table-row">
  //             <TableCell className="mnd__table-cell" width="210" align="center"><InfoButtonContextProvider buttonLabel="Product Disclaimer" heading = {productInfoButtonHeader} messages={productInfoButtonMessage}/></TableCell>
  //             <TableCell className="mnd__table-cell" width="210" align="center">
  //             <InfoButtonContextProvider 
  //             buttonLabel="Modification Details" 
  //             heading = {detailsButtonHeader} 
  //             messages={renderProductDisclaimerDetails(disclaimer)}/>
  //           </TableCell>
  //           <TableCell className="mnd__table-cell" width="170" align="center" ></TableCell>
  //             <TableCell className="mnd__table-cell" width="170" align="center">
  //               <TextField
  //                 className="mnd__text-area"
  //                 variant="filled"
  //                 name="disclaimer"
  //                 multiline={true}
  //                 label="Disclaimer"
  //                 placeholder="Disclaimer"
  //                 value={productDisclaimer}
  //                 onChange={handleUpdateProductDisclaimer}
  //                 size="large"
  //               />
  //             </TableCell>
  //             <TableCell className="mnd__table-cell" width="100" align="center">
  //             <Button className="admin__tax-update-btn" onClick={handleProductDisclaimerUpdate} variant="contained">
  //               Update
  //             </Button>
  //             </TableCell>
  //           </TableRow>
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  //   </form>
  //   </Paper>

       
  //     );
  //   } else {
  //     return <div>Loading...</div>;
  //   }
  // };
  
  const renderTDisclaimer = (data) => {
    if(data.length) {
      return (
        <div>
          <div className="mnd__header">
            <Typography
              className="header-text"
              variant="h4">
              Tax Disclaimer
            </Typography>
          </div>
          <Grid container className="MND__disclaimer-bg">
            <Grid item xs={8} className="MND__disclaimer-grids">
             <TextField
              variant="filled"
              name="taxdisclaimer"
              label="Tax Disclaimer"
              placeholder="Tax Disclaimer"
              multiline
              value={taxDisclaimer}
              onChange={handleUpdateTaxDisclaimer}
              size="small"
              />
              <InfoButtonContextProvider
                buttonLabel="Modification Details"
                messages={renderTaxDisclaimerDetails(tDisclaimer)}/>
            </Grid>
            <Grid item xs ={4} className="MND__disclaimer-grids">
            <Button
              className="admin__tax-update-btn"
              variant="contained"
              onClick={handleTaxDisclaimerUpdate}
            >
              Update
            </Button>
            </Grid>
            <Grid item xs={12} style={{marginTop:50}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Tax Disclaimer</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                {taxInfoButtonMessage}

                </Typography>
              </AccordionDetails>
            </Accordion>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      <div>Loading...</div>;
    }
  };

  // const renderTaxDisclaimer = (data) => {
  //   if (data.length) {
  //     return (
  //       <Paper elevation={1} className="mnd__disclaimer-paper">
  //         <div className="mnd__header">
  //         <Typography
  //           className="header-text"
  //           variant="h4">
  //           Tax Disclaimer
  //         </Typography>
  //       </div>
  //       <form onSubmit={handleTaxDisclaimerSubmit}>
  //       <TableContainer component={Paper}>
  //     <Table className="class-margin-details" aria-label="simple table">
  //       <TableHead>
  //       </TableHead>
  //       <TableBody>
  //           <TableRow className="mnd__table-row">
  //             <TableCell className="mnd__info-table-cell" width="210" align="center"><InfoButtonContextProvider buttonLabel="Tax Disclaimer" heading = {taxInfoButtonHeader} messages={taxInfoButtonMessage}/></TableCell>
  //             <TableCell className="mnd__info-table-cell" width="210" align="center">
  //             <InfoButtonContextProvider 
  //             buttonLabel="Modification Details" 
  //             heading = {detailsButtonHeader} 
  //             messages={renderTaxDisclaimerDetails(tDisclaimer)}/>
  //           </TableCell>
  //           <TableCell className="mnd__table-cell" width="170px" align="center">
  //             {/* <div>
  //             Tax disclaimer 
  //             <Grid className="mnd__tax-switch" component="label" container alignItems="center" spacing={1}>
  //               <Grid item className="mnd__tax-label">Not Shown</Grid>
  //                 <Grid item>
  //                   <Tooltip arrow={true}  title='"Taxes Included*" message displayed on the product page.'>
  //                     <Switch
  //                       checked={switchState}
  //                       onChange={handleChange}
  //                       checked={switchState}
  //                       color="primary"
  //                       size="small"
  //                     />
  //                   </Tooltip>
  //                 </Grid>
  //               <Grid item className="mnd__tax-label">Shown</Grid>
  //             </Grid>
  //             </div> */}
  //           </TableCell>
  //             <TableCell className="mnd__tax-table-cell" align="center" >
  //               <TextField
  //                 className="mnd__tax-text-area"
  //                 variant="filled"
  //                 name="disclaimer"
  //                 multiline={true}
  //                 label="Enter Tax Disclaimer"
  //                 placeholder="Enter Tax Disclaimer"
  //                 value={taxDisclaimer}
  //                 onChange={handleUpdateTaxDisclaimer}
  //                 size="large"
  //               />
  //             </TableCell>
  //             <TableCell className="mnd__table-cell" width="100" align="center">
  //             <Button className="admin__tax-update-btn" onClick={handleTaxDisclaimerUpdate} variant="contained">
  //               Update
  //             </Button>
  //             </TableCell>
  //           </TableRow>
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  //   </form>
  //   </Paper>

       
  //     );
  //   } else {
  //     return <div>Loading...</div>;
  //   }
  // };


  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={5} lg={5} className="grid-item">
          {/* <Paper elevation={5} className="mnd__box-paper-disclaimer"> */}
            {renderProductDisclaimer(disclaimer)}
            {/* <Accordion style={{marginTop:40}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Product Disclaimer</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                {productInfoButtonMessage}

                </Typography>
              </AccordionDetails>
            </Accordion> */}
          {/* </Paper> */}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className="grid-item">
          {/* <Paper elevation={5} className="mnd__box-paper-disclaimer"> */}
            {renderTDisclaimer(tDisclaimer)}
            {/* <Accordion style={{marginTop:80}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Tax Disclaimer</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                {taxInfoButtonMessage}

                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper> */}
        </Grid>
      </Grid>
      {/* <Paper elevation={0} className="admin__tax-content">
        {renderProductDisclaimer(disclaimer)}
      </Paper> */}
      {/* <Paper elevation={0} className="admin__tax-content">
        {renderTaxDisclaimer(tDisclaimer)}  
      </Paper> */}
    
    </div>
  );
};

export default DisclaimerUpdate;
