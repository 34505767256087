import React from "react";

// styles
import "../../styles/HomeBulletins.css";

// MUI
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

const Bulletin = (props) => {
  const { bulletin } = props;
  const [open, setOpen] = React.useState(false);
  const [bulletinImg, setbulletinImg] = React.useState("");
  const [bulletinTitle, setbulletinTitle] = React.useState("");
  const [bulletinMessage, setbulletinMessage] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (e, image, title, message) => {
    setOpen(true);
    setbulletinImg(image);
    setbulletinTitle(title);
    setbulletinMessage(message);
  };

  return (
    <div>
      <div
        onClick={(e) =>
          handleClickOpen(e, bulletin.url, bulletin.title, bulletin.message)
        }
        className="tile"
        href="#"
      >
        <img src={bulletin.url} alt="Bulletin" />
        <div className="details">
          <span className="title">{bulletin.title}</span>
          <span className="info">{bulletin.message}</span>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <img className="bulletin__modal-img" src={bulletinImg} alt="bulletin" />
        <div className="bulletin__modal-details">
          <Typography
            className="bulletin__modal-title"
            variant="h5"
            gutterBottom
          >
            {bulletinTitle}
          </Typography>
          <Typography variant="body1" className="bulletin__modal-info">
            {bulletinMessage}
          </Typography>
        </div>
      </Dialog>
    </div>
  );
};

export default Bulletin;
