import React, { Fragment } from "react";
// import QRcode from "qrcode.react";

// styles
// import "../../styles/Products.css";

class PrintableQRcodes extends React.Component {
  renderQRcodesList = (data) => {
    return data.length ? (
      data.map((product, index) => {
        // const productUrl = `${window.location.origin}/product/${product.product_uuid}`;
        return (
          <Fragment key={product.product_uuid}>
            <div className="pg__grid" lg={3}>
              <span className="pg__grid-qr-id">
                {product.style} <br />
              </span>
            </div>
          </Fragment>
        );
      })
    ) : (
      <div>Loading...</div>
    );
  };

  render() {
    return <div></div>;
  }
}

export default PrintableQRcodes;
