import React, { useContext } from "react";
import { Bar } from "react-chartjs-2";

// MUI
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// config
import { MostViewedManufacturersConfig } from "../../config";

// icons
import GetAppIcon from "@material-ui/icons/GetApp";

// functions
import { download, JsonToCsvConverter } from "../../../Util/utilsFunctions";
import CustomTable from "../../Custom/CustomTable";
import { CompanyStatsContext } from "../../../Context/CompaniesStatsContextProvider";
import CustomSelect from "../../Custom/CustomSelect";

const MostViewedManufacturers = (props) => {
  const { mostViewedManufacturers } = props;
  const { setDays } = useContext(CompanyStatsContext);
  const options = [7, 30, 60, 90, 120];

  const handleDownload = (data) => {
    const csvData = JsonToCsvConverter(data);
    const date = new Date();
    const filename = 'Most-Viewed-Manufacturers_' + date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    download(filename, csvData);
  };

  const legendOptions = {
    legend: {
      display: false,
    },
  };

  const renderBarChart = (companyData) => {
    if (companyData.length) {
      const data = {
        labels: companyData.map((c) => c.manufacturer_name),
        datasets: [
          {
            label: "Most Viewed Manufacturers",
            data: companyData.map((c) => c.times_viewed),
            backgroundColor: companyData.map((c) => {
              const r = Math.floor(Math.random() * 200);
              const g = Math.floor(Math.random() * 200);
              const b = Math.floor(Math.random() * 200);
              const color = `rgba(${r}, ${g}, ${b}, 0.5)`;
              return color;
            }),
          },
        ],
      };
      return <Bar options={legendOptions} data={data} />;
    } else {
      return <div>Loading...</div>;
    }
  };

  return (
    <div className="stats__mvm-container">
      <Paper elevation={0}>
        <Typography align="center" variant="h5" className="stats__mvp-header">
          Most Viewed Manufacturers
        </Typography>
        <Divider />
        <Paper elevation={0}>
          <CustomSelect
            option={30}
            data={options}
            setUpdate={setDays}
            property={"Days"}
          />
        </Paper>
        <div className="stats__mvp-stats">
          <div className="stats__mvp-table">
            <CustomTable
              data={mostViewedManufacturers}
              headers={MostViewedManufacturersConfig}
            />
          </div>
          <div className="stats__mvp-pie">
            {renderBarChart(mostViewedManufacturers)}
          </div>
        </div>
      </Paper>
      <Divider />
      <Paper className="stats__mvp-data-download-container">
        <Button
          variant="contained"
          className="stats__mvp-data-download-button"
          onClick={(e) => handleDownload(mostViewedManufacturers)}
          endIcon={<GetAppIcon />}
        >
          Download
        </Button>
      </Paper>
    </div>
  );
};

export default MostViewedManufacturers;
