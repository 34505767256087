import React, { useContext, useState, useEffect } from "react";

// styles
import "../../styles/Admin.css";

// Actions
import { deleteUser, updateUser, changePassword } from "../../../Actions";

// MUI
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import FormControl from '@material-ui/core/FormControl';

// dependents
import { AdminContext } from "../../../Context/AdminContextProvider";
import { AlertContext } from "../../../Context/AlertContextProvider";
import { getUser } from "../../../helpers/index";
import { Checkbox, FormControlLabel, FormLabel, Grid, Switch, Tooltip } from "@material-ui/core";

const EditUser = (props) => {
  const [user, setUser] = useState(props.user);
  const [access, setAccess] = useState(props.user.accessType);
  const [accessCode, setAccessCode] = useState("");
  const [name, setName] = useState(props.user.name);
  const [email, setEmail] = useState(props.user.email);
  const [isRSA, setIsRSA] = useState(!!props.user.is_rsa);
  const [usrId, setPasswordId] = useState("")
  const [open, setOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [roleId, setRoleId] = useState();
  const [roleList] = useState(props.roles);
  const [locationId, setLocationId] = useState(props.user.location_id);
  const { setUpdate, accesstypes, locations } = useContext(AdminContext);
  const { handleAlertOpen, setMessageType, setMessage, setUserEditOpen, userEditOpen } = useContext(
    AlertContext
  );
  const [currentUserId] = useState(getUser().user_id);
  const [userDeleteOpen, setUserDeleteOpen] = useState(false);
  const [phone, setPhone] = useState(props.phone);
  const [reminderSwitchState, setReminderSwitchState] = useState(!!props.user.sample_reminder);
  const reminderSwitchToolTip = `Allow this role to receive reminders about samples they have checked out`;

  useEffect(() => {
    // CurrentAccessCd(props.user.accessType);
    setAccess(props.user.accessType);
    const tempAccess = accesstypes.filter(
      (access) => access.access_type === props.user.accessType
    )[0];
    setAccessCode(tempAccess.access_type_cd);
    setPasswordId(user.userId);
    setUser(props.user);
    setName(props.user.name);
    if (props.user.email == null){
      setEmail("");
    }else{
    setEmail(props.user.email);
    }
    if (props.user.phone == null){
      setPhone(undefined);
    }else{
    setPhone(props.user.phone);
    }

    if (props.user.is_rsa === 0) {
      setIsRSA(false);
    } else if (props.user.is_rsa === 1){
      setIsRSA(true);
    };

    if (props.user.sample_reminder === 0) {
      setReminderSwitchState(false);
    } else if (props.user.sample_reminder === 1){
      setReminderSwitchState(true);
    };

    setAccess(props.user.accessType);
    setRoleId(props.user.role_id);
  }, [props.user, user.userId, accesstypes]);

  useEffect(() => {
    accessCode === "c" ? setIsRSA(0) : setIsRSA(1);
  }, [accessCode]);

  // const CurrentAccessCd =  (e) => {
  //   setAccess(e);
  //   const tempAccess = accesstypes.filter(
  //     (access) => access.access_type === e
  //   )[0];
  //   setAccessCode(tempAccess.access_type_cd);
  // };

  const handleUserDelete = (currentUserId, userid) => {
    if(currentUserId === userid) {
    setMessage("Cannot delete yourself!")
    setMessageType("error")
    handleAlertOpen() 
    } else {
      setUserDeleteOpen(true);
    }
  };

  const handleUserDeleteConfirmed = () => {
    deleteUser(user.userId)
      .then((res) => {
        setUpdate(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleUpdateUser = (userId) => {
    updateUser(name.trim(), email, phone, userId, roleId, accessCode, locationId, isRSA, reminderSwitchState)
    .then((result) => {
      setMessage("Updated user access");
      setMessageType("success");
      handleAlertOpen();
      setUpdate(true);
    })
    .catch((err) => {
      setMessage("Failed to update user");
      setMessageType("error");
      handleAlertOpen();
    });
  };

  const handleRoleSelectChange = (e) => {
    setRoleId(e.target.value);
  };

  const handleLocationSelectChange = (e) => {
    setLocationId(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNewPassword = (event) => {
    const value = event.target.value;
    setNewPassword(value);
  };

  const handleConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
  };

  const isValidPassword = () => {
    if (newPassword !== confirmPassword) return true;
    else return false;
  };

  const isValidNewPassword = () => {
    if (newPassword.length < 8) return true;
    else return false;
  };

  const isDisabled = () => {
    if (newPassword.length < 8) {
      return true;
    } else if (confirmPassword !== newPassword) {
      return true;
    } else if (newPassword.length > 8 && confirmPassword === newPassword) {
      return false;
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setNewPassword("");
    setConfirmPassword("");
    setOpen(false);
    setUserEditOpen(false);
    setUserDeleteOpen(false);
  };

  const handleSubmit = () => {
    changePassword(newPassword, confirmPassword, usrId)
      .then((result) => {
        setMessageType("success");
        setMessage("Password Changed!!");
        handleAlertOpen(true);
        handleClose();
      })
      .catch((err) => {
        setMessageType("error");
        setMessage("Error changing password");
        handleAlertOpen(true);
      });
  };

  const handleAccessChange = (e) => {
    const accessType = e.target.value;
    setAccess(accessType);
    const tempAccess = accesstypes.filter(
      (access) => access.access_type === accessType
    )[0];
    setAccessCode(tempAccess.access_type_cd);
  };

  const renderSelectLabel = () => {
    return access === "Customer" ? (
    <div>Select Role:</div>
    ) : <div></div>
  };

  const renderRoleSelect = () => {
    return access === "Customer" ? (
      <div>

        <TextField
          fullWidth
          className="admin__edit-user-dropdowns"
          onChange={handleRoleSelectChange}
          defaultValue={props.user.role_id}
          select
          label="Role"
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {roleList.map((r) => {
              return (
                <option key={r.role_id} value={r.role_id} description={r.role_description}> {r.role_name} </option>
              )
            })}
        </TextField>
      </div>
    ) : <div></div>
  };

  const renderLocationSelect = () => {
    return (
        <TextField
          fullWidth
          className="admin__edit-user-dropdowns"
          onChange={handleLocationSelectChange}
          defaultValue={props.user.location_id}
          select
          label="Location"
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {locations.map((l) => {
                return (
                  <option key={l.location_id} value={l.location_id} description={l.location_description}> {l.location_name} - {l.location_description} </option>
                )
              })}
        </TextField>
    )
  };

  const getAccessTypes = (data) => {
    return (
        <TextField
          fullWidth
          displayempty="true"
          className="admin__edit-user-dropdowns"
          value={access}
          onChange={handleAccessChange}
          select
          label="Access Type"
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {data.map((access) => {
                return (
                  <option key={access.access_type} value={access.access_type}>
                    {access.access_type}
                  </option>
                );
              })}
        </TextField>
    );
  };

  const renderUser = (user) => {
    return user.userId ? (
      <div>
        <Dialog fullWidth open={userDeleteOpen} onClose={handleClose}>
          <DialogTitle className="user__cp-modal-header">
            {"USER DELETE?"}
          </DialogTitle>
          <DialogContent>
            Are you sure you want to delete this user?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" className="user__pw-change-button" autoFocus>
              Cancel
            </Button>
            <Button
              onClick={handleUserDeleteConfirmed}
              className="user__cp-btn"
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={userEditOpen} onClose={handleClose}>
            <Typography
              className="header-text"
              variant="h4">
              Edit User 
              <Button variant="outlined" onClick={handleClose}
                className="admin__user-header-button">
                  x
              </Button>   
            </Typography>
          <Paper className="admin__edit-user-form">
            <table className="admin__create-user-table">
              <tbody className="admin__create-user-table-body">
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={handleNameChange}
                    >
                    </TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    >
                    </TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    value={phone}
                    onChange={handlePhoneChange}
                    >
                    </TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                  <TextField
                    disabled
                    fullWidth
                    label="Username"
                    variant="outlined"
                    value={user.userName}
                    >
                    </TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">{getAccessTypes(accesstypes)}</td>
                </tr>
                {accessCode === "c" && (
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">{renderRoleSelect()}</td>
                </tr>
                )}
                {accessCode !== "c" && (
                  <tr className="admin__create-user-table-row">
                    <td className="admin__create-user-table-divider">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={"checkedRSA"}
                            checked={isRSA} 
                            onChange={() => setIsRSA(!isRSA)}
                          />
                        }
                        label="Retail Sales Associate (RSA)"
                      />
                    </td>
                  </tr>
                )}
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">{renderLocationSelect()}</td>
                </tr>
                <tr>
                  <td className="admin__create-user-table-divider">
                    <FormControl>
                      <FormLabel>Enable Sample Reminders?</FormLabel>
                      <Grid className="mnd__tax-switch" component="label" container spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Tooltip arrow={true} title={reminderSwitchToolTip} >
                            <Switch
                              checked={reminderSwitchState}
                              onChange={(e) => setReminderSwitchState(e.target.checked)}
                              color="primary"
                              size="small"
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </FormControl>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                  <Button className="user__change-password-modal" 
                    variant="contained"
                    onClick={handleOpen}
                    >
                    Change Password
                  </Button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="admin__form-actions">
              <Button
                onClick={() => handleUpdateUser(user.userId)}
                className="admin__update-user"
                variant="contained"
              >
                Update
              </Button>
              <div style={{marginLeft: 20}}>
              <Button
                onClick={() => handleUserDelete(currentUserId, user.userId)}
                className="admin__delete-user"
                variant="contained"
              >
                Delete
              </Button>
              </div>
            </div>
          </Paper>
          <Dialog fullWidth open={open} onClose={handleClose}>
          <DialogTitle className="user__cp-modal-header">
            {"Change Password"}
          </DialogTitle>
          <DialogContent dividers>
            {/* <Paper className="Admin_create-user-form"> */}
            <form className="user__cp-container">
              <TextField
                error={isValidNewPassword()}
                className="user__change-password-input"
                label="Password"
                value={newPassword}
                onChange={handleNewPassword}
                placeholder="Password"
                variant="filled"
                type="password"
                helperText={
                  isValidNewPassword()
                    ? "password must be at least 8 letters long"
                    : null
                }
              />
              <br />
              <TextField
                error={isValidPassword()}
                className="user__change-password-input"
                label="Confirm password"
                value={confirmPassword}
                onChange={handleConfirmPassword}
                placeholder="Confirm Password"
                variant="filled"
                type="password"
                helperText={isValidPassword() ? "passwords did not match" : null}
              />
            </form>
            {/* </Paper> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" className="user__pw-change-button" autoFocus>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className="user__cp-btn"
              variant="contained"
              autoFocus
              disabled={isDisabled()}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      </div>
    ) : (
      <div>Select User</div>
    );
  };

  return <div className="admin-edit-user-paper">{renderUser(user)}</div>;
};

export default EditUser;
