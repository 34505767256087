import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
import { getUser } from "../helpers";
import { baseUrl } from "../Contants";

export const AnnouncementsContext = createContext();

export const AnnouncementsContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [announcements, setAnnouncements] = useState([]);
  const [update, setUpdate] = useState(false);

  // /admin/accesstypes

  const isAdmin = user.access_type_cd === "a" ? true : false;

  useEffect(() => {
    async function getAnnouncements() {
      const result = await axios(`${baseUrl}/api/admin/announcements`);
      setAnnouncements(result.data.data);
    }
    if (isAdmin) {
      getAnnouncements();
      setUpdate(false);
    }
  }, [isAdmin, update]);

  return (
    <AnnouncementsContext.Provider value={{ announcements, setUpdate }}>
      {children}
    </AnnouncementsContext.Provider>
  );
};
