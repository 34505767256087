import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

// MUI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { DialogTitle,
DialogContent,
Dialog,
DialogActions,
Typography} from "@material-ui/core";

// Icons
import LinkIcon from '@material-ui/icons/Link';
import PersonIcon from '@material-ui/icons/Person';

// Dependecies
import UserDetails from "../Samples/UserDetails";
import ProductStatsDialog from "../Product/ProductStatsDialog";

const CustomTable = (props) => {
  const { data, headers } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userOpen, setUserOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userPicture, setUserPicture] = useState("");
  const [showStatsDialog, setShowStatsDialog] = useState(false);
  const [statsProduct, setStatsProduct] = useState();

  const handleUserOpen = (name, email, phone, picture) => {
      setUserName(name);
      setUserEmail(email);
      setUserPhone(phone);
      setUserPicture(picture);
      setUserOpen(true);
  };

  const handleProductOpen = (productUuid) => {

  };

  const handleUserClose = () => {
    setUserOpen(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderUserDetails = () => {
    return (
        <Dialog
        open={userOpen}
        onClose={handleUserClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
        >
        <DialogTitle id="alert-dialog-title">
            User Information
        </DialogTitle>
        <DialogContent>
            <UserDetails name={userName} email={userEmail} phone={userPhone} picture={userPicture} />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleUserClose} variant="contained" style={{margin:10, width:80}}>
            Ok
            </Button>
        </DialogActions>
        </Dialog>
    );
  };

  const renderTableHeaders = (columns) => {
    return columns.map((column) => (
      <TableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth }}
      >
        {column.label}
      </TableCell>
    ));
  };

  const renderTableData = (columns, rows) => {
    return rows.length
      ? rows
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, i) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align} style={{fontWeight:"normal"}}>
                      {column.format && typeof value === "number"
                        ?  column.format(value)
                        : column.id === "name" ? (
                          <Button
                            onClick={() => {handleUserOpen(row.name, row.email, row.phone, row.picture)}}
                            variant="text"
                            style={{textDecoration:"underline", fontSize:12}}
                            >
                            {value}
                          </Button>
                        ) : column.id === "trade_name" ? (
                          <u 
                            style={{cursor: "pointer"}}
                            onClick={() => {
                              setStatsProduct(row);
                              setShowStatsDialog(true);
                            }}
                          >{value}</u>
                        ) : 
                        column.id === "liked_dttm" ||
                        column.id === "unliked_dttm" ||
                        column.id === "click_date" ? 
                        value ? value.split(" ")[0] : value : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
      : null;
  };

  return (
    <>
      <TableContainer className="stats__mvp-table-container">
        {renderUserDetails()}
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>{renderTableHeaders(headers)}</TableRow>
          </TableHead>
          <TableBody>{renderTableData(headers, data)}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ProductStatsDialog 
        product={statsProduct} 
        isOpen={showStatsDialog}
        showLocationSelection={true}
        handleOnClose={() => {
          setShowStatsDialog(false);
          setStatsProduct(undefined);
        }}
      />
    </>
  );
};

export default CustomTable;
