import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../Context/AppContextProvide";

function AuthRoute({ component: Component, authed, ...rest }) {
  const { user, loading, isLoggedOut } = useContext(AppContext);

  const isInternalUser = user && ["a", "rw", "ro"].includes(user.access_type_cd);
  const isAuthenticated = user && new Date().getTime() < user.will_expire_at;

  if (isLoggedOut) {
    return (<Redirect to={{ pathname: "/signin" }}/>);
  }

  if (loading) {
    return (<div>Loading...</div>);
  }
  
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && isInternalUser) {
          return (<Component { ...props }/>);
        } else {
          return (<Redirect to={{ pathname: "/customer-home", state: { from: props.location } }}/>);
        }
      }}
    />
  );
}

export default AuthRoute;
