import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../../Contants";

// styles
import "../styles/LeadManagement.css";

// MUI
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableFooter,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  IconButton,
  Dialog,
  Tooltip
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, ChevronLeft, ChevronRight, ExpandMore, Info, GetApp } from "@material-ui/icons";

import { AlertContext } from "../../Context/AlertContextProvider";
import { download, JsonToCsvConverter } from "../../Util/utilsFunctions";
import ViewLeadModal from "./ViewLeadModal";
import ViewNotesModal from "./ViewNotesModal";
import ChangeStatusDialog from "./ChangeStatusDialog";
import ChangeAssignmentDialog from "./ChangeAssignmentDialog";
import ViewCheckoutsModal from "./ViewCheckoutsModal";
import ViewFavoritesModal from "./ViewFavoritesModal";
import ExportLeadDataDialog from "./ExportLeadDataDialog";

export default function AllLeads(props) {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const [leads, setleads] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState("name");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("status");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedLead, setSelectedLead] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [expandedLead, setExpandedLead] = useState(undefined);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const refreshData = () => {
    setRefresh(true);
  }

  const getLeads = async () => {
    const result = await axios.get(`${baseUrl}/api/leads`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error retreiving leads");
      handleAlertOpen();
      return;
    }

    const filteredLeads = result.data.leadList;
    setleads([...filteredLeads]);
  }

  const assignLead = async (lead_id, rsa_id) => {
    if (!lead_id || !rsa_id) {
      setMessageType("error");
      setMessage("Error assigning lead");
      handleAlertOpen();
      return;
    }

    const result = await axios.post(`${baseUrl}/api/leads/${lead_id}/assign/${rsa_id}`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error assigning lead");
      handleAlertOpen();
      return;
    }

    setMessageType("success");
    setMessage("Lead has been assigned!");
    handleAlertOpen();
    refreshData();
  }

  useEffect(() => {
    if (!refresh) return;
    
    getLeads();
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    if (!props.isOpen) {
      setRefresh(false);
      return;
    }

    refreshData();
  }, [props.isOpen])

  useEffect(() => {
    setModalType("");
    setSelectedLead(undefined);
    setIsModalOpen(false);
    setIsAssignDialogOpen(false);
    setIsStatusDialogOpen(false);
    setExpandedLead(undefined);
  }, [props.isMobile])

  useEffect(() => {
    setPage(0);
  }, [searchTerm, sortBy, sortOrder, rowsPerPage, refresh]);

  if (!props.isOpen) return (<></>);

  const columnsConfigs = [
    { 
      name: "Name",
      colSpan: 1,
      canSort: true,
      canFilter: true,
    },
    { 
      name: "Email",
      colSpan: 2,
      canSort: true,
      canFilter: true,
    },
    { 
      name: "Phone",
      colSpan: 1,
      canSort: true,
      canFilter: true,
    },
    { 
      name: "Status",
      colSpan: 1,
      canSort: true,
      canFilter: true,
    },
    { 
      name: "Location",
      colSpan: 1,
      canSort: true,
      canFilter: true,
    },
    { 
      name: "Assigned To",
      colSpan: 1,
      canSort: false,
      canFilter: true,
    },
    { 
      name: "Tools",
      colSpan: 1,
      canSort: false,
      canFilter: false,
    }
  ];

  const tableHeaders = columnsConfigs.map(config => {
    const columnValue = config.name.toLowerCase().replace(" ", "_");

    if (!config.canSort) {
      return (
        <TableCell key={"column-header-" + columnValue}>
          <Typography variant="h6">{config.name}</Typography>
        </TableCell>
      )
    }

    const isButtonVisible = sortBy === columnValue;
    const buttonStyle = {minWidth: "auto", paddingTop: "3px"};

    if (isButtonVisible) {
      buttonStyle.visibility = "visible";
      buttonStyle.color = "black";
    }

    return (
      <TableCell 
        className="lead-management__sort-header"
        key={"column-header-" + columnValue} 
        colSpan={columnValue === "email" ? 2 : 1} 
        onClick={() => updateSortParams(columnValue)}
      >
        <Typography variant="h6" style={{float:'left'}}>
          {isButtonVisible ? <u>{config.name}</u> : <>{config.name}</>}
        </Typography>
        <Button 
          className="lead-management__sort-button"
          variant="text" 
          style={buttonStyle} 
          onClick={() => updateSortParams(columnValue)}
        >
          {isButtonVisible && sortOrder === "desc" ? <ArrowDownward /> : <ArrowUpward />}
        </Button>
      </TableCell>
    )
  });

  const getStrippedNumber = (number) => {
    const strippedNumber = number?.replace(/\D+/g, "");

    if (!strippedNumber) return '';
    
    return strippedNumber.length > 11 ? strippedNumber.substring(strippedNumber.length - 11) : strippedNumber;
  }

  const fuzzySearch = (value, searchTerm) => {
    const regex = new RegExp(`${searchTerm}`, "gi");
    return regex.test(value);
  }

  const specificSearch = (value, searchTerm) => {
    const trimmedSearchTerm = searchTerm.replace(/\"/g, "");
    const regex = new RegExp(`\\b${trimmedSearchTerm}\\b`, "g");
    return regex.test(value)
  } 

  const filterLeadsBySearchParams = (lead) => {
    if (!searchBy || !searchTerm) return true;
    const isSpecificTermSearch = searchTerm.charAt(0) === "\"" && searchTerm.charAt(searchTerm.length - 1) === "\"";

    let result = false;
    switch (searchBy) {
      case "name":
      case "email":
      case "status": 
        if (!lead[searchBy]) return false;

        result = isSpecificTermSearch ? specificSearch(lead[searchBy], searchTerm) : fuzzySearch(lead[searchBy], searchTerm);
        if (result) return true;
        break;
      case "location":
        if (!lead.location_name) return false;

        result = isSpecificTermSearch ? specificSearch(lead.location_name, searchTerm) : fuzzySearch(lead.location_name, searchTerm);
        if (result) return true;
        break;
      case "assigned_to":
        if (!lead.rsa_name) return false;
        
        result = isSpecificTermSearch ? specificSearch(lead.rsa_name, searchTerm) : fuzzySearch(lead.rsa_name, searchTerm);
        if (result) return true;
        break;
      case "phone":
        const leadStrippedNumber = getStrippedNumber(lead[searchBy]);
        const searchStrippedNumber = getStrippedNumber(searchTerm);
        if (leadStrippedNumber?.includes(searchStrippedNumber)) return true;
        break;
    }

    return false;
  }

  const sortLeadsBySortParams = (prevLead, currLead) => {
    const isPhoneSort = sortBy === "phone";
    const isLocationSort = sortBy === "location";
    let prev;
    let curr;

    if (isPhoneSort) {
      prev = getStrippedNumber(prevLead[sortBy]) ?? '';
      curr = getStrippedNumber(currLead[sortBy]) ?? '';
    } else if (isLocationSort) {
      prev = prevLead.location_name ?? '';
      curr = currLead.location_name ?? '';
    } else {
      prev = prevLead[sortBy] ?? '';
      curr = currLead[sortBy] ?? '';
    }

    let result = 0;

    if (prev < curr) result = -1;
    if (prev > curr) result = 1;

    return sortOrder === "asc" ? result : -1 * (result);
  }

  const leadRows = leads
    .filter((lead) => filterLeadsBySearchParams(lead))
    .sort((prevLead, currLead) => sortLeadsBySortParams(prevLead, currLead))
    .map((lead, index) => {
      const strippedNumber = getStrippedNumber(lead.phone)
      const numberUrl = "tel:" + (strippedNumber.length === 11 ? "+" + strippedNumber : strippedNumber);
      
      if (props.isMobile) {
        return (
          <Paper key={"lead-mobile-" + lead.lead_id} className="lead-management__mobile-lead-row">
            <Accordion 
              expanded={expandedLead === lead.lead_id} 
              onChange={() => setExpandedLead(expandedLead === lead.lead_id ? undefined : lead.lead_id)} 
            >
              <AccordionSummary
                expandIcon={<ExpandMore style={{color: "#28C4FC"}}/>}
                aria-controls={"panel-" + lead.name.replace(/[^A-z]+/g, "-") + "-content"}
                id={"panel-" + lead.name.replace(/[^A-z]+/g, "-") + "-header"}
                style={{
                  backgroundColor: "#142E3E",
                  color: "#28C4FC"
                }}
              > 
                <div>
                  <Typography variant="h5">{lead.name}</Typography>
                  <Typography className="lead-management__secondary-text">{lead.location_name}</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{display: "block"}}>
                <div style={{marginBottom: "20px"}}>
                  <Typography style={{margin: "0px auto"}} paragraph><a href={"mailto:" + lead.email}>{lead.email}</a></Typography>
                  <Typography style={{margin: "0px auto"}} paragraph><a href={numberUrl}>{lead.phone}</a></Typography>
                </div>
                <Typography style={{margin: "0px auto"}} paragraph><b>Status:</b> {lead.status}</Typography>
                <Typography style={{margin: "0px auto"}} paragraph><b>Assigned To:</b> {lead.rsa_name}</Typography>
                { props.user.access_type_cd === "a" ? (
                    <Button 
                      variant="contained"
                      style={{margin: "20px 20px 0px 0px"}}
                      onClick={() => {
                        setSelectedLead(lead);
                        setIsAssignDialogOpen(true);
                      }}
                    >
                      {lead.rsa_user_id ? "Reassign Lead" : "Assign Lead"}
                    </Button>
                  ) : undefined
                }
                {
                  !lead.rsa_user_id ? (
                    <Button 
                      variant="contained"
                      style={{margin: "20px 20px 0px 0px"}}
                      onClick={() => assignLead(lead.lead_id, props.user.user_id)}
                    >
                      Assign Lead to Me
                    </Button>
                  ) : undefined
                }
                { props.user.access_type_cd === "a" || lead.rsa_user_id === props.user.user_id ? (
                    <Button 
                      variant="contained"
                      style={{margin: "20px 20px 0px 0px"}}
                      onClick={() => {
                        setSelectedLead(lead);
                        setIsStatusDialogOpen(true);
                      }}
                    >
                      Update Status
                    </Button>
                  ) : undefined
                }
                <Button 
                  variant="contained" 
                  style={{margin: "20px 20px 0px 0px"}}
                  onClick={() => {
                    setSelectedLead(lead);
                    setIsModalOpen(true);
                    setModalType("notes");
                  }} 
                >
                  View Notes
                </Button>
                <Button 
                  variant="contained" 
                  style={{margin: "20px 20px 0px 0px"}}
                  onClick={() => {
                    setSelectedLead(lead);
                    setIsModalOpen(true);
                    setModalType("checkouts");
                  }} 
                >
                  View Checkouts
                </Button>
                <Button 
                  variant="contained" 
                  style={{margin: "20px 20px 0px 0px"}}
                  onClick={() => {
                    setSelectedLead(lead);
                    setIsModalOpen(true);
                    setModalType("favorites");
                  }} 
                >
                  View Favorites
                </Button>
                <Button 
                  variant="contained"
                  style={{margin: "20px 20px 0px 0px"}}
                  onClick={() => {
                    setSelectedLead(lead);
                    setIsExportDialogOpen(true);
                  }}
                >
                  Export Lead Data
                </Button>
              </AccordionDetails>
            </Accordion>
          </Paper>
        )
      } else {
        return (
          <TableRow key={"lead-desktop-" + lead.lead_id} className="lead-management__desktop-lead-row">
            <TableCell className="lead-management__word-break">
              {lead.name}
            </TableCell>
            <TableCell className="lead-management__word-break" colSpan={2}>
              <a href={"mailto:" + lead.email}>{lead.email}</a>
            </TableCell>
            <TableCell className="lead-management__word-break">
              <a href={numberUrl}>{lead.phone}</a>
            </TableCell>
            <TableCell className="lead-management__word-break">
              {lead.status}
            </TableCell>
            <TableCell className="lead-management__word-break">
              {lead.location_name}
            </TableCell>
            <TableCell className="lead-management__word-break">
              {lead.rsa_name}
            </TableCell>
            <TableCell>
              <Button 
                variant="contained" 
                onClick={() => {
                  setSelectedLead(lead);
                  setIsModalOpen(true);
                  setModalType("leads");
                }} 
              >
                Manage Lead
              </Button>
            </TableCell>
          </TableRow>
        )
      }
    });

  const updateSortParams = (value) => {
    let newSortOrder = sortBy === value && sortOrder === "asc" ? "desc" : "asc";
    
    setSortBy(value);
    setSortOrder(newSortOrder);
  }

  const handleChangePage = (e, page) => setPage(page);

  const handleChangeRowsPerPage = (e) => setRowsPerPage(parseInt(e.target.value));

  const paginationControls = (filteredList) => {
    const rowsPerPageOptions = [5, 10, 25];
    
    if (props.isMobile) {
      const buttonStyles = {
        backgroundColor: "rgba(0, 0, 0, 0.09)",
        margin: "0px 0px 0px 10px"
      };
      const isFirstPage = page === 0;
      const isLastPage = ((page + 1) * rowsPerPage) >= filteredList.length;
      const currentStart = Math.max(page * rowsPerPage, 1);
      const currentEnd = Math.min((page * rowsPerPage) + rowsPerPage, filteredList.length);

      return (
        <div style={{margin: "0px 0px 20px", maxWidth: "400px"}}>
          <FormControl>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page"
              value={rowsPerPage}
              style={{width: "125px", marginRight: "10px", marginBottom: "20px"}}
              variant="filled"
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {rowsPerPageOptions.map(o => {
                return (
                  <MenuItem key={o} value={o}>{o}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <div style={{display: "inline-block"}}>
            <Typography style={{display: "inline-block", verticalAlign: "middle", margin: "0px", textAlign: "center"}}>{currentStart} - {currentEnd} of {filteredList.length}</Typography>
            <IconButton variant="text" disabled={isFirstPage} style={buttonStyles} onClick={() => setPage(isFirstPage ? 0 : page - 1)}><ChevronLeft /></IconButton>
            <IconButton variant="text" disabled={isLastPage} style={buttonStyles} onClick={() => setPage(isLastPage ? page : page + 1)}><ChevronRight /></IconButton>
          </div>
        </div>
      )
    } 

    return (
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={columnsConfigs.reduce((sum, config) => sum + config.colSpan, 0)}
          count={filteredList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          style={{borderTop: "1px solid rgba(224, 224, 224, 1)"}}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableRow>
    )
  }

  const handleDownload = async () => {
    if (!leads?.length) {
      setMessageType("error");
      setMessage("Error retrieving lead data.");
      handleAlertOpen();
      return;
    }   

    const data = leads.filter((lead) => filterLeadsBySearchParams(lead))
      .sort((prevLead, currLead) => sortLeadsBySortParams(prevLead, currLead))
      .map(record => {
        return {
          name: record.name,
          phone: record.phone,
          email: record.email,
          location_name: record.location_name,
          status: record.status,
          role_name: record.role_name,
          rsa_name: record.rsa_name,
        }
      });


    if (!data) {
      setMessageType("error");
      setMessage("Error exporting lead data.");
      handleAlertOpen();
      return;
    }

    const date = new Date();
    const filename = "All-Leads_" + date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    const csvData = JsonToCsvConverter(data);
    download(filename, csvData);
  };

  const paginatedRows = leadRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const filterItems = columnsConfigs.filter(c => c.canFilter).map(c => {
    const itemName = c.name.toLowerCase().replace(" ", "_");
    return (
      <MenuItem key={"filter-" + itemName} value={itemName}>{c.name}</MenuItem>
    )
  });

  return (
    <>
      <Grid container spacing={2} style={{marginBottom: "1em"}}>
        <Grid item sm={12} md={12} lg={12}>
          <Typography paragraph>
            Please use the inputs below to filter the results
            <Tooltip
              style={{display: "inline-block", margin: "0px 0px -5px 5px"}} 
              title={
                <>
                  <span>
                    If you would like to search for a specific term, then please add quotes around the term you are searching for (i.e. "search").
                  </span>
                  <span style={{display: "block", marginTop: "10px"}}>
                    Please note that this type of search is case sensitive.
                  </span>
                </>  
              }
            >
              <Info></Info>
            </Tooltip>
          </Typography>
          <FormControl variant="filled" style={{width: "190px", margin: "0px 20px 10px 0px"}}>
            <InputLabel id="search-by-label">Search By</InputLabel>
            <Select
              labelId="search-by-label"
              id="search-by"
              value={searchBy}
              onChange={(e) => {
                setSearchBy(e.target.value);
                setSearchTerm("");
              }}
            >
              {filterItems}
            </Select>
          </FormControl>
          <TextField label="Search" variant="filled" value={searchTerm} onChange={(e) => setSearchTerm(`${e.target.value}`)}/>
        </Grid>
      </Grid>
      { !props.isMobile ? (
          <div className="lead-management__desktop-lead-table">
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows}
              </TableBody>
              <TableFooter>
                {paginationControls(leadRows)}
              </TableFooter>
            </Table>
            <ViewLeadModal 
              className="lead-management__desktop-modal" 
              lead={selectedLead} 
              user={props.user} 
              isModalOpen={isModalOpen && modalType === "leads"}
              isMobile={props.isMobile}
              handleOnClose={() => {
                setIsModalOpen(false);
                setModalType("");
                setSelectedLead(undefined);
              }}
              refreshParent={refreshData}
            />
          </div>
        ) : (
          <div className="lead-management__mobile-lead-table">
            {paginationControls(leadRows)}
            {paginatedRows}
            <ViewNotesModal 
              className="lead-management__mobile-modal" 
              lead={selectedLead} 
              user={props.user} 
              isModalOpen={isModalOpen && modalType === "notes"}
              handleOnClose={() => {
                setIsModalOpen(false);
                setModalType("");
                setSelectedLead(undefined);
              }}
            />
            <ViewCheckoutsModal 
              className="lead-management__mobile-modal" 
              lead={selectedLead} 
              isModalOpen={isModalOpen && modalType === "checkouts"}
              handleOnClose={() => {
                setIsModalOpen(false);
                setModalType("");
                setSelectedLead(undefined);
              }}
            />
            <ViewFavoritesModal 
              className="lead-management__mobile-modal" 
              lead={selectedLead} 
              isModalOpen={isModalOpen && modalType === "favorites"}
              handleOnClose={() => {
                setIsModalOpen(false);
                setModalType("");
                setSelectedLead(undefined);
              }}
            />
            <ChangeStatusDialog 
              lead={selectedLead}
              user={props.user}
              isStatusDialogOpen={isStatusDialogOpen} 
              handleOnClose={() => {
                setIsStatusDialogOpen(false);
                setSelectedLead(undefined);
              }}
              refreshParent={refreshData}
            />
            <ChangeAssignmentDialog 
              lead={selectedLead}
              user={props.user}
              isAssignDialogOpen={isAssignDialogOpen} 
              handleOnClose={() => {
                setIsAssignDialogOpen(false);
                setSelectedLead(undefined);
              }}
              refreshParent={refreshData}
            />
            <ExportLeadDataDialog 
              isOpen={isExportDialogOpen}
              lead={selectedLead}
              handleOnClose={() => {
                setIsExportDialogOpen(false);
                setSelectedLead(undefined);
              }}
            />
          </div>
        )
      }
      <div style={{textAlign: "right", margin: "20px auto 0px"}}>
        <Button
          variant="contained"
          className="stats__mvp-data-download-button"
          onClick={() => handleDownload()}
          endIcon={<GetApp />}
        >
          Download
        </Button>
      </div>
    </>
  )   
}