import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../../Contants";

// styles
import "../styles/LeadManagement.css";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Modal,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { CloseRounded, Delete, Edit } from "@material-ui/icons";

import { AlertContext } from "../../Context/AlertContextProvider";

function NewNote(props) {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const [description, setDescription] = useState("");
  const [inputError, setInputError] = useState();

  const createNote = async (lead_id, description) => {
    if (inputError) return;

    if (!lead_id) {
      setMessageType("error");
      setMessage("Error creating note");
      handleAlertOpen();
      return;
    }

    if (!description) {
      setInputError("Text field is empty");
      return;
    }

    const result = await axios.post(`${baseUrl}/api/leads/${lead_id}/notes`, { note: description });
    
    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error creating note");
      handleAlertOpen();
      return;
    }

    setMessageType("success");
    setMessage("New note created!");
    handleAlertOpen();
    props.refreshParent();
    props.setIsNewNoteOpen(false);
  }

  const validateNote = (description) => {
    setDescription(description);

    if (/\S/.test(description)) {
      setInputError(undefined);
    } else {
      setInputError("Text field is empty");
    }
  }

  return ( 
    <Dialog
      open={props.isNewNoteOpen}
      onClose={() => props.setIsNewNoteOpen(false)}
      aria-labelledby="New Note Dialog"
      aria-describedby="New Note Dialog"
    >
      <DialogTitle id="new-note-dialog-title">New Note</DialogTitle>
      <DialogContent className="lead-management__dialog-content">
        <TextField
          label={"Create Description"}
          multiline
          autoFocus
          rows={6}
          rowsMax={10}
          variant="outlined"
          value={description}
          error={inputError ? true : false}
          helperText={inputError}
          style={{width: "100%", whiteSpace: "pre"}}
          onChange={(e) => validateNote(e.target.value)}
        />
      </DialogContent>
      <DialogActions style={{padding: "15px 24px"}}>
        <Button 
          variant="outlined"
          onClick={() => props.setIsNewNoteOpen(false)}
        >
          Cancel
        </Button> 
        <Button 
          disabled={inputError ? true : false}
          variant="outlined" 
          onClick={() => createNote(props.lead?.lead_id, description)} 
          style={{marginLeft: "20px", color: "rgba(9, 150, 42, 0.7)"}}
        >
          Save Note
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function NoteRecord(props) {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [description, setDescription] = useState(props.note?.note ?? "");
  const [inputError, setInputError] = useState();

  const updateNote = async (lead_id, note_id, description) => {
    if (inputError) return;

    if (!lead_id || !note_id) {
      setMessageType("error");
      setMessage("Error updating note");
      handleAlertOpen();
      return;
    }

    if (!description) {
      setInputError("Text field is empty");
      return;
    }

    const result = await axios.put(`${baseUrl}/api/leads/${lead_id}/notes/${note_id}`, { note: description });

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error updating note");
      handleAlertOpen();
      return;
    }

    setMessageType("success");
    setMessage("Note updated!");
    handleAlertOpen();
    setIsEditorOpen(false);
    props.refreshParent();
  }

  const deleteNote = async (lead_id, note_id) => {
    if (!lead_id || !note_id)  {
      setMessageType("error");
      setMessage("Error deleting note");
      handleAlertOpen();
      return;
    }

    const result = await axios.delete(`${baseUrl}/api/leads/${lead_id}/notes/${note_id}`);
    
    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error deleting note");
      handleAlertOpen();
      return;
    }

    setMessageType("success");
    setMessage("Note deleted!");
    handleAlertOpen();
    props.refreshParent();
  }

  const validateNote = (description) => {
    setDescription(description);

    if (/\S/.test(description)) {
      setInputError(undefined);
    } else {
      setInputError("Text field is empty");
    }
  }

  const createdDate = new Date(props.note?.created_dttm).toLocaleString();
  const lastUpdatedDate = new Date(props.note?.last_modified_dttm).toLocaleString();

  return (
    <>
      <ListItem style={{width: "100%", backgroundColor: "#FFFFFF"}}>
        <div>
          <div style={{margin: "15px auto"}}>
            { isEditorOpen ? (
                <>
                  <TextField
                    label={"Edit Description"}
                    multiline
                    autoFocus
                    rowsMax={10}
                    variant="outlined"
                    value={description}
                    error={inputError ? true : false}
                    helperText={inputError}
                    style={{width: "100%", whiteSpace: "pre"}}
                    onChange={(e) => validateNote(e.target.value)}
                  />
                  <p>
                    <Button 
                      variant="outlined"
                      onClick={() => {
                        setDescription(props.note?.note);
                        setInputError(undefined);
                        setIsEditorOpen(false);
                      }}
                    >
                      Cancel
                    </Button>  
                    <Button 
                      disabled={inputError ? true : false}
                      variant="outlined" 
                      onClick={() => updateNote(props.note?.lead_id, props.note?.note_id, description)} 
                      style={{marginLeft: "20px", color: "rgba(9, 150, 42, 0.7)"}}
                    >
                      Save Changes
                    </Button>
                  </p>   
                </>
              ) : (
                <Typography style={{whiteSpace: "pre"}}>{description}</Typography>
              )
            }
          </div>
          <Typography variant="subtitle2"><b>{props.note?.create_by}</b></Typography>
          <Typography variant="subtitle2" style={{display: "inline-block", marginRight: "20px"}}><b>Created:</b> {createdDate}</Typography>
          { createdDate !== lastUpdatedDate ? (
              <Typography variant="subtitle2" style={{display: "inline-block", marginRight: "20px"}}><b>Last Updated:</b> {lastUpdatedDate}</Typography>
            ) : undefined
          }
        </div> 
        <ListItemSecondaryAction>
          { !isEditorOpen ? (
              <div>
                <IconButton edge="end" aria-label="edit-note" onClick={() => setIsEditorOpen(!isEditorOpen)}>
                  <Edit />
                </IconButton>
              </div>
            ) : undefined
          }
          <div>
            <IconButton 
              edge="end" 
              aria-label="delete-note"
              onClick={() => deleteNote(props.note?.lead_id, props.note?.note_id)}
            >
              <Delete/>
            </IconButton>
          </div>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  )
}

export default function ViewNotesModal(props) {  
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const [isNewNoteOpen, setIsNewNoteOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const refreshData = () => {
    setRefresh(true);
  }

  const getNotes = async (lead_id) => {
    if (!lead_id) return;
    const result = await axios(`${baseUrl}/api/leads/${lead_id}/notes`);
    
    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error retrieving notes");
      handleAlertOpen();
      setNotes([]);
      return;
    }

    setNotes([...result.data.leadNoteList]);
  }

  useEffect(() => {
    if (!props.isModalOpen || !props.lead?.lead_id) {
      setNotes([]);
      return;
    } 

    refreshData();
  }, [props.isModalOpen, props.lead?.lead_id])

  useEffect(() => {
    if (!refresh) return;

    getNotes(props.lead?.lead_id);
    setRefresh(false);
  }, [refresh])

  const notesList = notes?.map((note, index) => {
    return (
      <React.Fragment key={"note-" + note.note_id}>
        { index !== 0 ? (
            <Divider/>
          ) : undefined
        }
        <NoteRecord note={note} refreshParent={refreshData}/>
      </React.Fragment>
    )
  });

  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.handleOnClose}
      aria-labelledby="customer notes modal"
      aria-describedby="modal for managing customer notes"
    >
      <Paper 
        style={{
          position: "absolute", 
          top: "50%", 
          left: "50%", 
          transform: "translate(-50%, -50%)", 
          backgroundColor: "white",
          minHeight: "60%",
          width: "90%",
          padding: "20px 0px"
        }}
      >
        <div style={{margin: "0px 15px"}}>
          <CloseRounded style={{float: "right"}} onClick={props.handleOnClose}></CloseRounded>
          <Typography variant="h5">Notes</Typography>
          <Button 
            variant="outlined" 
            onClick={() => setIsNewNoteOpen(true)}
            style={{marginTop: "15px"}}
          >
            Add Note
          </Button>
        </div>
        <div>
          { isNewNoteOpen ? (
              <NewNote isNewNoteOpen={isNewNoteOpen} setIsNewNoteOpen={setIsNewNoteOpen} lead={props.lead} refreshParent={refreshData}/>
            ) : undefined 
          }
          { notesList?.length ? (
              <List style={{overflowY: "auto", backgroundColor: "#EEEEEE", marginTop: "20px", maxHeight: "70vh"}}>
                {notesList}
              </List> 
            ) : (
              <Typography style={{margin: "20px 15px"}}>No notes currently available for this lead</Typography>
            )
          } 
        </div>
      </Paper>
    </Modal>
  )
}