import React, { useContext, useEffect, useState } from "react";

// MUI
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

// styles
import "../styles/User.css";
import { changePassword } from "../../Actions";
import { AlertContext } from "../../Context/AlertContextProvider";

// dependents

// component
const ChangePassword = (props) => {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const [open, setOpen] = useState(props.open);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //   const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {}, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setNewPassword("");
    setConfirmPassword("");
    setOpen(false);
  };

  const handleNewPassword = (event) => {
    const value = event.target.value;
    setNewPassword(value);
  };

  const handleConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
  };

  const isValidPassword = () => {
    if (newPassword !== confirmPassword) return true;
    else return false;
  };

  const isValidNewPassword = () => {
    if (newPassword.length < 8) return true;
    else return false;
  };

  const isDisabled = () => {
    if (newPassword.length < 8) {
      return true;
    } else if (confirmPassword !== newPassword) {
      return true;
    } else if (newPassword.length > 8 && confirmPassword === newPassword) {
      return false;
    }
  };

  const handleSubmit = () => {
    changePassword(newPassword, confirmPassword)
      .then((result) => {
        setMessageType("success");
        setMessage("Password Changed!!");
        handleAlertOpen(true);
        handleClose();
      })
      .catch((err) => {
        setMessageType("error");
        setMessage("Error changing password");
        handleAlertOpen(true);
      });
  };

  return (
    <div>
      <Button className="user__change-password-modal"  variant="contained" onClick={handleOpen}>
        Change Password
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle className="user__cp-modal-header">
          {"Change Password"}
        </DialogTitle>
        <DialogContent dividers>
          {/* <Paper className="Admin_create-user-form"> */}
          <form className="user__cp-container">
            <TextField
              error={isValidNewPassword()}
              className="user__change-password-input"
              label="Password"
              value={newPassword}
              onChange={handleNewPassword}
              placeholder="Password"
              variant="filled"
              type="password"
              helperText={
                isValidNewPassword()
                  ? "password must be at least 8 letters long"
                  : null
              }
            />
            <br />
            <TextField
              error={isValidPassword()}
              className="user__change-password-input"
              label="Confirm password"
              value={confirmPassword}
              onChange={handleConfirmPassword}
              placeholder="Confirm Password"
              variant="filled"
              type="password"
              helperText={isValidPassword() ? "passwords did not match" : null}
            />
          </form>
          {/* </Paper> */}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose}  
            autoFocus
            variant="contained"
            className="user__password-change-button"
            >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className="user__cp-btn"
            variant="contained"
            autoFocus
            disabled={isDisabled()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangePassword;
