import React, { useContext, useState } from "react";
import { BulletinContext } from "../../../Context/BulletinContextProvider";

// styles
import "../../styles/Admin.css";
import noImg from "../../../images/noImg.png";

// MUI
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Actions
import { deleteBulletin } from "../../../Actions";

// dependents
import CreateBulletin from "./CreateBulletin";
import { AlertContext } from "../../../Context/AlertContextProvider";

const ManageBulletin = () => {
  const [open] = useState(false);
  const { bulletins, setUpdate } = useContext(BulletinContext);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const handleDeleteBulletin = (id) => {
    deleteBulletin(id)
      .then(() => {
        setMessage("Bulletin Deleted");
        setMessageType("success");
        handleAlertOpen();
        setUpdate(true);
      })
      .catch(() => {
        setMessage("Failed to delete bulletin");
        setMessageType("error");
        handleAlertOpen();
      });
  };

  const renderBulletins = (data) => {
    return data && data.length ? (
      <div className="admin__cards-container">
        {data.map((bulletin) => {
          const {
            message,
            bulletinId,
            url,
            title,
            effectiveStartDate,
            effectiveEndDate,
          } = bulletin;
          return (
            <div key={bulletinId}>
              <Card className="admin__bulletin-card">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Bulletin Post"
                    height="140"
                    image={url !== null ? url : noImg}
                    title="Bulletin Post"
                    className="admin__card-image"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {title !== null ? title : "No Title"}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {message !== null ? message : "No message"}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className="admin__card-actions">
                  <CreateBulletin
                    imageUrl={url}
                    open={open}
                    type="Edit"
                    message={message}
                    title={title}
                    effectiveStartDate={effectiveStartDate}
                    effectiveEndDate={effectiveEndDate}
                    bulletinId={bulletinId}
                  />
                  <Button
                    onClick={() => handleDeleteBulletin(bulletinId)}
                    size="small"
                    className="admin__card-action-delete"
                    variant="contained"
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </div>
          );
        })}
      </div>
    ) : (
      <div>No Bulletins</div>
    );
  };

  return (
    <div style={{backgroundColor: "#FFFFFF", padding:10}}>
      <div>{renderBulletins(bulletins)}</div>
      <div className="admin__create-bulletin-container">
        <CreateBulletin open={open} type="Create" />
      </div>
    </div>
  );
};

export default ManageBulletin;
