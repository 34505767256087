import axios from "axios";

// constants
import { baseUrl } from "../Contants";

export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  return axios.get(`${baseUrl}/api/auth/logout`);
};

// products actions

export const getProductsWithFilters = url => {
  return axios.get(`${baseUrl}/api/user/products${url}`);
};

export const getProductDetails = (productId, roleId) => {
  return axios.get(`${baseUrl}/api/user/product/${productId}/${roleId}`);
};

export const getMergedProductDetails = (productId, roleId) => {
  return axios.get(`${baseUrl}/api/user/product/merged/${productId}/${roleId}`);
};

export const updateRPO = (rpoData) => {
  const url = `${baseUrl}/api/user/rpo`;
  return axios.post(url, { rpoData });
};

// product actions
export const updateProduct = (
  costId,
  margin,
  effectiveStartDate,
  effectiveEndDate,
  comments
) => {
  const data = { margin, effectiveStartDate, effectiveEndDate, comments };
  const url = `${baseUrl}/api/user/costs/${costId}/margin`;
  return axios.put(url, data);
};

export const resetMargin = marginId => {
  const url = `${baseUrl}/api/user/margins/${marginId}`;
  return axios.delete(url);
};

// user actions
export const getUserNames = () => {
  return axios.get(`${baseUrl}/api/admin/usernames`);
};

export const userNameExists = (user) => {
  return axios.get(`${baseUrl}/api/signup/user/valid/?user=${user}`);
};

export const confirmUserReg = (reset_token) => {
  return axios.get(`${baseUrl}/api/confirmuserreg/${reset_token}`);
};

export const createUser = (userName, phone, accessTypeCode) => {
  const url = `${baseUrl}/api/admin/users`;
  return axios.post(url, { userName, phone, accessTypeCode });
};

export const registerLocalUser = (
  name,
  email,
  username,
  phone,
  password,
  accesstype,
  roleId,
  locationId,
  isRsa
) => {
  const url = `${baseUrl}/api/signupInternal`;
  return axios.post(url, {
    name,
    email,
    username,
    phone,
    password,
    accesstype,
    roleId,
    locationId,
    isRsa
  });
};

export const customerRegisterLocalUser = (name, email, username, phone, password, key) => {
  const url  = `${baseUrl}/api/signup`;
  return axios.post(url, {name, email, username, phone, password, key})
}

export const updateUser = (name, email, phone, userId, roleId, accessTypeCode, locationId, isRsa, sample_reminder) => {
  const url = `${baseUrl}/api/admin/users/${userId}`;
  return axios.put(url, { name, email, phone, roleId, accessTypeCode, locationId, isRsa, sample_reminder });
};

export const updateLocalUser = (name, email, phone, userId) => {
  const url = `${baseUrl}/api/user/local/update/${userId}`;
  return axios.put(url, { name, email, phone });
};

export const deleteUser = userId => {
  const url = `${baseUrl}/api/admin/users/${userId}`;
  return axios.delete(url);
};

export const createRole = (role_name, role_description, prefix, auto_checkout_sample, sample_reminder) => {
  const url = `${baseUrl}/api/admin/roles/`;
  return axios.post(url, { role_name, role_description, prefix, auto_checkout_sample, sample_reminder });
};

export const getRoleToMargin = role_id => {
  const url = `${baseUrl}/api/admin/roles/${role_id}/margins`;
  return axios.get(url, { role_id });
};

export const upsertRoleToMargin = (
  role_id,
  material_class_cd,
  margin,
  add_to_cost,
  add_to_price,
  disclaimer
) => {
  const url = `${baseUrl}/api/admin/roles/${role_id}/margins/`;
  return axios.post(url, { role_id, material_class_cd, margin, add_to_cost, add_to_price, disclaimer });
};

export const getRoles = () => {
  return axios.get(`${baseUrl}/api/admin/roles`);
};

export const getAccessTypes = () => {
  return axios.get(`${baseUrl}/api/admin/accesstypes`);
};

export const updateRole = (role_id, role_name, role_description, prefix, auto_checkout_sample, sample_reminder) => {
  const url = `${baseUrl}/api/admin/roles/${role_id}`;
  return axios.post(url, { role_name, role_description, prefix, auto_checkout_sample, sample_reminder });
};

export const updateUserRole = role_id => {
  const url = `${baseUrl}/api/admin/updateuserrole/${role_id}`;
  return axios.post(url, { role_id });
};

export const editRolePrefix = (role_id, switch_state) => {
  const url = `${baseUrl}/api/admin/roleprefix`;
  return axios.post(url, { role_id, switch_state });
};

export const deleteRole = role_id => {
  const url = `${baseUrl}/api/admin/roles/${role_id}`;
  return axios.delete(url, { role_id });
};

// announcement actions
export const createAnnouncement = (
  title,
  announcement,
  effectStartDate,
  effectEndDate
) => {
  const url = `${baseUrl}/api/admin/announcements`;
  return axios.post(url, {
    title,
    announcement,
    effectStartDate,
    effectEndDate
  });
};

export const updateAnnouncement = (
  announcementId,
  title,
  announcement,
  effectStartDate,
  effectEndDate
) => {
  const url = `${baseUrl}/api/admin/announcements/${announcementId}`;
  return axios.put(url, {
    title,
    announcement,
    effectStartDate,
    effectEndDate
  });
};

export const deleteAnnouncement = announcementId => {
  const url = `${baseUrl}/api/admin/announcements/${announcementId}`;
  return axios.delete(url);
};

// bulletin actions

export const createBulletin = data => {
  const url = `${baseUrl}/api/admin/bulletin`;
  return axios.post(url, data);
};

export const deleteBulletin = id => {
  const url = `${baseUrl}/api/admin/bulletin/${id}`;
  return axios.delete(url);
};

export const updateBulletin = (bulletinId, data) => {
  const url = `${baseUrl}/api/admin/bulletins/${bulletinId}`;
  return axios.put(url, data);
};

// print actions
export const updatePrintQueue = productUUIDList => {
  const url = `${baseUrl}/api/user/queue`;
  return axios.post(url, { productUUIDList });
};

export const deleteFromPrintQueue = (printQueueId) => {
  const url = `${baseUrl}/api/user/products/${printQueueId}/queue`;
  return axios.delete(url);
};

export const clearPrintQueue = () => {
  const url = `${baseUrl}/api/user/queue`;
  return axios.delete(url);
};

export const printPreview = () => {
  return axios.get(`${baseUrl}/api/user/queue/print`);
};

// tax update actions

export const updateTaxRate = taxRate => {
  const url = `${baseUrl}/api/admin/taxrate/update`;
  return axios.post(url, { taxRate });
};

export const updateCompanyMargin = companyMargin => {
  const url = `${baseUrl}/api/admin/margin/company`;
  return axios.post(url, { marginCompanyRate: Number(companyMargin) });
};

export const updateLoadedMargin = loadedMargin => {
  const url = `${baseUrl}/api/admin/margin/loaded`;
  return axios.post(url, { marginLoadedRate: Number(loadedMargin) });
};

export const updateMaterialClassMargin = (
  materialClass,
  materialClassMargin
) => {
  const url = `${baseUrl}/api/admin/margin/material`;
  return axios.post(url, {
    marginMaterialRates: [
      {
        material_class_cd: materialClass,
        material_margin: Number(materialClassMargin)
      }
    ]
  });
};

// Sales Tax

export const updateSalesTaxRate = (salesTaxRate) => {
  return axios.post(`${baseUrl}/api/admin/salestaxrate/update`, { salesTaxRate });
};

// login actions

export const forgotPassword = (username) => {
  return axios.get(`${baseUrl}/api/auth/forgot-password/${username}`)
}

export const login = (username, password) => {
  return axios.post(`${baseUrl}/api/login`, { username, password });
};

export const changePassword = (newPassword, confirmPassword, userId) => {
  return axios.post(`${baseUrl}/api/auth/local/user/password-change`, {
    newPassword,
    confirmPassword,
    userId
  });
};

export const resetPassword = (resetToken, user_name, newPassword, confirmPassword) => {
  const url = `${baseUrl}/api/auth/reset-password/${resetToken}`;
  return axios.post(url, { user_name, newPassword, confirmPassword });
};

export const uploadLogo = data => {
  const url = `${baseUrl}/api/admin/company/logo`;
  return axios.post(url, data);
};

// manual file uploads actions
export const getDistributorList = () => {
  return axios.get(`${baseUrl}/api/upload/distributor`);
};

export const getPreview = data => {
  const url = `${baseUrl}/api/upload/file`;
  return axios.post(url, data);
};

export const confirmManualUpload = data => {
  const url = `${baseUrl}/api/upload/file/confirm`;
  return axios.post(url, data);
};

export const getTemplateHeader = () => {
  return axios.get(`${baseUrl}/api/upload/templateheader`);
};

// File export actions
export const getDataExportList = () => {
  return axios.get(`${baseUrl}/api/exports/`);
};

export const getDataExportPreview = (export_id, action, role, manufacturers, materials, styles, distributors) => {
  let req = {
    role: JSON.parse(role),
    manufacturers: JSON.parse(manufacturers),
    materials: JSON.parse(materials),
    distributors: JSON.parse(distributors),
    styles: JSON.parse(styles)
  }
  const url = `${baseUrl}/api/exports/${export_id}/${action}`;
  return axios.post(url, req);
};

export const getManufacturers = () => {
  return axios.get(`${baseUrl}/api/ref/manufacturers`);
};

export const getDistributors = () => {
  return axios.get(`${baseUrl}/api/ref/distributors`);
};

export const getMaterials = () => {
  return axios.get(`${baseUrl}/api/ref/materials`);
};

export const getStyles = () => {
  return axios.get(`${baseUrl}/api/ref/styles`);
};

export const getTemplate = () => {
  const url = `${baseUrl}/api/upload/gettemplate`;
  return axios.post(url);
};

// disclaimer actions
export const getTaxDisclaimer = () => {
  return axios.get(`${baseUrl}/api/admin/taxdisclaimer`);
};

export const setTaxDisclaimerPosition = switch_position => {
  const url = `${baseUrl}/api/admin/taxdisclaimerposition`;
  return axios.post(url, { switch_position });
};

export const updateTaxDisclaimer = tax_disclaimer => {
  const url = `${baseUrl}/api/admin/disclaimer/tax`;
  return axios.post(url, { tax_disclaimer });
};

export const getProductDisclaimer = () => {
  return axios.get(`${baseUrl}/api/admin/disclaimer/product`);
};

export const updateProductDisclaimer = product_disclaimer => {
  const url = `${baseUrl}/api/admin/disclaimer/product`;
  return axios.post(url, { product_disclaimer });
};

// Product status (active, discontinued)
export const updateProductStatus = (cost_id, product_uuid, position) => {
  const url = `${baseUrl}/api/admin/product/status/set`;
  return axios.post(url, { cost_id, product_uuid, position });
};

// Logo Link
export const setLogoLink = company_link => {
  const url = `${baseUrl}/api/admin/logolink/set`;
  return axios.post(url, { company_link });
};

export const getLogoLink = () => {
  return axios.get(`${baseUrl}/api/admin/logolink/get`);
};

// Roomvo

export const getRoomvoToggle = () => {
  const url = `${baseUrl}/api/admin/getroomvotoggle`;
  return axios.get(url);
}

export const setRoomvoToggle = (value) => {
  const url = `${baseUrl}/api/admin/setroomvotoggle`;
  return axios.post(url, { value });
}

// Product Info

export const getProductLookBack = days => {
  return axios.get(`${baseUrl}/api/user/lookback/${days}`);
};

export const getProductDescription = puuid => {
  return axios.get(`${baseUrl}/api/admin/product/description/${puuid}`);
};

export const setProductDescription = (product_description, product_image, puuid) => {
  const url = `${baseUrl}/api/admin/product/setdescription`;
  return axios.post(url, { product_description, product_image, puuid });
};

// File history

export const getLatestB2B = () => {
  return axios.get(`${baseUrl}/api/files/b2b/latest`)
};

export const getFilesByPartner = (partner) => {
  return axios.get(`${baseUrl}/api/files/b2b/partners/${partner}`);
};

export const getProductsByFilename = (filename) => {
  return axios.get(`${baseUrl}/api/files/b2b/${filename}/products`)
}

export const deleteCostHistory = (file_name) => {
  const url = `${baseUrl}/api/files/manual/delete`;
  return axios.post(url, {file_name});
};

export const deleteCostRecordsByProduct = (product_uuid) => {
  const url = `${baseUrl}/api/files/manual/deletebyproduct`;
  return axios.post(url, {product_uuid});
};

export const deleteCostRecordsByCostId = (cost_id) => {
  const url = `${baseUrl}/api/files/manual/deletebycostid`;
  return axios.post(url, {cost_id});
};

// Get S3
export const listS3Files = () => {
  return axios.get(`${baseUrl}/api/files/manual/`);
};

export const getS3Files = (file) => {
  const url = `${baseUrl}/api/files/manual/download`;
  return axios.post(url, {file});
};

// Stats

export const getViewsByDay = (sDate, eDate, role, accessCode, materialClass, locations, spoke) => {
  let req = {
    role: role,
    access_code: accessCode,
    material_class: materialClass,
    locations: locations
  }
  const url = `${baseUrl}/api/stats/products/Viewsbyday/${sDate}/${eDate}?siteName=${spoke}`;
  return axios.post(url, req);
};

export const getFavoritesHistory = (sDate, eDate, role, accessCode, locations, materialClass, names, likes, unLikes, spoke) => {
  const url = `${baseUrl}/api/stats/history/favorites/${sDate}/${eDate}?siteName=${spoke}`;
  return axios.post(url, {role, accessCode, locations, materialClass, names, likes, unLikes});
}

export const getCheckoutsHistory = (sDate, eDate, role, accessCode, materialClass, locations, names, checkedOut, spoke) => {
  const url = `${baseUrl}/api/stats/history/checkouts/${sDate}/${eDate}?siteName=${spoke}`;
  return axios.post(url, {role, accessCode, materialClass, locations, names, checkedOut});
}

// Versions
export const getVersion = () => {
  return axios.get(`${baseUrl}/api/version`);
};

// Mailer
export const setMailer = (to, fName, lName, email, phone, subject, messageBody, link, mailData) => {
  const url = `${baseUrl}/api/mail/mailer`;
  return axios.post(url, { to, fName, lName, email, phone, subject, messageBody, link, mailData });
};

// Video 
export const getVideoLinks = () => {
  return axios.get(`${baseUrl}/api/user/video/links`);
};

// Product page settings

export const setManufacturerSwitchPosition = switch_position => {
  const url = `${baseUrl}/api/admin/manufacturerposition`;
  return axios.post(url, { switch_position });
};

export const getManufacturerSwitchPosition = () => {
  return axios.get(`${baseUrl}/api/admin/manufacturerposition`);
};

export const setRoomCalcSwitchPosition = calc_switch_position => {
  const url = `${baseUrl}/api/admin/roomcalcposition`;
  return axios.post(url, { calc_switch_position });
};

export const getRoomCalcSwitchPosition = () => {
  return axios.get(`${baseUrl}/api/admin/roomcalcposition`);
};

export const setLocationSwitchPosition = location_switch_position => {
  const url = `${baseUrl}/api/admin/locationposition`;
  return axios.post(url, { location_switch_position });
};

export const getLocationSwitchPosition = () => {
  return axios.get(`${baseUrl}/api/admin/locationposition`);
};

export const setProdFavSwitchPosition = prod_fav_switch_position => {
  const url = `${baseUrl}/api/admin/prod_fav_position`;
  return axios.put(url, { prod_fav_switch_position });
};

export const getProdFavSwitchPosition = () => {
  return axios.get(`${baseUrl}/api/admin/prod_fav_position`);
};

export const setProdCheckoutSwitchPosition = prod_checkout_switch_position => {
  const url = `${baseUrl}/api/admin/prod_checkout_position`;
  return axios.put(url, { prod_checkout_switch_position });
};

export const getProdCheckoutSwitchPosition = () => {
  return axios.get(`${baseUrl}/api/admin/prod_checkout_position`);
};

export const getNotificationsSwitchPos = () => {
  return axios.get(`${baseUrl}/api/settings/notifications/site`);
};

export const setNotificationsSwitchPos = sample_reminder => {
  const url = `${baseUrl}/api/settings/notifications/site`;
  return axios.post(url, { sample_reminder });
};

// Locations

export const getLocations = () => {
  return axios.get(`${baseUrl}/api/admin/locations`);
}

export const createLocation = (location_name, location_description, sample_reminder, customer_facing_name, location_phone, location_email) => {
  const url = `${baseUrl}/api/admin/locations`;
  return axios.post(url, {location_name, location_description, sample_reminder, customer_facing_name, location_phone, location_email});
}

export const deleteLocation = location_id => {
  const url = `${baseUrl}/api/admin/locations/${location_id}`;
  return axios.delete(url);
};

export const updateLocation = (location_id, location_name, location_description, sample_reminder, customer_facing_name, location_phone, location_email) => {
  const url = `${baseUrl}/api/admin/locations/${location_id}`;
  return axios.post(url, {location_name, location_description, sample_reminder, customer_facing_name, location_phone, location_email});
}

// Settings

export const getRetailRoundUp = () => {
  return axios.get(`${baseUrl}/api/admin/getroundup`);
};

export const setRetailRoundUp = (retail_round_up) => {
  const url = `${baseUrl}/api/admin/setroundup`;
  return axios.post(url, {retail_round_up});
}

export const getUsePromoPrice = () => {
  return axios.get(`${baseUrl}/api/admin/getusepromoprice`);
};

export const getQrPrintConfig = () => {
  return axios.get(`${baseUrl}/api/settings/print_config/get`);
};

export const setQrPrintConfig = (margins_x, margins_y) => {
  const url = `${baseUrl}/api/settings/print_config/set`;
  return axios.put(url, {margins_x, margins_y});
};

export const setUsePromoPrice = (promo_price_status) => {
  const url = `${baseUrl}/api/admin/setusepromoprice`;
  return axios.post(url, {promo_price_status});
}

// Checkout

export const setSampleAutoCheckoutStatus = (role_id, switch_state) => {
  const url = `${baseUrl}/api/admin/autocheckoutstatus`;
  return axios.post(url, { role_id, switch_state });
};

export const getUserCheckoutStatus = (product_uuid) => {
  return axios.get(`${baseUrl}/api/user/product/${product_uuid}/checkout/status/`);
};

export const requestSampleCheckout = (product_uuid, locationId) => {
  return axios.get(`${baseUrl}/api/product/${product_uuid}/checkout/request/?loc=${locationId}`);
};

export const getCheckoutList = () => {
  return axios.get(`${baseUrl}/api/checkout`);
};

export const returnSample = (sample_checkout_id) => {
  return axios.get(`${baseUrl}/api/product/:product_uuid/checkout/return/?sample_checkout_id=${sample_checkout_id}`);
};

export const getUserCheckoutList = () => {
  return axios.get(`${baseUrl}/api/user/checkout/`);
};

export const getUserCheckoutListByCustomerId = (customerId) => {
  return axios.get(`${baseUrl}/api/user/${customerId}/checkout/`);
};

export const approveCheckout = (product_uuid, sample_checkout_id) => {
  return axios.get(`${baseUrl}/api/user/product/${product_uuid}/checkout/${sample_checkout_id}/approve/`);
};

export const checkin = (product_uuid, sample_checkout_id) => {
  return axios.get(`${baseUrl}/api/user/product/${product_uuid}/checkout/${sample_checkout_id}/checkin/`);
};

export const getCustomerCheckoutStatus = (product_uuid) => {
  return axios.get(`${baseUrl}/api/product/${product_uuid}/checkout/status/`);
};

export const cancelSampleCheckout = (checkoutId) => {
  return axios.get(`${baseUrl}/api/checkout/${checkoutId}/cancel/`);
};

export const setCustomerCreateAccountsSwitchPos = (switch_state) => {
  const url = `${baseUrl}/api/admin/customeraccountcreatestatus`;
  return axios.post(url, { switch_state });
};

export const getCustomerCreateAccountStatus = () => {
  return axios.get(`${baseUrl}/api/admin/customer/account/create/status`);
};

export const getCustomerUserInfo = () => {
  return axios.get(`${baseUrl}/api/user/customer/data`);
};

// Customer favorites
export const likeProduct = (product_uuid, product_sku, location_id) => {
  return axios.get(`${baseUrl}/api/product/${product_uuid}/sku/${product_sku}/like/${location_id}`);
};

export const unlikeProduct = (product_uuid, product_sku) => {
  return axios.get(`${baseUrl}/api/product/${product_uuid}/sku/${product_sku}/unlike`);
};

export const likeProductStatus = (product_uuid) => {
  return axios.get(`${baseUrl}/api/product/${product_uuid}/like/status`);
};

export const getLikes = () => {
  return axios.get(`${baseUrl}/api/likes`);
};

export const getAllLikes = () => {
  return axios.get(`${baseUrl}/api/likes/all`);
};

export const getAllLikesByCustomerId = (customerId) => {
  return axios.get(`${baseUrl}/api/likes/user/${customerId}`);
};

export const getCheckoutStatusNames = () => {
  return axios.get(`${baseUrl}/api/admin/checkout/status_names`);
}

// Material Class/UOM 

export const getMaterialClassCodes = () => {
  return axios.get(`${baseUrl}/api/settings/material_class_codes/get`);
}

export const setMaterialClassCodes = (material_class_cd, material_class) => {
  const url = `${baseUrl}/api/settings/material_class_codes/set`;
  return axios.post(url, {material_class_cd, material_class});
}

export const deleteCodeTranslation = (material_class_cd_id, action, field_name) => {
  const url = `${baseUrl}/api/settings/material_class/delete`;
  return axios.put(url, {material_class_cd_id, action, field_name});
}

export const getCodeTranslations = () => {
  return axios.get(`${baseUrl}/api/settings/code_translations/get`);
}

export const setNewCodeTranslation = (field_name, code_translation, target_code) => {
  const url = `${baseUrl}/api/settings/code_translation/set`;
  return axios.post(url, {field_name, code_translation, target_code});
}

export const getUom = () => {
  return axios.get(`${baseUrl}/api/settings/uom/get`);
}

export const setNewUom = (uom_type, uom_type_cd) => {
  const url = `${baseUrl}/api/settings/uom/set`;
  return axios.post(url, {uom_type, uom_type_cd});
}

export const deleteMasterMaterialClass = (id) => {
  const url = `${baseUrl}/api/settings/material_class_master/delete`;
  return axios.post(url, {id});
}

export const deleteMasterUom = (id) => {
  const url = `${baseUrl}/api/settings/uom_master/delete`;
  return axios.post(url, {id});
}

// Hub and Spoke
export const getSpokes = () => {
  return axios.get(`${baseUrl}/api/hub/spokes`);
}

export const uploadToSpokes = (siteList, data) => {
  data.siteList = siteList;
  const url = `${baseUrl}/api/hub/upload/file/spoke`;
  return axios.post(url, data);
};

// Product merging

export const getMergeCandidates = (productUuidList) =>  {
  const url = `${baseUrl}/api/merge/candidates`;
  return axios.post(url, {productUuidList});
};

export const mergeCandidates = (targetProductUuid, sourceProductUuidList) => {
  const url = `${baseUrl}/api/merge/candidates/merge`;
  return axios.post(url, {targetProductUuid, sourceProductUuidList});
};

export const unmergeCandidates = (mergedProductUuidList) => {
  const url = `${baseUrl}/api/merge/candidates/unmerge`;
  return axios.put(url, {mergedProductUuidList});
};

export const guestCheckout = (data) => {
  
  let body = {
    userId: data.userId,
    guestName: data.checkoutName,
    guestEmail: data.checkoutEmail, 
    guestPhone: data.checkoutPhone, 
    productUUID: data.productId
  }
  const url = `${baseUrl}/api/user/checkout/guest/`;
  return axios.post(url, body);
};
