import React, { useState, createContext, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../Contants";
import { AppContext } from "./AppContextProvide";

export const UserAnnouncementsContext = createContext();

export const UserAnnouncementsContextProvider = ({ children }) => {
  const { user } = useContext(AppContext);
  const [announcements, setAnnouncements] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(false);

  // /admin/accesstypes
  const isLoggedIn = user.user_id;

  useEffect(() => {
    async function getUserAnnouncements() {
      const result = await axios(`${baseUrl}/api/user/announcements`);
      setAnnouncements(result.data.data);
    }
    if (isLoggedIn) {
      getUserAnnouncements();
      setUpdate(false);
    }
  }, [isLoggedIn]);

  return (
    <UserAnnouncementsContext.Provider value={{ announcements, setUpdate }}>
      {children}
    </UserAnnouncementsContext.Provider>
  );
};
