import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

// table config
import { productsTableConfigMain } from "../config";

// styles
// import "../styles/Products.css";
import { productsStyles } from "../styles/ProductsStyles";
import { generalStyles } from "../styles/GeneralStyles";

// MUI
import { Accordion, 
  AccordionDetails, 
  AccordionSummary, 
  Checkbox, 
  FormControlLabel,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Button,
  TextField,
  NativeSelect,
  CircularProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  MenuItem,
 } from "@material-ui/core";
import ExpandMoreIcon from"@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";

// dependents
import { ProductsWithSearchContext } from "../../Context/ProductsWithSearchContextProvider";
import { AppContext } from "../../Context/AppContextProvide";
import PrintProducts from "./PrintQueue/PrintProducts";
import TableToolBar from "./ProductTable/TableToolBar";
import EnhancedTableHead from "./ProductTable/EnhancedTableHead";
import Row from "./ProductTable/Row";
import RowMobile from "./ProductTable/RowMobile";
import Merge from "./Merge";

//Actions
import { getRoles, updateUserRole, getMergeCandidates, mergeCandidates, unmergeCandidates } from "../../Actions";
import { sortObjectsByAttribute } from "../../Util/utilsFunctions";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

const Products = () => {
  const {
    productsList,
    setManufacturerFilter,
    setDistributorFilter,
    setTradeNameFilter,
    setStyleFilter,
    setSKUFilter,
    setColorFilter,
    setColorNumberFilter,
    setMaterialFilter,
    setMaterialSubFilter,
    manufacturerFilter,
    distributorFilter,
    tradeNameFilter,
    styleFilter,
    SKUFilter,
    colorFilter,
    colorNumberFilter,
    materialFilter,
    materialSubFilter,
    setGetProductsList,
    setRId, 
    handleProgressOpen,
    handleProgressClose,
    // productUuid,
    setUpdateProduct,
    setCollectionFilter,
    collectionFilter,
    setDisplayNameFilter,
    displayNameFilter,
    setPrivateStyleNameFilter,
    setPrivateStyleNumberFilter,
    privateStyleNameFilter,
    privateStyleNumberFilter,
    setRpoFilter,
    setDiscontinuedFilter,
    setMinPriceFilter,
    setMaxPriceFilter,
    minPriceFilter,
    maxPriceFilter
  } = useContext(ProductsWithSearchContext);

  const { user, setRoleId, roleId, setRoleName, setRolePrefix } = useContext(AppContext);

  const [open] = useState(false);
  const [products, setProducts] = useState([]);

  // table variables
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [roles, setRoles] = useState([]);
  const [progress, setProgress] = useState();
  const [rpoChecked, setRpoChecked] = useState(false);
  const [discontinuedChecked, setDiscontinuedChecked] = useState(false);
  const [openList, setOpenList] = useState();
  const [value, setValue] = useState(0);
  const [mergeListUuids, setMergeListUuids] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mergeList, setMergeList] = useState([]);
  const productsClasses = productsStyles();
  const generalClasses = generalStyles();
  const [sourceUuidList, setSourceUuidList] = useState([]);
  const [targetUuid, setTargetUuid] = useState();
  const [unmergeFlag, setUnmergeFlag] = useState(false);

  useEffect(() => {

    const roleList = new Promise((resolve) =>{
      resolve(getRoles());
    });

    roleList.then((result) => {
      setRoles(result.data.roleList); 
    });

    setProducts(productsList);
  }, [productsList]);

  const handleListClick = (index) => {
    openList === index ? setOpenList(undefined) : setOpenList(index);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.length
        ? products.map((n) => n.product_uuid)
        : null;
      setMergeListUuids(newSelecteds);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
    setMergeListUuids([]);
  };

  const handleClick = (event, id) => {
    if(event.target.checked === true) {
      setMergeListUuids(mergeList => [...mergeList, id] )
      setRefresh(refresh + 1);
      } else {
        var index = mergeListUuids.indexOf(id);
          if (index !== -1) {
            mergeListUuids.splice(index, 1);
          };
        setRefresh(refresh - 1);
    };

    const selectedIndex = selected.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleManufacturerFilterChange = (e) =>
    setManufacturerFilter(e.target.value);
  const handleDistributorFilterChange = (e) =>
    setDistributorFilter(e.target.value);
  const handleTradeNameFilterChange = (e) => setTradeNameFilter(e.target.value);
  const handlStyleFilterChange = (e) => setStyleFilter(e.target.value);
  const handleSKUFilterChange = (e) => setSKUFilter(e.target.value);
  const handleColorFilter = (e) => setColorFilter(e.target.value);
  const handleColorNumberFilter = (e) => setColorNumberFilter(e.target.value);
  const handleMaterialFilter = (e) => setMaterialFilter(e.target.value);
  const handleMaterialSubFilter = (e) => setMaterialSubFilter(e.target.value);
  const handleCollectionFilter = (e) => setCollectionFilter(e.target.value);
  const handleDisplayNameFilter = (e) => setDisplayNameFilter(e.target.value);
  const handlePrivateStyleNameFilter = (e) => setPrivateStyleNameFilter(e.target.value);
  const handlePrivateStyleNumberFilter = (e) => setPrivateStyleNumberFilter(e.target.value);
  const handleMinPriceSubFilter = (e) => setMinPriceFilter(e.target.value);
  const handleMaxPriceSubFilter = (e) => setMaxPriceFilter(e.target.value);

  const getProductsDataWithFilters = (e) => {
    e.preventDefault();
    setSelected([]);
    setGetProductsList(true);
    setUpdateProduct(1);
    setTargetUuid("");
    setSourceUuidList([]);
    setMergeList([]);
    setMergeListUuids([]);
    setUnmergeFlag(false);
  };

  const handleMerge = () => {
    if (unmergeFlag === true) {
    unmergeCandidates(sourceUuidList)
    .then(() => {
      setDialogOpen(false);
      setGetProductsList(true);
      // setProgress(true);
      handleProgressOpen();
      setUnmergeFlag(false);
      setUpdateProduct(1);
      setSelected([]);
      setSourceUuidList([]);
      setMergeListUuids([]);
      setTargetUuid("");
    })
    .catch((err) => {
      console.log(err);
    });
  } else {
    mergeCandidates(targetUuid, sourceUuidList)
    .then((result) => {
      setDialogOpen(false);
      setGetProductsList(true);
      // setProgress(true);
      handleProgressOpen();
      setUnmergeFlag(false);
      setUpdateProduct(1);
      setSelected([]);
      setSourceUuidList([]);
      setMergeListUuids([]);
      setTargetUuid("");
    })
    .catch((err) => {
      console.log(err)
    })
  }
  };

  const handleRoleSelect = (e) => {
    const roleData = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute('data'));
    setRolePrefix(roleData.prefix);
    setRoleName(roleData.role_name);
    setRoleId(e.target.value);
    updateUserRole(e.target.value);
    setRId(e.target.value);
    setUpdateProduct(1);
  };
  const handleProgressBarState = () =>  {
    setPage(0);
    handleProgressOpen();
  };

  const handleClearForm = () => {
    setMaterialFilter("");
    setCollectionFilter("");
    setColorFilter("");
    setDisplayNameFilter("");
    setDistributorFilter("");
    setManufacturerFilter("");
    setMaterialSubFilter("");
    setPrivateStyleNameFilter("");
    setPrivateStyleNumberFilter("");
    setSKUFilter("");
    setStyleFilter("");
    setTradeNameFilter("");
    setRpoChecked(false);
    setRpoFilter(false);
    setDiscontinuedChecked(false);
    setDiscontinuedFilter(false);
    setMinPriceFilter("");
    setMaxPriceFilter("");
    setTargetUuid("");
    setSourceUuidList([]);
    setMergeList([]);
    setMergeListUuids([]);
    setUnmergeFlag(false);
  };

  const handleRpoCheckBox = (e) => {
    setRpoFilter(e.target.checked);
    setRpoChecked(e.target.checked);
  };

  const handleDiscontinuedCheckBox = (e) => {
    setDiscontinuedFilter(e.target.checked);
    setDiscontinuedChecked(e.target.checked);
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setTargetUuid("");
    setSourceUuidList([]);
    setMergeList([]);
    setUnmergeFlag(false);
  };

  const showMergeList = () => {
    getMergeCandidates(mergeListUuids)
    .then((result) => {
      setMergeList(result.data.data)
      setDialogOpen(true);
    })
  };

  const handleSourceMerge = (id) => {

    const selectedIndex = sourceUuidList.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(sourceUuidList, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(sourceUuidList.slice(1));
    } else if (selectedIndex === sourceUuidList.length - 1) {
      newSelected = newSelected.concat(sourceUuidList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        sourceUuidList.slice(0, selectedIndex),
        sourceUuidList.slice(selectedIndex + 1)
      );
    }
    setSourceUuidList(newSelected);
  };

  const handleTargetMerge = (id) => {
    setTargetUuid(id);
    var index = sourceUuidList.indexOf(id);
    if (index !== -1) {
      sourceUuidList.splice(index, 1);
    };
  };

  const unmerge = (flag) => {
    setUnmergeFlag(flag);
  };
  
  const renderDialog = () => {
    return (
    <Dialog open={dialogOpen} onClose={handleClose} fullScreen={true}>
        <DialogTitle
          className="Admin__create-user-header"
          id="alert-dialog-title"
        >
          Merge Product
          <Button variant="outlined"
          className={generalClasses.xButton}
          onClick={handleClose}>
          X
          </Button>
        </DialogTitle>
        <DialogContent dividers style={{boxShadow:"0px 5px 5px #eeeeee"}}>
          <Merge productList={mergeList} handleSourceMerge={handleSourceMerge} handleTargetMerge={handleTargetMerge} unmergeFlag={unmerge}/>
        </DialogContent>
        <DialogActions style={{margin:"auto"}}>
          <Button 
            onClick={handleClose} 
            autoFocus
            variant="contained"
            className={productsClasses.productFilterButton}
            >
            Cancel
          </Button>
          <Button
            onClick={handleMerge}
            className={productsClasses.productFilterButton}
            variant="contained"
            disabled={sourceUuidList.length > 0 && targetUuid !== "" ? false : true}
          >
            {unmergeFlag === true ? "Unmerge" : "Merge"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderTable = (data) => {

    if (!data?.length) { 
      return ( 
        <Paper>
          <div style={{backgroundColor: "#142E3E", height: 10}}>
          </div>
          <div className={productsClasses.productsFooter}>
            <Typography
              align="center"
              variant="h5"
            >
              Set Filter
            </Typography>
            <Typography
              align="center"
              variant="body2"
            >
              Please search for at least one of the following filters to get the
              products data.
            </Typography>
            <Typography
              variant="h6"
              align="center"
            >
              <p>manufacturer, distributor, style name, style number, SKU, color</p>
            </Typography>
          </div>
        </Paper>
      )
    }

    const paginatedList = products.sort((prev, curr) => sortObjectsByAttribute(prev, curr, order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const buttonStyles = {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      margin: "0px 0px 0px 10px"
    };
    const isFirstPage = page === 0;
    const isLastPage = ((page + 1) * rowsPerPage) >= products.length;
    const currentStart = Math.max(page * rowsPerPage, 1);
    const currentEnd = Math.min((page * rowsPerPage) + rowsPerPage, products.length);
    const rowsPerPageOptions = [10, 25, 100, 500];

    return (
      <div>
      <div className={productsClasses.productsContainerFull}>
      <Paper>
         <Typography
          variant="h4"
          className={generalClasses.header}>
          Product List
        </Typography>
          <TableToolBar productIdList={selected} numSelected={selected.length} showMergeList={showMergeList} refresh={refresh} />
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={productsTableConfigMain}
            />
            <TableBody>
              {paginatedList.map((product, i) => {
                  const isItemSelected = isSelected(product.product_uuid);
                  const labelId = `enhanced-table-checkbox-${product.product_uuid}`;
                  return (
                    <Row
                      key={"desktop_" + product.product_uuid + "_" + product.style + "_" + product.distributor_uuid}
                      product={product}
                      rowNumber={i + 1}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      </div>
      <div className={productsClasses.productsContainerMobile}>
        <TableToolBar productIdList={selected} numSelected={selected.length} />
        <div style={{margin: "25px 24px 0px", maxWidth: "400px"}}>
          <FormControl>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page"
              value={rowsPerPage}
              style={{width: "125px", marginRight: "10px", marginBottom: "20px"}}
              variant="filled"
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {rowsPerPageOptions.map(o => {
                return (
                  <MenuItem key={o} value={o}>{o}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <div style={{display: "inline-block"}}>
            <Typography style={{display: "inline-block", verticalAlign: "middle", margin: "0px", textAlign: "center"}}>{currentStart} - {currentEnd} of {products.length}</Typography>
            <IconButton variant="text" disabled={isFirstPage} style={buttonStyles} onClick={() => setPage(isFirstPage ? 0 : page - 1)}><ChevronLeft /></IconButton>
            <IconButton variant="text" disabled={isLastPage} style={buttonStyles} onClick={() => setPage(isLastPage ? page : page + 1)}><ChevronRight /></IconButton>
          </div>
        </div>
        {paginatedList.map((product, i) => {
          const isItemSelected = isSelected(product.product_uuid);
          const labelId = `enhanced-table-checkbox-${product.product_uuid}`;
          return (
            <RowMobile
              key={"mobile_" + product.product_uuid + "_" + product.style + "_" + product.distributor_uuid}
              product={product}
              rowNumber={i + 1}
              isItemSelected={isItemSelected}
              labelId={labelId}
              handleClick={handleClick}
            />
          );
        })}
        <div style={{margin: "25px 24px 0px", maxWidth: "400px"}}>
          <FormControl>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page"
              value={rowsPerPage}
              style={{width: "125px", marginRight: "10px", marginBottom: "20px"}}
              variant="filled"
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {rowsPerPageOptions.map(o => {
                return (
                  <MenuItem key={o} value={o}>{o}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <div style={{display: "inline-block"}}>
            <Typography style={{display: "inline-block", verticalAlign: "middle", margin: "0px", textAlign: "center"}}>{currentStart} - {currentEnd} of {products.length}</Typography>
            <IconButton variant="text" disabled={isFirstPage} style={buttonStyles} onClick={() => setPage(isFirstPage ? 0 : page - 1)}><ChevronLeft /></IconButton>
            <IconButton variant="text" disabled={isLastPage} style={buttonStyles} onClick={() => setPage(isLastPage ? page : page + 1)}><ChevronRight /></IconButton>
          </div>
        </div>
      </div>
      </div>
    )
  };

  const renderAdvancedSearch = () => {
    return (
      <Accordion className={productsClasses.productsSearchAccordian}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography variant="h5">Advanced Search <SearchIcon style={{color: "#ff682c"}}/></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid>
            <TextField
                    className={productsClasses.tableFilter}
                    id="search-collection"
                    label="Collection"
                    type="search"
                    variant="filled"
                    value={collectionFilter}
                    onChange={handleCollectionFilter}
                  />
                  <TextField
                    className={productsClasses.tableFilter}
                    id="search-display-name"
                    label="Display Name"
                    type="search"
                    variant="filled"
                    value={displayNameFilter}
                    onChange={handleDisplayNameFilter}
                  />
                  <TextField
                    className={productsClasses.tableFilter}
                    id="search-private-style-name"
                    label="Private Style Name"
                    type="search"
                    variant="filled"
                    value={privateStyleNameFilter}
                    onChange={handlePrivateStyleNameFilter}
                  />
                  <TextField
                    className={productsClasses.tableFilter}
                    id="search-private-style-number"
                    label="Private Style Number"
                    type="search"
                    variant="filled"
                    value={privateStyleNumberFilter}
                    onChange={handlePrivateStyleNumberFilter}
                  />
                  <TextField
                    className={productsClasses.tableFilter}
                    id="search-color-number"
                    label="Color Number"
                    type="search"
                    variant="filled"
                    value={colorNumberFilter}
                    onChange={handleColorNumberFilter}
                  />
                  <TextField
                    className={productsClasses.tableFilter}
                    id="search-material_sub"
                    label="Material Sub Class"
                    type="search"
                    variant="filled"
                    value={materialSubFilter}
                    onChange={handleMaterialSubFilter}
                  />
                                    <TextField
                    className={productsClasses.tableFilter}
                    label="Min Price"
                    type="search"
                    variant="filled"
                    value={minPriceFilter}
                    onChange={handleMinPriceSubFilter}
                  /> 
                  <TextField
                    className={productsClasses.tableFilter}
                    label="Max Price"
                    type="search"
                    variant="filled"
                    value={maxPriceFilter}
                    onChange={handleMaxPriceSubFilter}
                  />
                    <div>
                    <FormControlLabel  control={<Checkbox className={productsClasses.productsCheckbox} checked={rpoChecked} onChange={handleRpoCheckBox} />} label="Retail Price Override" />
                    </div>
                    <div>
                    <FormControlLabel  control={<Checkbox className={productsClasses.productsCheckbox} checked={discontinuedChecked} onChange={handleDiscontinuedCheckBox} />} label="Discontinued" />
                    </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    )};

  return (
    <div className={productsClasses.productsListContainer}>
      <Typography
            className={generalClasses.header}
            variant="h4">
            Product Search
      </Typography>
        <Paper className={productsClasses.productsSearchPaper}>
          <Grid 
          className={productsClasses.productsRoleSelectGrid}
          container spacing={3}
          >
            <Grid  item>
              <PrintProducts open={open} />
            </Grid>
            <Grid 
            container spacing={3} 
            alignItems="center"
            justify="center"
            >
              {/* <Grid item xs={12}>
              <Typography
              variant="h4"
              className="products__product-list-title"
              >
                Product List
              </Typography>
              </Grid> */}
              <Grid item>
                <Typography className={generalClasses.headerText}>Select role to search products: </Typography>
              </Grid>
              <Grid item>
                <NativeSelect
                  value={!roleId ? user.role_id : roleId}
                  onChange={handleRoleSelect}
                >
                  {roles.map((role) => {
                    return (
                      <option key={role.role_id} value={role.role_id} data={JSON.stringify(role)}>{role.role_name}</option>
                    );
                  })}
                </NativeSelect>
              </Grid>
            </Grid>
          </Grid>
          <form onSubmit={getProductsDataWithFilters}>
            <TextField
              className={productsClasses.tableFilter}
              id="search-manufacturer"
              label="Manufacturer"
              type="search"
              variant="filled"
              value={manufacturerFilter}
              onChange={handleManufacturerFilterChange}
            />
            <TextField
              className={productsClasses.tableFilter}
              id="search-distributor"
              label="Distributor"
              type="search"
              variant="filled"
              value={distributorFilter}
              onChange={handleDistributorFilterChange}
            />
            <TextField
              className={productsClasses.tableFilter}
              id="search-trade-name"
              label="Style Name"
              type="search"
              variant="filled"
              value={tradeNameFilter}
              onChange={handleTradeNameFilterChange}
            />
            <TextField
              className={productsClasses.tableFilter}
              id="search-style"
              label="Style Number"
              type="search"
              variant="filled"
              value={styleFilter}
              onChange={handlStyleFilterChange}
            />
            <TextField
              className={productsClasses.tableFilter}
              id="search-sku"
              label="SKU"
              type="search"
              variant="filled"
              value={SKUFilter}
              onChange={handleSKUFilterChange}
            />
            <TextField
              className={productsClasses.tableFilter}
              id="search-material"
              label="Material"
              type="search"
              variant="filled"
              value={materialFilter}
              onChange={handleMaterialFilter}
            />
            <TextField
              className={productsClasses.tableFilter}
              id="search-color"
              label="Color"
              type="search"
              variant="filled"
              value={colorFilter}
              onChange={handleColorFilter}
            />
            <div>
              <Button onClick={handleProgressBarState} type="submit" variant="contained" className={productsClasses.productFilterButton}>
                Search
              </Button>
              <Button onClick={handleClearForm} variant="contained" className={productsClasses.productFilterButton}>
                Clear
              </Button>
            </div>
            {renderAdvancedSearch()}
          </form>
        </Paper>
      {renderTable(products)}
      {renderDialog()}
    </div>
  );
};

export default withRouter(Products);
