import React,  { useContext } from "react";

// Context
import { AppContext } from "../../../Context/AppContextProvide";

// styles
import "../../styles/Stats.css";

import { Card, CardContent, Divider, Typography, CardActionArea , Tooltip, Grid} from "@material-ui/core";

// icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import EventIcon from "@material-ui/icons/Event";
import SearchIcon from "@material-ui/icons/Search";
import StoreIcon from "@material-ui/icons/Store";
import FavoriteSharpIcon from '@material-ui/icons/FavoriteSharp';
import StyleIcon from '@material-ui/icons/Style';



const StatCards = (props) => {
  const {
    mostRecentViewedProduct,
    mostViewedProduct,
    mostViewedManufacturer,
    mostLikedProduct,
    mostCheckedoutProduct
  } = props;

  const { user } = useContext(AppContext);

  const renderRecentProduct = (data) => {
    return data ? (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
        <CardContent>
        <Typography className="stats__card-title" color="textSecondary" variant="h5" component="h2" gutterBottom>
        <VisibilityIcon className="stats__icon" />
        Most Recent Viewed
                </Typography>
        </CardContent>
        <Divider />
        <CardActionArea href={"/product/" + data.product_uuid}>
        <Tooltip title={data.material_sub_class ? data.material_sub_class : data.material_class}>
          <CardContent>
            <div className="stats__card-content">
              <div className="stats__card-data">
                
                <Typography
                  color="textSecondary"
                  className="stats__card-company"
                  gutterBottom
                >
                  {data.manufacturer_name}
                </Typography>
                <Typography
                  color="textPrimary"
                  className="stats__card-style-name"
                  gutterBottom
                >
                  {data.trade_name}
                </Typography>

                <Typography
                  color="textSecondary"
                  className="stats__card-style-more"
                  gutterBottom
                >
                  {data.style} - {data.material_class}
                </Typography>
              </div>
            </div>
            </CardContent>
            </Tooltip>
          </CardActionArea>
            <Divider />
            <CardContent>
            <div className="stats_card-date">
              <EventIcon className="status__card-calendar-icon status__card-secondary-icon" />
              <Typography
                className="status__card-last-seen"
                color="textSecondary"
              >
                {data.click_date}
              </Typography>
            </div>
            
          </CardContent>
          
          {/* <CardActions>
        <Button size="small" color="primary" href={"/product/" + data.product_uuid}>
          View
        </Button>
      </CardActions> */}
        </Card>
      </Grid>
    ) : (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
          <CardContent>
            <div className="stats__card-content">
              No Data Yet...
              <div className="stats__card-icon"></div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderMostVisitedProduct = (data) => {
    return data ? (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
          <CardContent>
            <div className="stats__card-icon">
             
              <Typography className="stats__icon_searched-title" color="textSecondary" variant="h5" component="h2" gutterBottom>
                <SearchIcon className="stats__icon_searched" />
                  Most Viewed Product
              </Typography>
            </div>
          </CardContent>
            
          <Divider />
          <CardActionArea href={"/product/" + data.product_uuid}>
            <Tooltip title={data.material_sub_class !== null ? data.material_sub_class : ""}>
              <CardContent>
              <div className="stats__card-content">
                <div className="stats__card-data">
                  <Typography
                    color="textSecondary"
                    className="stats__card-company"
                    gutterBottom
                  >
                    {data.manufacturer_name}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    className="stats__card-style-name"
                    gutterBottom
                  >
                    {data.trade_name}
                  </Typography>

                  <Typography
                    color="textSecondary"
                    className="stats__card-style-more"
                    gutterBottom
                  >
                    {data.style}
                  </Typography>
                </div>
              </div>
              </CardContent>
              </Tooltip>
              </CardActionArea>
            <Divider />
            <CardContent>
            <div className="stats_card-date">
              <SearchIcon className="status__card-calendar-icon status__card-secondary-icon" />
              <Typography
                className="status__card-last-seen"
                color="textSecondary"
              >
                {data.times_viewed}  {data.times_viewed < 2 ? "Time Viewed" : "Times Viewed"}
              </Typography>
              </div>
              </CardContent>
        </Card>
      </Grid>
    ) : (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
          <CardContent>
            <div className="stats__card-content">
              {user.access_type_cd === "ro" ? "No Visited Products in 30 days" : "No Data Yet..."}  
            <div className="stats__card-icon"></div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderMostViewedManufacturer = (data) => {
    return data ? (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
        <CardContent>
            <div className="stats__card-icon">
             
              <Typography className="stats__icon_searched-title" color="textSecondary" variant="h5" component="h2" gutterBottom>
              <StoreIcon className="stats__icon-searched-company" />
                  Most Viewed Manufacturer
                
              </Typography>
            </div>
          </CardContent>
            
          <Divider />
          <CardActionArea>
            <Tooltip title={"Number of Views: " + data.times_viewed}>
              <CardContent>
                <div className="stats__card-content">
                  
                  <div className="stats__card-data">
                    <Typography
                      color="textSecondary"
                      className="stats__card-company stats__card-company-viewed"
                      gutterBottom
                    >
                      {data.manufacturer_name}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className="stats__card-style-name"
                      gutterBottom
                    >
                      {data.manufacturer_name}
                    </Typography>

                    <Typography
                      color="textSecondary"
                      className="stats__card-style-more stats__card-company-viewed"
                      gutterBottom
                    >
                      {data.manufacturer_name}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Tooltip>
          </CardActionArea>
          <Divider />
          <CardContent>
            <div className="stats_card-date">
              <VisibilityIcon className="status__card-calendar-icon status__card-secondary-icon" />
              <Typography
                className="status__card-last-seen"
                color="textSecondary"
              >
                {data.times_viewed}  {data.times_viewed < 2 ? "Time Viewed" : "Times Viewed"}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    ) : (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
          <CardContent>
            <div className="stats__card-content">
              No Data Yet...
              <div className="stats__card-icon"></div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderMostLikedProduct = (data) => {
    return data ? (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
        <CardContent>
            <div className="stats__card-icon">
              <Typography className="stats__icon_searched-title" color="textSecondary" variant="h5" component="h2" gutterBottom>
                <FavoriteSharpIcon className="stats__icon-most-favorite"/>
                  Most Liked Product
                
              </Typography>
            </div>
          </CardContent>
            
          <Divider />
          <CardActionArea href={"/product/" + data.product_uuid}>
            <Tooltip title={"Number of Likes: " + data.count}>
              <CardContent>
                <div className="stats__card-content">
                  
                  <div className="stats__card-data">
                    <Typography
                      color="textSecondary"
                      className="stats__card-company"
                      gutterBottom
                    >
                      {data.manufacturer_name}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className="stats__card-style-name"
                      gutterBottom
                    >
                      {data.trade_name} - {data.sku}
                    </Typography>

                    <Typography
                      color="textSecondary"
                      // className="stats__card-style-more stats__card-company-viewed"
                      gutterBottom
                    >
                      {data.style}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Tooltip>
          </CardActionArea>
          <Divider />
          <CardContent>
            <div className="stats_card-date">
              <VisibilityIcon className="status__card-calendar-icon status__card-secondary-icon" />
              <Typography
                className="status__card-last-seen"
                color="textSecondary"
              >
                {data.count} {data.count < 2 ? "Time Liked" : "Times Liked"}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    ) : (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
          <CardContent>
            <div className="stats__card-content">
              No Data Yet...
              <div className="stats__card-icon"></div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderMostCheckedoutProduct = (data) => {
    return data ? (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
        <CardContent>
            <div className="stats__card-icon">
              <Typography className="stats__icon_searched-title" color="textSecondary" variant="h5" component="h2" gutterBottom>
                <StyleIcon className="stats__icon-most-checkedout"/>
                  Most Checked Out Product
                
              </Typography>
            </div>
          </CardContent>
            
          <Divider />
          <CardActionArea href={"/product/" + data.product_uuid}>
            <Tooltip title={"Number of Likes: " + data.count}>
              <CardContent>
                <div className="stats__card-content">
                  
                  <div className="stats__card-data">
                    <Typography
                      color="textSecondary"
                      className="stats__card-company"
                      gutterBottom
                    >
                      {data.manufacturer_name}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className="stats__card-style-name"
                      gutterBottom
                    >
                      {data.trade_name}
                    </Typography>

                    <Typography
                      color="textSecondary"
                      // className="stats__card-style-more stats__card-company-viewed"
                      gutterBottom
                    >
                      {data.style}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Tooltip>
          </CardActionArea>
          <Divider />
          <CardContent>
            <div className="stats_card-date">
              <VisibilityIcon className="status__card-calendar-icon status__card-secondary-icon" />
              <Typography
                className="status__card-last-seen"
                color="textSecondary"
              >
                {data.count} {data.count < 2 ? "Time Checked Out" : "Times Checked Out"}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    ) : (
      <Grid item>
        <Card className="stats__card-item" variant="outlined">
          <CardContent>
            <div className="stats__card-content">
              No Data Yet...
              <div className="stats__card-icon"></div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <div className="stats__cards-container">
      <Grid container className="stats__grid-container-cards" spacing={3}>
        {renderRecentProduct(mostRecentViewedProduct)}
        {renderMostVisitedProduct(mostViewedProduct)}
        {renderMostViewedManufacturer(mostViewedManufacturer)}
        {renderMostLikedProduct(mostLikedProduct)}
        {renderMostCheckedoutProduct(mostCheckedoutProduct)}
      </Grid>
    </div>
  );
};

export default StatCards;
