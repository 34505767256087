import React, { useContext, useState, useEffect } from "react";

// Actions
import { getMergedProductDetails } from "../../Actions";

// MUI
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Tooltip,
    makeStyles,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
  } from "@material-ui/core";

  // dependencies
  import { AlertContext } from "../../Context/AlertContextProvider";
  
  // icons
  import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
  import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
  import InfoIcon from '@material-ui/icons/Info';

  // config
  import { productsTableConfigMerge } from "../config";

  // Style 
  import "../styles/Row.css"

  const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#ececec"
    },
    table: {
        "& > p:nth-child(1)": {
            backgroundColor: "#c9c9c9"
        }
    },
    checkbox : {
        color: "#FF682C !important"
    }, 
    tooltip: {
        marginLeft: 10
    },
    infoIcon: {
        color: "#FF682C",
    }
  }));

  const Merge = (props) => {

    const { handleSourceMerge, handleTargetMerge, productList, unmergeFlag } = props;

    const productsTableConfigMergeSecondary = [
        { id: "product_sku", label: "SKU", minWidth: 80 },
        { id: "color_name", label: "Color", minWidth: 80 },
        { id: "cost", label: "Cost", minWidth: 80 },
        { id: "promo_cost", label: "Promo Cost", minWidth: 80 },
        { id: "retail_price", label: "Retail\u00a0Price", minWidth: 80 },
        { id: "promo_retail_price", label: "Promo \u00a0Price", minWidth: 80 },
        { id: "selling_unit", label: "Selling\u00a0Unit", minWidth: 80 },
        { id: "thickness", label: "Thickness", minWidth: 80},
        { id: "wear_layer", label: "Wear Layer", minWidth: 80},
        { id: "ounce_weight", label: "Ounce Weight", minWidth: 80},
        { id: "margin", label: "Margin", minWidth: 80 },
        { id: "uom", label: "UOM", minWidth: 100 },
        { id: "basis_cd", label: "Basis", minWidth: 100 },
        { id: "last_modified_dttm", label: "Last Modified Date/Time", minWidth: 80 }
      ];

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { handleAlertOpen, setMessageType, setMessage } = useContext(
        AlertContext
    );
    const [ounceWeight, setOunceWeight] = useState(false);
    const [wearLayer, setWearLayer] = useState(false);
    const [thickness, setThickness] = useState(false);
    const [promoCost, setPromoCost] = useState(false);
    const [promoRetail, setPromoRetail] = useState(false);
    const [productInfo, setProductInfo] = useState([]);
    const today = new Date().toISOString().slice(0, 10);
    let costArray = [];
    let priceArray = [];
    const costDisclaimer = "Call for price."
    const [selectedIndex, setSelectedIndex] = useState();
    const [targetDisabled, setTargetDisabled] = useState();
    const [targetCount, setTargetCount] = useState(0);
    const [targetMergeUuid, setTargetMergeUuid] = useState();
    const [sourceProductUuidList, setSourceProductUuidList] = useState([]);

    useEffect(() => {
        productList.map((row) => {
            if (row.merged_product_uuid !== null ) {
                handleTargetMerge(row.merged_product_uuid)
                setTargetMergeUuid(row.merged_product_uuid)
                setTargetCount(targetCount + 1)
            }
        })
    }, [targetMergeUuid])

    const handleTarget = (e, id, index) => {
        handleTargetMerge(id);
        setTargetCount(targetCount + 1);
        setTargetDisabled(index);
        setTargetMergeUuid(id);
        if (targetDisabled === index) { 
            handleTargetMerge("");
            setTargetDisabled("");
            setTargetCount(0);
            setTargetMergeUuid();
            var index = sourceProductUuidList.indexOf(id);
            if (index !== -1) {
              sourceProductUuidList.splice(index, 1);
            };
        }
    };

    const handleMerge = (e, id, mergedId, index) => {
         if(mergedId !== null) {
            unmergeFlag(true);
         };
        handleSourceMerge(id);
        if(e.target.checked === true) {
            setSourceProductUuidList(sourceProductUuidList => [...sourceProductUuidList, id] )
        } else {
            unmergeFlag(false);
            var index = sourceProductUuidList.indexOf(id);
            if (index !== -1) {
                sourceProductUuidList.splice(index, 1);
            };
            };
    };

    const handleOpen = async (productId, index, roleId) => {
          try {
            const data = await getMergedProductDetails(productId, roleId);
            setProductInfo(data.data.data[0].options);
            data.data.data[0].options.forEach((result, index)=>{
              costArray.push(result.promotion.cost);
              priceArray.push(result.promotion.retail_price);
              
              if ((result.wear_layer !== "") && (result.wear_layer !== null) && (result.wear_layer !== undefined)) {
                setWearLayer(true);
              };
              if ((result.ounce_weight !== "") && (result.ounce_weight !== null) && (result.ounce_weight !== undefined)) {
                setOunceWeight(true);
              };
              if ((result.thickness !== "") && (result.thickness !== null) && (result.thickness !== undefined)) {
                setThickness(true);
              };
              if (costArray.some(c => c !== null)) {
                setPromoCost(true);
              } else {
                setPromoCost(false);
              };
              if (result.promotion.retail_price !== null && result.promotion.effective_start_date !== null && result.promotion.effective_end_date !== null) {
                setPromoRetail(true);
              } else {
                setPromoRetail(false);
              };
            })
          } catch (e) {
            setMessageType("error");
            setMessage("Error retrieving data");
            handleAlertOpen();
          }

        if (selectedIndex === index) {
            setSelectedIndex("")
          } else {
            setSelectedIndex(index)
          }
        setOpen(!open);
      };

      const renderTableHead = () => {
        return (
            <TableHead className="select-all-products">
              <TableRow>

                <TableCell className="table-head">More Details</TableCell>
                {productsTableConfigMerge.map((headCell) => {
                  return (
                    <TableCell
                    className="table-head"
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "default"}
                    >
                        {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          );
      };

      const handleShowHeader = () => {
        if (wearLayer === false) {
          for (var i = 0; i < productsTableConfigMergeSecondary.length; i++){
            if ( productsTableConfigMergeSecondary[i].id === 'wear_layer') { 
                productsTableConfigMergeSecondary.splice(i, 1); 
            } 
          }
        } 
    
        if (thickness === false) {
          for (var t = 0; t < productsTableConfigMergeSecondary.length; t++){
            if ( productsTableConfigMergeSecondary[t].id === 'thickness') { 
                productsTableConfigMergeSecondary.splice(t, 1); 
            } 
          }
        }
    
        if (ounceWeight === false) {
          for (var o = 0; o < productsTableConfigMergeSecondary.length; o++){
            if ( productsTableConfigMergeSecondary[o].id === 'ounce_weight') { 
                productsTableConfigMergeSecondary.splice(o, 1); 
            } 
          }
        }
    
        if (promoCost === false) {
          for (var i = 0; i < productsTableConfigMergeSecondary.length; i++){
            if ( productsTableConfigMergeSecondary[i].id === 'promo_cost') { 
                productsTableConfigMergeSecondary.splice(i, 1); 
            } 
          }
        } 
    
        if (promoRetail === false) {
          for (var i = 0; i < productsTableConfigMergeSecondary.length; i++){
            if ( productsTableConfigMergeSecondary[i].id === 'promo_retail_price') { 
                productsTableConfigMergeSecondary.splice(i, 1); 
            } 
          }
        } 
    
        return (
              productsTableConfigMergeSecondary.map((header) => {
                return (
                  <TableCell className={classes.header} key={header.id}>{header.label}</TableCell>
                );
              })
          )
      }

      const isChecked = (row, i) => {
        if (targetDisabled === i) {
            return false;
        } else if (targetMergeUuid == row.product_uuid ) {
            return false;
        } else if (!sourceProductUuidList.includes(row.product_uuid)) {
            return false;
        } else {
            return true;
        };
      };

    const checkboxLabel = (row, i) => {
        if (row.merged_product_uuid !== null) {
            return "Unmerge";
        } else {
            return "Merge";
        }
    };

    return (
        <div>
            <Table>
            {renderTableHead()}                   
            {productList.map((row, i) => {
                return (
                    <TableBody key={i}>
                    <TableRow>
                        <TableCell>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleOpen(row.product_uuid, i)}
                            >
                                {i === selectedIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                            {row.merged_product_uuid !== null && (
                            <Tooltip className={classes.tooltip} placement="bottom-end" title={<h2>This product has been merged into another</h2>}>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                                )}
                        </TableCell>
                        <TableCell>
                            {row.manufacturer_name}
                        </TableCell>
                        <TableCell>
                        {row.private_style_name}
                        </TableCell>
                        <TableCell>
                        {row.trade_name}
                        </TableCell>
                        <TableCell>
                        {row.style}
                        </TableCell>
                        <TableCell>
                        {row.material_class}
                        </TableCell>
                        <TableCell>
                        {row.material_sub_class}
                        </TableCell>
                        <TableCell>
                        {row.size_cd}
                        </TableCell>
                        <TableCell>
                        {row.backing_cd}
                        </TableCell>
                        <TableCell>
                            <FormGroup>
                                <FormControlLabel 
                                disabled={targetCount > 0 && targetDisabled  !== i} 
                                control={<Checkbox 
                                className={classes.checkbox} 
                                name="keep" 
                                onClick={(e) => handleTarget(e, row.product_uuid, i)}/>} 
                                label="Keep" 
                                checked={targetMergeUuid === row.product_uuid}
                                />
                                <FormControlLabel 
                                disabled={targetDisabled === i || targetMergeUuid === row.product_uuid ? true : false} 
                                control={<Checkbox 
                                className={classes.checkbox}
                                name={"merge"} 
                                onClick={(e) => handleMerge(e, row.product_uuid, row.merged_product_uuid ,i)}
                                checked={isChecked(row, i)}/>} 
                                label={checkboxLabel(row, i)} />
                            </FormGroup>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                    <Collapse in={i === selectedIndex} timeout="auto" unmountOnExit style={{width:'90vw'}}>
                        <Box margin={1} style={{width:'90vw'}}>
                            <TableHead>
                                <TableRow>
                                {handleShowHeader()}
                                </TableRow>
                            </TableHead>
                                {productInfo.map((product, i) => {
                                var cost = product.cost
                                if (Number(cost) === 0.00) {
                                    cost = costDisclaimer
                                }else{
                                    cost = '$' + product.cost
                                }
                                var retail = product.retail_price
                                if (Number(retail) === 0.00) {
                                    retail = costDisclaimer
                                }else{
                                    retail = '$' + product.retail_price
                                }

                                return (
                                    
                                    <TableRow key={i}>
                                    <TableCell component="th" scope="row">
                                        {product.product_sku}
                                    </TableCell>
                                    <TableCell>{product.color_name}</TableCell>
                                    <TableCell>{cost}</TableCell>


                                    {promoCost === true 
                                    && product.promotion.effective_end_date > today
                                    && product.promotion.effective_start_date < today 
                                    ? (<TableCell style={{minWidth:50}}>{product.promotion.cost != null ? `$ ${product.promotion.cost}` : ""}</TableCell>)
                                    : promoCost === true  ? <TableCell></TableCell> : ""}


                                    <TableCell>{retail}</TableCell>
                                    {/* {
                                        !disabled ? 
                                        <TableCell>
                                        $
                                        <TextField 
                                        className='text-field'
                                        inputProps={{fontcolor, maxLength: 7}}
                                        value={product.retail_price_override ? product.retail_price_override : ''}
                                        onChange={(event) => handleUpdateRPO(event.target.value, product.cost_id)}
                                        >
                                        </TextField>
                                        </TableCell> : <TableCell style={{display:"none"}}></TableCell>
                                    } */}
                                    


                                    {promoRetail === true
                                    && product.promotion.effective_end_date > today
                                    && product.promotion.effective_start_date < today 
                                    ? (<TableCell style={{minWidth:190}}>{product.promotion.retail_price != null
                                    ? `$ ${product.promotion.retail_price} - Ending ${product.promotion.effective_end_date}` 
                                    : ""}</TableCell>)
                                    : promoRetail === true  ? <TableCell></TableCell> : ""}


                                    <TableCell>{product.selling_unit_quantity} {product.selling_unit_uom}</TableCell>
                                    {thickness === true && (<TableCell>{product.thickness}</TableCell>)}
                                    {wearLayer === true && (<TableCell>{product.wear_layer}</TableCell>)}
                                    {ounceWeight === true && (<TableCell>{product.ounce_weight}</TableCell>)}
                                    <TableCell>{product.margin}</TableCell>
                                    <TableCell>{product.uom}</TableCell>
                                    <TableCell>{product.basis_cd}</TableCell>
                                    {/* <TableCell>{product.distributor}</TableCell> */}
                                    <TableCell>{product.last_modified_dttm !== null || product.last_modified_dttm !== undefined ? product.last_modified_dttm.split(".")[0] : "Never"}</TableCell>
                                    {/* <TableCell>
                                    <Checkbox
                                        onClick={(event) => handleDeleteSku(event.target.checked, product.cost_id)}
                                        checked={isDeleteSelected}
                                        inputProps={{ "aria-labelledby": labelId }}
                                        className="row__checkbox"
                                    />
                                    </TableCell> */}
                                    {product.discontinued === 1 ?
                                    <TableCell>
                                        <Tooltip title="Product Discontinued">
                                        <InfoIcon color="secondary" />
                                        </Tooltip>
                                    </TableCell> :
                                    <TableCell></TableCell> 
                                    }
                                    </TableRow>
                                );
                                })}
                            </Box>
                    </Collapse>
                    </TableRow>
                    </TableBody>
                )              
            })}
            </Table>
        </div>
    );
  };
  
  export default Merge;