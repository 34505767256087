import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";

// MUI
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";

// icons
import InfoIcon from "@material-ui/icons/Info";

// styles
// import MomentUtils from "@date-io/moment";
import "../styles/UpdateProduct.css";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";

// dependends
import { AppContext } from "../../Context/AppContextProvide";
import { AlertContext } from "../../Context/AlertContextProvider";
import { ProductContext } from "../../Context/ProductContext";

// actions
import { resetMargin, updateProductStatus, setProductDescription } from "../../Actions";

// updateProduct as updateReq,

// component
const UpdateProduct = (props) => {
  const {
    params: { productId },
  } = props.match;
  const { product, setPath, setUpdate } = useContext(ProductContext);
  const { user, roleId } = useContext(AppContext);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const [open, setOpen] = useState(props.open);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date("2054-08-29T21:11:54"));
  // const [price, setPrice] = useState(0);
  const [cost, setCost] = useState(0);
  // const [margin, setMargin] = useState(0);
  const [marginId, setMarginId] = useState(0);
  const [marginOverride, setMarginOverride] = useState(0);
  // const [taxRate, setTaxRate] = useState(0);
  const [editedUser, setEditedUser] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [switchState, setSwitchState] = useState(false);
  const [discontinueDate, setDiscontinueDate] = useState(null);
  const [productDiscontinueDate] = useState([]);
  const [pDiscont, setPDiscont] = useState();
  const [description, setDescription] =  useState(props.productDescription);
  const [image, setImage] = useState(props.productImage);

  //
  const [costId, setCostId] = useState(0);
  const [productData, setProductData] = useState("");
  const [productUuidData , setProductUuidData ] = useState();
  
  //
  const [color, setColor] = useState("");
  // const [comment, setComment] = useState("");

  useEffect(() => {

    setPath(`${productId}/${roleId}`);
    if (product.product_uuid) {
      let startdate =
        product.options[0].effective_start_date === null
          ? startDate
          : product.options[0].effective_start_date;
      let enddate =
        product.options[0].effective_end_date === null
          ? endDate
          : product.options[0].effective_end_date;
      setColor(product.options[0].color);
      // setPrice(product.options[0].retail_price);
      setCost(product.options[0].cost);
      setCostId(product.options[0].cost_id);
      // setMargin(product.options[0].margin);
      // setTaxRate(product.options[0].tax_rate);
      setMarginOverride(product.options[0].margin_override);
      setMarginId(product.options[0].margin_id);
      setStartDate(startdate);
      setEndDate(enddate);
      setEditedUser(product.options[0].user_name);
      // setComment(product.options[0].comments);
      setDiscontinueDate(product.options[0].product_effective_end);
    }
    if (product.options[0].discontinued === 0){
      setSwitchState(false);
    } else {
      setSwitchState(true);
    }
    if (!productDiscontinueDate.includes(0)) {
      setPDiscont(1);
    }
  }, [productId, setPath, product, startDate, endDate, productDiscontinueDate, roleId]);

  const popOverOpen = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // handlers
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleProductDescriptionChange = (e) => {
    setDescription(e)
  };

  const handleProductImageChange = (e) => {
    setImage(e)
  };

  // const handleComments = (value) => {
  //   setComment(value);
  // };

  // const handleStartDate = (date) => {
  //   setStartDate(date);
  // };

  // const handleEndDate = (date) => {
  //   setEndDate(date);
  // };

  // const handlePriceChange = (value, cost) => {
  //   setPrice(value);
  //   const tempMargin = calMargin(value, cost) * 100;
  //   setMargin(tempMargin);
  // };

  // const handleMarginChange = (value, cost) => {
  //   setMargin(value);
  //   const tempPrice = calPrice(cost, value / 100);
  //   setPrice(tempPrice);
  // };

  const handleSizeChange = (e) => {
    const tempColor = e.target.value;
    setColor(tempColor);
    const sizeOption = product.options.filter(
      (option) => option.color === tempColor
    )[0];
    if (!product.options.filter((option) => option.color === tempColor)[0]) {
      setProductUuidData (e.target.value);
      setProductData(e.target.value)
      setCostId(undefined);
      // setPrice(0);
      setCostId(0);
      // setMargin(-1);
      setCost(0);
      if (Number(pDiscont) !== 1){
        setSwitchState(false);
      } else {
        setSwitchState(true);
      };
    } else {
      setProductUuidData (undefined);
      setProductData(e.target.value);
      // setPrice(sizeOption.retail_price);
      setCostId(sizeOption.cost_id);
      // setMargin(sizeOption.margin);
      setCost(sizeOption.cost);
      // setTaxRate(sizeOption.tax_rate);
      setMarginOverride(sizeOption.margin_override);
      setMarginId(sizeOption.margin_id);
      setEditedUser(sizeOption.user_name);
      // setComment(sizeOption.comments);
      setDiscontinueDate(sizeOption.product_effective_end);
      if (sizeOption.discontinued === 0){
        setSwitchState(false);
      } else {
        setSwitchState(true);
      };
    }

  };

  // const handlePopOverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handlePoperOverClose = (event) => {
    setAnchorEl(null);
  };

  const handleMarginReset = (marginId) => {
    resetMargin(marginId)
      .then(() => {
        setMessageType("success");
        setMessage("Margin reset successful!");
        handleAlertOpen();
        setUpdate(true);
      })
      .catch(() => {
        setMessageType("error");
        setMessage("Margin reset error!");
        handleAlertOpen();
      });
  };

  const handleSubmit = () => {
    setProductDescription(description, image, product.product_uuid)
    .then(() => {
      setMessageType("success");
      setMessage("Link to product description updated!");
      handleAlertOpen();
      setUpdate(true);
      handleClose();
    })
    .catch(() => {
      setMessageType("error");
      setMessage("Error setting link to product description!");
      handleAlertOpen();
    });
  };

  // const handleSubmit = () => {
  //   const startd =
  //     startDate.length < 11
  //       ? startDate
  //       : startDate.toISOString().substring(0, 10);
  //   const endd =
  //     endDate.length < 11 ? endDate : endDate.toISOString().substring(0, 10);

  //   updateReq(costId, margin, startd, endd, comment)
  //     .then(() => {
  //       setMessageType("success");
  //       setMessage("Successfull update margin!");
  //       handleAlertOpen();
  //       setUpdate(true);
  //     })
  //     .catch(() => {
  //       setMessageType("error");
  //       setMessage("Error updating margin!");
  //       handleAlertOpen();
  //     });
  // };

  // calculate
  const canEdit = (access) => {
    if (access === "a" || access === "rw") {
      return false;
    }
    return true;
  };

  // const getPrice = () => {
  //   return price;
  // };

  // const calMargin = (price, cost) => {
  //   const tempTax = 1 + taxRate / 100;
  //   const tempMargin = 1 - (cost * tempTax) / price;
  //   return parseFloat(tempMargin).toFixed(4);
  // };

  // const calPrice = (cost, margin) => {
  //   const tempPrice = parseFloat(
  //     (cost / (1 - margin)) * (1 + taxRate / 100)
  //   ).toFixed(2);
  //   console.log("tempPrice", tempPrice);
  //   return tempPrice;
  // };

  // const getComment = () => {
  //   let commentToReturn = comment === null ? "" : comment;
  //   return commentToReturn;
  // };

  // //
  // const canUpdate = () => {
  //   if (costId !== 0) {
  //     return false;
  //   }
  //   return true;
  // };

  // const disabledMargin = () => {
  //   if (margin > -1) {
  //     return false;
  //   }
  //   return true;
  // };

  // const disablePrice = () => {
  //   if (price !== 0) {
  //     return false;
  //   }
  //   return true;
  // };

  const handleSwitchChange = (event) => {
    setSwitchState(event.target.checked)
    if (Number(event.target.checked) === 1) {
      updateProductStatus(costId, productData.product_uuid, 1)
      .then((response) => {
        setDiscontinueDate(response.data.discontinue_date);
        setUpdate(true);
      });
      if (productUuidData !== undefined) {
        setPDiscont(1);
        setSwitchState(true);
      };
    }
    if (Number(event.target.checked) === 0) {
      updateProductStatus(costId, productData.product_uuid, 0);
      setDiscontinueDate();
      setUpdate(true);
      if (productUuidData  !== undefined) {
        setPDiscont(0);
      };
    }
  };

  const renderDiscontinued = () => {
    return (
      discontinueDate != null ?
        <div>
          <Typography className="product__offer-dates" color="textSecondary">
                Date Discontinued
          </Typography>
            <TextField
              name="Discontinued"
              type="text"
              value={discontinueDate}
              fullWidth
              InputProps={{
                  style: {
                      color: "red"
                  }
              }}
            />
        </div> :
          <div></div>
    )};

  const renderIcon = (eDate) => {
    if (eDate !== 0) {
      return ( 
        <Tooltip title="Product discontinued">
          <InfoIcon color="secondary" />
        </Tooltip>
      )
    };
  };

  const renderProductDiscIcon = (discon) => {
      return ( Number(discon === 1) ?
        <Tooltip title="Product discontinued">
          <InfoIcon color="secondary" />
        </Tooltip> : <div></div>
      )};

  const renderSwitch = () => {
    return (
      <div>
        <Grid className="mnd__tax-switch" component="label" container alignItems="center" spacing={1}>
        <Grid container className="mnd__product-status-switch" direction="column">Product status</Grid>
          <Grid item>Active</Grid>
            <Grid item>
              <Tooltip arrow={true}  title='Toggle to show product as discontinued'>
                <Switch
                  checked={switchState}
                  onChange={handleSwitchChange}
                  color="primary"
                  size="small"
                />
              </Tooltip>
            </Grid>
          <Grid item>Discontinued</Grid>
        </Grid>
        </div>
    )
  };

  const renderOptions = (options, data) => {
    return (
      <FormControl className="product__size-control">
        <InputLabel id="demo-controlled-open-select-label">
          Select Option
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          value={color}
          onChange={handleSizeChange}
          placeholder={"Options"}
        >
          <MenuItem value={data} >
            {data.trade_name}
            {renderProductDiscIcon(pDiscont)}
          </MenuItem>
          {options.map((option, i) => {
            productDiscontinueDate.push(option.discontinued)
           // setProductDisconDate(option.product_effective_end);
            const { color } = option;
            return (
              <MenuItem key={i} value={color}> 
                {color}
                {renderIcon(option.discontinued)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const renderForm = (data) => {
    if (data.product_uuid ) {
      const { product_uuid, options } = data;
      return (
        <div key={product_uuid} className="product__update-form">
          <form>
            {renderOptions(options, data)}
            <div>
              <span>
                <br/>
                <TextField
                name="description"
                type="text"
                label="Link to product description"
                value={description}
                fullWidth
                onChange={(e) => handleProductDescriptionChange(e.target.value)}
              />
              </span>
            </div>
            <div>
              <span>
                <br/>
                <TextField
                name="image"
                type="text"
                label="Link to product image"
                value={image}
                fullWidth
                onChange={(e) => handleProductImageChange(e.target.value)}
              />
              </span>
            </div>

            {/* <TextField
              name="price"
              type="number"
              label="Retail Price"
              placeholder="Update Price"
              value={getPrice()}
              fullWidth
              disabled
              // disabled={disablePrice()}
              onChange={(e) => handlePriceChange(e.target.value, cost)}
            /> */}

            <div className="product__update-margin-info">
              {/* <TextField
                name="margin"
                type="number"
                label="Margin"
                placeholder="Update Margin"
                value={margin}
                onChange={(e) => handleMarginChange(e.target.value, cost)}
                className="product_input-margin"
                disabled
                // disabled={disabledMargin()}
                fullWidth
              /> */}
              <div className="admin__announcement-info-container">
                {/* <IconButton
                  onClick={handlePopOverOpen}
                  className="admin__announcement-info-button"
                >
                  <InfoIcon />
                </IconButton> */}
                {/* PopOver */}
                <Popover
                  id={id}
                  open={popOverOpen}
                  anchorEl={anchorEl}
                  onClose={handlePoperOverClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div className="product-update___popover-container">
                    <Typography align="center" className="admin__info-future">
                      {" "}
                      {marginOverride === 1
                        ? "Margin Changed"
                        : "Default Margin"}
                    </Typography>

                    {editedUser !== null ? (
                      <div className="product-update__edited-user-container">
                        <Typography
                          className="product-update__edited-user-header"
                          align="center"
                          variant="h6"
                        >
                          Edited by
                        </Typography>
                        <Typography
                          className="product-update__edited-user"
                          align="center"
                          variant="body1"
                        >
                          {editedUser}
                        </Typography>
                      </div>
                    ) : (
                      <div className="product-update__edited-user-container">
                        <Typography
                          className="product-update__edited-user-header"
                          align="center"
                          variant="h6"
                        >
                          Edited by
                        </Typography>
                        <Typography
                          className="product-update__edited-user"
                          align="center"
                          variant="body1"
                        >
                          Username not available
                        </Typography>
                      </div>
                    )}
                    <Button
                      disabled={marginId === null ? true : false}
                      onClick={(e) => handleMarginReset(marginId)}
                      className="product-update__margin-reset-btn"
                    >
                      Reset
                    </Button>
                  </div>
                </Popover>
                {/* PopOver */}
              </div>
            </div>

            {/* <TextField
              name="comments"
              type="text"
              label="Comments"
              placeholder="Comments"
              value={getComment()}
              multiline
              rows="2"
              onChange={(e) => handleComments(e.target.value)}
              className="product_input-comments"
              fullWidth
            /> */}
            {/* <Typography className="product__offer-dates" color="textSecondary">
              Offer Dates
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Select Start Date"
                value={startDate}
                onChange={handleStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />

              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Select End Date"
                value={endDate}
                onChange={handleEndDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider> */}
            {renderDiscontinued()}
          </form>
        </div>
      );
    } else {
      return <div>No data</div>;
    }
  };

  const renderCards = (data) => {
    if (data.product_uuid) {
      const { product_uuid, company, type, style, style_name } = data;

      return (
        <Card key={product_uuid} className="product__info-card">
          <CardContent>
            <Typography className="title" color="textSecondary">
              Product
            </Typography>
            <Typography variant="h5" component="h2">
              {company}
            </Typography>
            <Typography color="textSecondary">
              {type} - {style}
            </Typography>
            <Typography color="textSecondary">{style_name}</Typography>
            <Typography variant="body2" component="p">
              cost: {cost}
            </Typography>
          </CardContent>
        </Card>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div style={{display: "inline-block", margin: "10px"}}>
      <Button
        className="product-editor"
        variant="contained"
        disabled={canEdit(user.access_type_cd)}
        onClick={handleOpen}
      >
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          {"Update Product Info"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid className="product_update-content" container spacing={4}>
            <Grid item className="product__info-updater">
              {renderCards(product)}
            </Grid>
            <Grid item>{renderForm(product)}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={4} direction="column">
            <Grid item align="left" style={{ display: "flex", justifyContent: "flex-start" }}>
              {renderSwitch()}
            </Grid>
          </Grid>
          <Grid container spacing={4} direction="column">
            <Grid item align="right" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleClose} 
                autoFocus 
                variant="contained"
                className="update-product-button"
              >
                Close
              </Button>
              <Button
                className="product__info"
                onClick={() => handleSubmit()}
                variant="contained"
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(UpdateProduct);
