// Products table main
export const productsTableConfigMain = [
  { id: "manufacturer_name", label: "Manufacturer", minWidth: 80 },
  { id: "distributor_name", label: "Distributor", minWidth: 80 },
  { id: "private_style_name", label: "Private Style Name", minWidth: 80 },
  { id: "trade_name", label: "Style Name", minWidth: 80 },
  { id: "style", label: "Style Number", minWidth: 80 },
  { id: "material_class", label: "Material Class", minWidth: 80 },
  { id: "material_sub_class", label: "Material Sub Class", minWidth: 80 },
  { id: "size_cd", label: "Size", minWidth: 40 },
  { id: "backing_cd", label: "Backing", minWidth: 40 },
  { id: "update", label: "Update", minWidth: 100 },
];

export const productsTableConfigMerge = [
  { id: "manufacturer_name", label: "Manufacturer", minWidth: 80 },
  { id: "private_style_name", label: "Private Style Name", minWidth: 80 },
  { id: "trade_name", label: "Style Name", minWidth: 80 },
  { id: "style", label: "Style Number", minWidth: 80 },
  { id: "material_class", label: "Material Class", minWidth: 80 },
  { id: "material_sub_class", label: "Material Sub Class", minWidth: 80 },
  { id: "size_cd", label: "Size", minWidth: 40 },
  { id: "backing_cd", label: "Backing", minWidth: 40 },
  { id: "merge", label: "Merge", minWidth: 40 }
];

// products table secondary data

export const productsTableConfigSecondary = [
  { id: "product_sku", label: "SKU", minWidth: 80 },
  { id: "color_name", label: "Color", minWidth: 80 },
  { id: "cost", label: "Cost", minWidth: 80 },
  { id: "retail_price", label: "Retail\u00a0Price", minWidth: 80 },
  { id: "selling_unit", label: "Selling\u00a0Unit", minWidth: 80 },
  { id: "thickness", label: "Thickness", minWidth: 80},
  { id: "wear_layer", label: "Wear Layer", minWidth: 80},
  { id: "ounce_weight", label: "Ounce Weight", minWidth: 80},
  { id: "margin", label: "Margin", minWidth: 80 },
  { id: "uom", label: "UOM", minWidth: 100 },
  { id: "basis_cd", label: "Basis", minWidth: 100 },
  // { id: "distributor", label: "Distributor", minWidth: 80 },
  { id: "last_modified_dttm", label: "Last Modified Date/Time", minWidth: 80 },
  { id: "delete", label: "Delete", minWidth: 80 }
];

export const productsTableConfigSecondaryRPO = [
  { id: "product_sku", label: "SKU", minWidth: 80 },
  { id: "color_name", label: "Color", minWidth: 80 },
  { id: "cost", label: "Cost", minWidth: 80 },
  { id: "retail_price", label: "Retail\u00a0Price", minWidth: 80 },
  { id: "retail_price_override", label: "Retail\u00a0Price\u00a0Override", minWidth: 80 },
  { id: "selling_unit", label: "Selling\u00a0Unit", minWidth: 80 },
  { id: "margin", label: "Margin", minWidth: 80 },
  { id: "uom", label: "UOM", minWidth: 100 },
  { id: "basis_cd", label: "Basis", minWidth: 100 },
  { id: "last_modified_dttm", label: "Last Modified Date/Time", minWidth: 80 },
  { id: "delete", label: "Delete", minWidth: 80 },
];

export const filterListConfig = [
  { id: "manufacturer_name", label: "Manufacturer", minWidth: 80 },
  { id: "distributor_name", label: "Distributor", minWidth: 80 },
  { id: "style", label: "Style Number", minWidth: 80 },
  { id: "trade_name", label: "Trade", minWidth: 100 },
  { id: "material_class", label: "Material Class", minWidth: 100 },
  { id: "material_sub_class", label: "Material Sub Class", minWidth: 100 },
  { id: "product_sku", label: "SKU", minWidth: 100 },
  { id: "color_name", label: "Color", minWidth: 100 }
];

export const MostProductsViewedConfig = [
  { id: "manufacturer_name", label: "Manufacturer", minWidth: 80 },
  { id: "style", label: "Style Number", minWidth: 80 },
  { id: "material_class", label: "Material\u00a0Class", minWidth: 100 },
  {
    id: "material_sub_class",
    label: "Material\u00a0Sub\u00a0Class",
    minWidth: 100,
  },
  { id: "trade_name", label: "Trade\u00a0Name", minWidth: 80 },
  { id: "times_viewed", label: "Times\u00a0Viewed", minWidth: 80 },
];

export const RecentlyViewedProductsConfig = [
  { id: "manufacturer_name", label: "Manufacturer", minWidth: 80 },
  { id: "style", label: "Style Number", minWidth: 80 },
  { id: "material_class", label: "Material Class", minWidth: 80 },
  { id: "trade_name", label: "Style Name", minWidth: 80 },
  { id: "user_name", label: "User", minWidth: 80 },
  { id: "role_name", label: "Role", minWidth: 80 },
  { id: "location_name", label: "Location", minWidth: 80},
  { id: "click_date", label: "View Date", minWidth: 80 },
];

export const MostViewedManufacturersConfig = [
  { id: "manufacturer_name", label: "Manufacturer", minWidth: 80 },
  { id: "times_viewed", label: "Times\u00a0Viewed", minWidth: 100 },
];

export const ProductFavoritesConfig = [
  { id: "name", label: "Name", minWidth: 80 },
  { id: "manufacturer_name", label: "Manufacturer", minWidth: 80 },
  { id: "trade_name", label: "Style Name", minWidth: 80 },
  { id: "style", label: "Style Number", minWidth: 80 },
  { id: "material_class", label: "Material Class", minWidth: 80 },
  { id: "role_name", label: "Role", minWidth: 80 },
  { id: "location_name", label: "Location", minWidth: 80},
  { id: "liked_dttm", label: "Like Date", minWidth: 80 },
  { id: "unliked_dttm", label: "Unlike Date", minWidth: 80 },
];

export const ProductCheckoutsConfig = [
  { id: "name", label: "Name", minWidth: 80 },
  { id: "manufacturer_name", label: "Manufacturer", minWidth: 80 },
  { id: "trade_name", label: "Style Name", minWidth: 80 },
  { id: "style", label: "Style Number", minWidth: 80 },
  { id: "material_class", label: "Material Class", minWidth: 80 },
  { id: "status_name", label: "Status", minWidth: 80 },
  { id: "role_name", label: "Role", minWidth: 80 },
  { id: "location_name", label: "Location", minWidth: 80},
  { id: "check_out_dttm", label: "Checkout Date", minWidth: 80 },
  { id: "check_in_dttm", label: "Return Date", minWidth: 80 },
];


