import React, { useEffect, useState } from "react";

// Actions 
import { getAllLikesByCustomerId } from "../../Actions";

//Styles
import "../styles/Checkout.css";
import { Divider, FormControl, IconButton, InputLabel, Link, ListItemText, MenuItem, Select, TableRow, Typography, ListItem, List, Collapse, TablePagination } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, ChevronLeft, ChevronRight, ExpandLess, ExpandMore } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export default function FavoriteHistory(props) {
  const [favoritesList, setFavoritesList] = useState([]);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("liked_dttm");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getFavoritesListForUser = async (lead) => {
    const result = await getAllLikesByCustomerId(lead.lead_id);
    setFavoritesList([...result.data.likes]);
  }

  useEffect(() => {
    if (props.lead && refresh) {
      getFavoritesListForUser(props.lead);
      setRefresh(false);
    }
  }, [props.lead, refresh])

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleClick = (index) => {
    open === index ? setOpen(undefined) : setOpen(index);
  };
  const favoritesRows = (paginatedList, view) => {
    if (view === 'm') {
      return paginatedList?.map((favorite, index) => (
        <React.Fragment key={favorite.product_like_id}>
          <List style={{ padding: "0px" }}>
            <ListItem button onClick={() => handleClick(favorite.product_like_id)} className="favorites__list-menu">
              <ListItemText primary={<Typography className="checkout__list-item-secondary">{favorite.style_name}</Typography>} secondary={favorite.liked_dttm} />
              {open === favorite.product_like_id ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {open === favorite.product_like_id ? (
              <Collapse in={true} timeout="auto" unmountOnExit>
                <section style={{ backgroundColor: "#FFF", padding: "8px 16px"}}>
                  <Link to={`/product/${favorite.product_uuid}`} className="favorite__links" target="blank">
                    <Typography style={{fontWeight: "bold"}}>{favorite.style_name}</Typography>
                  </Link>
                  <Typography>{favorite.manufacturer_name} / {favorite.material_class}</Typography>
                  <p>
                    { favorite.style_number ? (
                        <Typography>Style Number: {favorite.style_number}</Typography> 
                      ) : undefined
                    }
                    { favorite.color_name ? (
                        <Typography>Color: {favorite.color_name}</Typography> 
                      ) : undefined
                    }
                    { favorite.uom ? (
                        <Typography>UOM: {favorite.uom}</Typography> 
                      ) : undefined
                    }
                    { favorite.retail_price ? (
                        <Typography>Listing Price: ${favorite.retail_price}</Typography> 
                      ) : undefined
                    }
                  </p> 
                </section>
              </Collapse>
            ) : <></>}
          </List>
          <Divider></Divider>
        </React.Fragment>
      ))
    }
  }

  const handleChangePage = (e, page) => {
    setPage(page);
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
  }

  const paginationControls = (filteredList, view) => {
    const rowsPerPageOptions = [5, 10, 25];

    if (view === "m") {
      const buttonStyles = {
        backgroundColor: "rgba(0, 0, 0, 0.09)",
        margin: "0px 0px 0px 10px"
      };
      const isFirstPage = page === 0;
      const isLastPage = ((page + 1) * rowsPerPage) >= filteredList.length;
      const currentStart = Math.max(page * rowsPerPage, 1);
      const currentEnd = Math.min((page * rowsPerPage) + rowsPerPage, filteredList.length);

      return (
        <div style={{ margin: "0px 0px 20px", maxWidth: "400px" }}>
          <FormControl>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page"
              value={rowsPerPage}
              style={{ width: "125px" }}
              variant="filled"
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {rowsPerPageOptions.map(o => {
                return (
                  <MenuItem key={o} value={o}>{o}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <div style={{ float: "right" }}>
            <Typography style={{ display: "inline-block", verticalAlign: "middle", margin: "0px", textAlign: "center" }}>{currentStart} - {currentEnd} of {filteredList.length}</Typography>
            <IconButton variant="text" disabled={isFirstPage} style={buttonStyles} onClick={() => setPage(isFirstPage ? 0 : page - 1)}><ChevronLeft /></IconButton>
            <IconButton variant="text" disabled={isLastPage} style={buttonStyles} onClick={() => setPage(isLastPage ? page : page + 1)}><ChevronRight /></IconButton>
          </div>
        </div>
      )
    }

    return (
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={5}
          count={filteredList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableRow>
    )
  }

  if (!favoritesList?.length) return (
    <Typography>No favorites history currently available for this user</Typography>
  )

  const sortOptions = [
    {
      name: "Style Name",
      attribute: "style_name",
    },
    {
      name: "Manufacturer",
      attribute: "manufacturer_name",
    },
    {
      name: "Material Class",
      attribute: "material_class",
    },
    {
      name: "Date Favorited",
      attribute: "liked_dttm",
    },
    {
      name: "Price",
      attribute: "retail_price",
    }
  ];
  const sortMenuItems = sortOptions.map(i => {
    return (
      <MenuItem key={i.attribute} value={i.attribute}>
        {i.name}
      </MenuItem>
    )
  })

  const sortedFavoritesList = stableSort(favoritesList, getComparator(order, orderBy));
  const paginatedRows = sortedFavoritesList.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);

  return (
    <>
      <div style={{display: "inline-block", maxWidth: "500px", margin: "10px auto", verticalAlign: "middle"}}>
        <FormControl variant="filled" style={{width: "150px", marginBottom: "10px", marginRight: "10px"}}>
          <InputLabel id="checkout-sort-list-label">Sort By</InputLabel>
          <Select
            labelId="checkout-sort-list-label"
            id="checkout-sort-list"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            variant="filled"
          >
            {sortMenuItems}
          </Select>
        </FormControl>
        <ToggleButtonGroup value={order} aria-label="sort order" exclusive={true} onChange={(e, value) => {if(value) setOrder(value)}} style={{ height: "56px", marginBottom: "10px"}}>
          <ToggleButton value="asc" aria-label="ascending" style={{width: "50%"}}>
            <ArrowUpward /> Asc
          </ToggleButton>
          <ToggleButton value="desc" aria-label="descending" style={{width: "50%"}}>
            <ArrowDownward /> Desc
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {paginationControls(favoritesList, "m")}
      <div style={{ overflowY: "auto", backgroundColor: "#EEEEEE", height: "80%", maxHeight: "450px" }}>
        {favoritesRows(paginatedRows, "m")}
      </div>
    </>
  )
}