import React, {useEffect, useState, useContext } from "react";

// MUI
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";

// Icons
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DescriptionIcon from '@material-ui/icons/Description';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WidgetsIcon from '@material-ui/icons/Widgets';
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

// Actions
import { getFilesByPartner, getProductsByFilename } from "../../Actions";

// styles
import "../styles/FileHistory.css";

// Dependencies
import FileHistory from "../LandingPage/FileHistory/FileHistory";
import { ProgressContext } from "../../Context/ProgressContextProvider";

const SimpleFileHistory = (props) => {

    const latest = props.file_history;
    const [open, setOpen] = useState(false);
    const [body, setBody] = useState([]);
    const [value, setValue] = useState();
    const [header, setHeader] = useState([]);
    const [listOpen, setListOpen] = useState(undefined);
    const { handleProgressOpen, handleProgressClose } = useContext(ProgressContext);

    useEffect(() => {}, []);

    const handleClick = (index) => {
        listOpen === index ? setListOpen(undefined) : setListOpen(index);
    };

    const handleSelectedPartner = (event) => {
        handleProgressOpen();
        getFilesByPartner(event)
        .then((result ) => {
            handleProgressClose();
            setBody(result.data.latest);
            setValue(1);
            setHeader(['Partner', 'File Name', 'Style Count', 'SKU Count', 'Last Updated']);
            setOpen(true);
        });
        
    };

    const handleSelectedFile = (fileName) => { 
        handleProgressOpen();
        getProductsByFilename(encodeURIComponent(fileName))
            .then((result) => {
                handleProgressClose();
                setBody(result.data.latest)
                setValue(0);
                setHeader(['View Product', 'Manufacturer Name', 'Style Number', 'Style Name', 'Material Class Code']);
                setOpen(true);
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const infoHeader = () => {
        return (latest.length ?
            <TableRow>
                <TableCell className="header-cell">Partner</TableCell>
                <TableCell className="header-cell">File Name</TableCell>
                <TableCell className="header-cell">Style Count</TableCell>
                <TableCell className="header-cell">SKU Count</TableCell>
                <TableCell className="header-cell">Last Updated</TableCell>
            </TableRow> :
            <TableRow></TableRow>

    )};

    const renderDialog = () => {
        return (
        <Dialog onClose={handleClose} open={open} maxWidth={'lg'} fullWidth={true}>
            <DialogTitle className="files-by-partner-header">
                Files by partner 
                <Button variant="outlined"
                style={{color:"#FF682C", marginLeft:20, fontSize:20, float:"right"}}
                onClick={handleClose}>
                X
                </Button>
            </DialogTitle>
            < FileHistory body={body} header={header} value={value}/> 
        </Dialog>
        )};

    return ( latest.length ?
        <div>
            <div className="fh__container-full">
                <div style={{width:"100%"}}>
                    <Typography variant="h4">
                    Automatic B2B Pricing Updates
                    </Typography>
                </div>
                {/* <AppBar position="static" className="home__appbar">
                    <Toolbar variant="dense">
                        <Typography
                            className="home__announcement-header"
                            variant="h6"
                            color="inherit"
                        >
                            Automatic B2B Pricing Updates
                        </Typography>
                    </Toolbar>
                </AppBar> */}
                <Table>
                    <TableBody>
                        {infoHeader()}
                    </TableBody>
                </Table>
                <Divider style={{height:5}} />
                <Table>
                    <TableBody>
                        {latest.map((data, i ) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell className="table-cell">
                                        <Button 
                                            variant="contained"  
                                            className="files-by-partner-button" 
                                            onClick={() => handleSelectedPartner(data.partner)}>{data.partner}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Button 
                                            variant="contained" 
                                            className="files-by-partner-button" 
                                            onClick={() => handleSelectedFile(data.filename)}>{data.filename.includes("/") ? data.filename.split("/")[1].slice(-13) : data.filename.slice(-13)}
                                        </Button>
                                    </TableCell>
                                    <TableCell className="table-cell">{data.productCount}</TableCell>
                                    <TableCell className="table-cell">{data.skuCount}</TableCell>
                                    <TableCell className="table-cell">{data.lastUpdateDateTime}</TableCell>
                                </TableRow>
                        )})}
                    </TableBody>
                </Table>   
                {renderDialog()}    
            </div> 

            <div className="fh__container-mobile">
                    <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                    <ListSubheader component="div" id="nested-list-subheader" className="fh__list-sub-header">
                        <div style={{width:"100%"}}>
                            <Typography variant="h6">
                                B2B Pricing Updates
                            </Typography>
                        </div>
                    </ListSubheader>
                }
                >
                {latest.map((partner, index) => {
                    return (
                    <List key={index}>
                        <ListItem button onClick={() => handleClick(index)} className="fh__list-menu">
                        <ListItemIcon>
                        <Button onClick={() => handleSelectedPartner(partner.partner)}><InsertDriveFileIcon style={{color:"#FF682C"}}/></Button>
                        </ListItemIcon>
                            <ListItemText primary={partner.partner} />
                        {listOpen === index ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                {listOpen === index ? 
                <Collapse in={true} timeout="auto" unmountOnExit>
                <List style={{backgroundColor:"#FFF"}}>
                <ListItem>
                    <ListItemIcon>
                        <DescriptionIcon onClick={() => handleSelectedFile(partner.filename)}/>
                    </ListItemIcon>
                    <ListItemText primary="File Name" secondary={partner.filename.includes("/") ? partner.filename.split("/")[1].slice(-13) : partner.filename.slice(-13)} />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <WidgetsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Product Count" secondary={partner.productCount}/>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <WidgetsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sku Count" secondary={partner.skuCount}/>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                        <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Updated" secondary={partner.lastUpdateDateTime}/>
                </ListItem>
                </List>
                </Collapse> :
                <></>}
                    </List>
                )})}
                </List>
                </div>          
             </div>
        : <div>
            <Typography variant="h5" style={{textAlign: "center", backgroundColor: "#d3e7fa", height: 50}}>
                No Automatic B2B Pricing History Available
            </Typography>
        </div>
    )
};

export default SimpleFileHistory;