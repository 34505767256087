import React, { useContext, useEffect } from "react";
import { AppContext } from "../Context/AppContextProvide";
import { useHistory, useLocation, withRouter } from "react-router-dom";

const Auth = (props) => {
  const tempToken = window.location.search;
  const token = tempToken.substring(13, tempToken.length);
  const history = useHistory();
  const location = useLocation();
  const expTime = new Date().getTime() + (3600000);
  localStorage.setItem("accessToken", token);
  localStorage.setItem("authType", "google");
  localStorage.setItem("tokenExpiration", expTime);
  localStorage.setItem("sessionTime", expTime); 

  const { setAccessToken, setAuthType } = useContext(AppContext);

  useEffect(() => {
    setAccessToken(token);
    setAuthType("google");
    setTimeout(() => history.push("/home"), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, setAccessToken]);

  return <div></div>;
};

export default withRouter(Auth);
