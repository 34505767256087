import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
import { getUser } from "../helpers";
import { baseUrl } from "../Contants";

export const AdminCompanyMarginContext = createContext();

export const AdminCompanyMarginContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [margin, setCompanyMargin] = useState([]);
  // const [loadedMargin, setLoadedMargin] = useState([]);
  const [update, setUpdate] = useState(false);

  const isAdmin = user.access_type_cd === "a" ? true : false;

  useEffect(() => {
    async function getCompanyMargin() {
      const result = await axios(`${baseUrl}/api/admin/margin/company`);
      setCompanyMargin(result.data.data);
    }
    // async function getLoadedMargin() {
    //   const result = await axios(`${baseUrl}/api/admin/margin/loaded`);
    //   setLoadedMargin(result.data.data);
    // }
    if (isAdmin) {
      getCompanyMargin();
      // getLoadedMargin()
      setUpdate(false);
    }
  }, [isAdmin, update]);

  return (
    <AdminCompanyMarginContext.Provider value={{ margin, setUpdate }}>
      {children}
    </AdminCompanyMarginContext.Provider>
  );
};
