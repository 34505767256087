import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContextProvide";
import { useHistory } from "react-router-dom";

// styles
import "../styles/User.css";

// Icons
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EditIcon from '@material-ui/icons/Edit';

// Context
import { AlertContext } from "../../Context/AlertContextProvider";

// MUI
import {
  Avatar,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  OutlinedInput
} from "@material-ui/core";

// Dependencies
import ChangePassword from "./ChangePassword";

// Actions
import { updateLocalUser } from "../../Actions";

const User = () => {
  const { user: userData, setAuthType, setAccessToken } = useContext(AppContext);
  const [hasImage, setHasImage] = useState(false);
  const [hasUsername, setHasUsername] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [token] = useState(localStorage.getItem("tokenExpiration"));
  const [authorizationType] = useState(userData.auth_type);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );
  const history = useHistory();

  // user data
  const [user, setUser] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (userData.user_id) {
      const tempHasImage = userData.picture !== null;
      const tempUsername = userData.name !== null;
      const name = userData.name === null ? "" : userData.name;
      setHasImage(tempHasImage);
      setHasUsername(tempUsername);
      setUser(name);
      setUsername(userData.user_name);
      setEmail(userData.email);
      setPhone(userData.phone);
    }
  }, [userData]);

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  }

  const handleSubmit = () => {
    updateLocalUser(user, email, phone, userData.user_id)
    .then((result) => {
      setMessageType("success");
      setMessage("Account information updated");
      handleAlertOpen(true);
      setAuthType(authorizationType);
      setEditOpen(false);
      setAccessToken(token);
    })
    .catch((err) => {
      setMessageType("error");
      setMessage("Error updating account information");
      handleAlertOpen(true);
      setEditOpen(false);
    });
  };

  const renderAccessType = (access) => {
    if (access === "a") return "Admin";
    if (access === "ro") return "Read Only";
    if (access === "rw") return "Read/Write";
    if (access === "c") return "Customer";
  };

  const renderAvatar = (hasimage) => {
    return hasimage ? (
      <Avatar className="user__avatar" src={userData.picture}></Avatar>
    ) : (
      <Avatar
        style={{ backgroundColor: "#F4F9E9" }}
        className="user__avatar"
      >
        <Typography style={{color:"black"}} variant="h4">
        {userData.user_name[0].toUpperCase()}
        </Typography>
      </Avatar>
    );
  };


  const renderProfile = (data) => {
    return userData.user_id ? (
  <div className="user__container">
    <div className ="inf-content">
      <Grid container spacing={3}>
        <Grid item lg={3} md={6} sm={12} className="user__avatar-container">
          <div className="user__info-container">
            {renderAvatar(hasImage)}
          </div>
        </Grid>
        <Grid item lg={8} md={6} sm={12}>
          <Table>
            <TableBody>
            <TableRow>
                <TableCell className="user__td" colSpan={2}>
                  <Typography variant="h4">
                    Account Information
                  </Typography>
                </TableCell>           
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h5">
                    {<PersonIcon />} Name
                  </Typography>
                </TableCell>
                <TableCell>
                  {editOpen === true ?
                  <OutlinedInput
                  className="register__text-field"
                  type="text"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                  aria-describedby="confirmnote"
                /> :
                  <Typography variant="body1" style={{overflowWrap: "break-word"}}>
                    {data.name}
                  </Typography> }
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell >
                  <Typography variant="h5">
                    {<AccountBoxIcon />} User Name
                  </Typography>
                </TableCell>
                <TableCell>            
                  <Typography variant="body1" style={{overflowWrap: "break-word"}}>
                    {data.user_name}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{width:"20vw"}}>
                  <Typography variant="h5" style={{overflowWrap: "break-word"}}>
                    {<EmailIcon />} Email
                  </Typography>
                </TableCell>
                <TableCell>
                {editOpen === true && data.auth_type !== "google" ?
                  <OutlinedInput
                  className="register__text-field"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  aria-describedby="confirmnote"
                /> :
                  <Typography variant="body1" style={{overflowWrap: "break-word"}}>
                    {data.email}
                  </Typography>}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h5" style={{overflowWrap: "break-word"}}>
                    {<PhoneIphoneIcon />} Phone Number
                  </Typography>
                </TableCell>
                <TableCell>
                {editOpen === true ?
                  <OutlinedInput
                  className="register__text-field"
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  required
                  aria-describedby="confirmnote"
                /> :
                  <Typography variant="body1" style={{overflowWrap: "break-word"}}>
                    {data.phone}
                  </Typography>}
                </TableCell>
              </TableRow>

              <TableRow>
                {editOpen === true ? 
                <TableCell colSpan={2} className="user__button-row">
                  <Button 
                  variant="outlined" 
                  style={{background:"#fcfcfc"}}
                  onClick={handleEditClose}
                  autoFocus
                  >
                  Cancel
                  </Button>

                  <Button 
                  variant="outlined" 
                  className="user__submit-button"
                  onClick={handleSubmit}
                  >
                  Submit
                  </Button>

                  <div style={{float:"right"}}>
                    <ChangePassword open={open}/>
                  </div>
                </TableCell> :
                <TableCell colSpan={2} className="user__button-row">
                <Button 
                variant="outlined" 
                className="user__edit-button"
                onClick={handleEditOpen}
                hidden={true}>
                Edit
                </Button> 
              </TableCell>}
              </TableRow>

            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>    
  </div>                              
    ) : (
      <div>{history.push("/signin")}</div>
    );
  };
  return <div>{renderProfile(userData)}</div>;
};

export default User;
