import React, { useState, useContext } from "react";
import { getUser } from "../../../helpers";
import { AlertContext } from "../../../Context/AlertContextProvider";
import { AppContext } from "../../../Context/AppContextProvide";

// Actions
import { getProductDetails } from "../../../Actions";

// Mui 
import { Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Checkbox, 
    FormControlLabel, 
    Divider, 
    ListItem,
    ListItemText, 
    ListItemIcon, 
    List, 
    ListItemSecondaryAction,
    ListSubheader,
    Button, 
    checkbox } from "@material-ui/core";

// Icons
import Collapse from '@material-ui/core/Collapse';
import StyleIcon from '@material-ui/icons/Style';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Styles
import "../../styles/RowMobile.css";


const RowMobile = (props) => {

    const { handleAlertOpen, setMessageType, setMessage } = useContext(
        AlertContext
      );
      const { roleId, roleName, rolePrefix} = useContext(AppContext);

    const { product, isItemSelected, handleClick, promoPriceStatus } = props;
    const [productInfo, setProductInfo] = useState([]);
    const [openList, setOpenList] = useState();
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [tempRPO] = useState({});
    const [user] = useState(getUser());
    const [alertOpen, setAlertOpen] = useState(false);
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [deleteProductUuid, setDeleteProductUuid] = useState("");
    const [selected, setSelected] = useState([]);
    const [ounceWeight, setOunceWeight] = useState(false);
    const [wearLayer, setWearLayer] = useState(false);
    const [thickness, setThickness] = useState(false);
    const [promoCost, setPromoCost] = useState(false);
    const [promoRetail, setPromoRetail] = useState(false);
    const today = new Date().toISOString().slice(0, 10);
    const [openSubList, setOpenSubList] = useState();
    const [openPromoSubList, setOpenPromoSubList] = useState(false);
    let costArray = [];
    let priceArray = [];

    const handleListClick = (index, roleId) => {
        openList === index ? setOpenList(undefined) : setOpenList(index);
        handleOpen(index, roleId);
      };

      const handleSubListClick = (index, sku, roleId) => {
        openSubList === sku ? setOpenSubList(undefined) : setOpenSubList(sku);
        handleOpen(index, roleId);
      };

      const handlePromoSubListClick = (index, sku, roleId) => {
        openPromoSubList === sku ? setOpenPromoSubList(undefined) : setOpenPromoSubList(sku);
        handleOpen(index, roleId);
      };

      const handleOpen = async (productId,roleId) => {
        if (!open) {
          try {
            const data = await getProductDetails(productId, roleId);
            setProductInfo(data.data.data[0].options);
            setPromoCost(false);
            setPromoRetail(false);
            data.data.data[0].options.forEach((result, index)=>{
              costArray.push(result.promotion.cost);
              priceArray.push(result.promotion.retail_price);
              
              if ((result.wear_layer !== "") && (result.wear_layer !== null) && (result.wear_layer !== undefined)) {
                setWearLayer(true);
              };
              if ((result.ounce_weight !== "") && (result.ounce_weight !== null) && (result.ounce_weight !== undefined)) {
                setOunceWeight(true);
              };
              if ((result.thickness !== "") && (result.thickness !== null) && (result.thickness !== undefined)) {
                setThickness(true);
              };
              if (costArray.some(c => c !== null)) {
                setPromoCost(true);
              };
              if (priceArray.some(p => p !== null)) {
                setPromoRetail(true);
              };
            })
          } catch (e) {
            setMessageType("error");
            setMessage("Error retrieving data");
            handleAlertOpen();
          }
        }
        setOpen(!open);
      };

          return (
            <div className="rowMobile-container">
                <List
                component="nav"
                aria-labelledby="nested-list-subheader">

                <List key={product.product_uuid}>
                  <ListItem button onClick={() => handleListClick(product.product_uuid, roleId)} className="rowMobilelist-menu">
                  <ListItemIcon>
                    <AccountTreeIcon />
                  </ListItemIcon>
                  <ListItemText primary={product.trade_name}/>
                  {/* <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      // onChange={handleToggle(value)}
                      // checked={checked.indexOf(value) !== -1}
                      // inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemSecondaryAction> */}
                  {openList === product.product_uuid ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {openList === product.product_uuid ? 
                  <Collapse in={true} timeout="auto" unmountOnExit>
                  <List style={{backgroundColor:"#FFF"}}>
                    
                  <div className="rowMobile-container-sublist">
                  <a href={`/product/${product.product_uuid}`} target="blank" style={{textDecoration: "none", color:"#000"}}>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon>
                        <VisibilityIcon style={{color:"#23B246", marginRight:35}}/>
                      </ListItemIcon>
                        <ListItemText primary="View" />
                    </ListItem>
                    </a>
                  </div>
                  <div className="rowMobile-container-sublist">
                    <ListItem alignItems="flex-start">
                      <Checkbox
                        onClick={(event) => handleClick(event, product.product_uuid)}
                        checked={isItemSelected}
                        className="row__checkbox"
                      />
                        <ListItemText primary="Add To Print Queue" />
                    </ListItem>
                  </div>
                  <Divider />
                  
                    {Object.keys(product).map((keyName, keyIndex) => {
                        {return keyName === "manufacturer_name" ||
                        keyName === "distributor_name" ||
                        keyName === "product_style" || 
                        keyName === "private_style_name" ||
                        keyName === "Material_class" || 
                        keyName === "material_sub_class" ||
                        keyName === "size_cd" ||
                        keyName === "backing_cd" || 
                        keyName === "material_class" ? (
                            <div key={product.product_uuid + '-' + keyName + '-' + keyIndex} className="rowMobile-container-sublist">
                                <ListItem>
                                  <ListItemIcon>
                                    <StyleIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={keyName} secondary={product[keyName]} />
                                </ListItem>
                            </div>
                        ) : (<></>) }
                    })}

                  <Divider />
                  <ListItem>
                    <List
                    component="nav"
                    aria-labelledby="nested-list-subheader">
                    {productInfo.length > 0 && productInfo.map((row, i) => {
                        return (
                            <List key={row.product_sku + '-' + row.color_name + '-' + row.basis_cd}>
                            <ListItem alignItems="flex-start" button onClick={() => handleSubListClick(product.product_uuid, row.product_sku, roleId)} className="rowMobile-sub-menu">
                            <ListItemIcon>
                              <AccountTreeIcon />
                            </ListItemIcon>
                              <ListItemText primary="More Info" secondary={row.retail_price !== null ?
                               `${row.color_name} - $${row.retail_price}` :
                               `${row.color_name} - $${row.promotion.retail_price}`}/>
                              {openSubList === row.product_sku ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            {openSubList === row.product_sku ? 
                            <Collapse in={true} timeout="auto" unmountOnExit>
                            <List className="rowMobile-sub-menu-items">
                                {Object.keys(row).map((prodKey, prodKeyI) => {                             
                                    // Promo sub list
                                   return prodKey === "promotion" ? (
                                    <div key={row.product_sku + '-' + row.color_name + '-promo-' + prodKeyI}>
                                     <Divider />
                                    <ListItem alignItems="flex-start">
                                      <List
                                      component="nav"
                                      aria-labelledby="nested-list-subheader">
                                              <List>
                                              <ListItem alignItems="flex-start" button onClick={() => handlePromoSubListClick(product.product_uuid, row.product_sku, roleId)}
                                               style={{minHeight:70, borderRadius:2, background:row.promotion.retail_price !== null ? "rgba(0, 154, 0, 0.898)" : "rgba(20, 46, 62, .5)"}}>
                                                <ListItemIcon>
                                                  <AccountTreeIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Promotion" secondary={prodKey === "promotion" ? `End Date ${row[prodKey].effective_end_date}` : "No Promotion"}/>
                                                {openPromoSubList === row.product_sku ? <ExpandLess /> : <ExpandMore />}
                                              </ListItem>
                                              {openPromoSubList === row.product_sku ? 
                                              <Collapse in={true} timeout="auto" unmountOnExit>
                                              <List className="rowMobile-sub-menu-items">
                                                  {Object.keys(row.promotion).map((skuKey, skuKeyI) => {
                                                      return (
                                                          <div className="rowMobile-container-sublist" key={row.product_sku + '-' + row.color_name + '-' + skuKey + '-' + skuKeyI}>
                                                              <ListItem alignItems="flex-start">
                                                                  <ListItemIcon>
                                                                      <StyleIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText
                                                                      primary={skuKey} 
                                                                      secondary={row.promotion[skuKey]}/>
                                                              </ListItem>
                                                              {/* <Divider /> */}
                                                          </div>
                                                      )
                                                  })}
                                                  
                                              </List>
                                              </Collapse> : <></>}
                                              </List>
                                              {/* <Divider /> */}
                                      </List>
                                    </ListItem>
                                    </div>
                                    // Promo sub list end
                                   ) :
                                    prodKey !== "role" &&
                                    prodKey !== "discontinued" &&
                                    prodKey !== "cost_id" &&
                                    prodKey !== "margin_id" &&
                                    prodKey !== "effective_start_date" &&
                                    prodKey !== "effective_end_date" && 
                                    prodKey !== "product_effective_end" &&
                                    prodKey !== "user_name" &&
                                    prodKey !== "comments" &&
                                    prodKey !== "distributor" &&
                                    prodKey !== "color" && (
                                        <div className="rowMobile-container-sublist" key={row.product_sku + '-' + row.color_name + '-non-promo-' + prodKeyI}>
                                            <ListItem alignItems="flex-start">
                                                {prodKey !== "role" &&
                                                    prodKey !== "discontinued" && (
                                                <ListItemIcon>
                                                    <StyleIcon />
                                                </ListItemIcon>)}
                                                <ListItemText
                                                    primary={prodKey} 
                                                    secondary={
                                                    prodKey !== "promotion" && 
                                                    prodKey !== "role" &&
                                                    prodKey !== "discontinued" ? row[prodKey] : ""
                                                    // prodKey === "promotion" ? `Cost:${row[prodKey].cost} Price:${row[prodKey].retail_price}` : ""
                                                    }/>
                                                    
                                            </ListItem>
                                        </div>
                                    )
                                })}
                                
                            </List>
                            </Collapse> : <></>}
                            </List>                           
                        )})}
                    </List>
                  </ListItem>
                  </List>
              </Collapse> :
              <></>}
              </List>
          </List>
          </div>
          );       
};

export default RowMobile;