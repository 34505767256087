import React, { useState, createContext, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../Contants";
import { AppContext } from "./AppContextProvide";
import { HubSpokeContext } from "./HubSpokeContextProvider";

export const ProductStatsContext = createContext();

export const ProductStatsContextProvider = ({ children }) => {
  const { user } = useContext(AppContext);
  const { selectedSpoke } = useContext(HubSpokeContext);
  const [productStats, setProductStats] = useState([]);
  const [days, setDays] = useState(30);
  const [productLikes, setProductLikes] = useState([]);
  const [productCheckouts, setProductCheckouts] = useState([]);
  const [productStatCards, setProductStatCards] = useState([]);

  const isLoggedIn = user.user_id;

  useEffect(() => {
    async function getProductLikes() {
      const result = await axios(`${baseUrl}/api/stats/products/likes/?siteName=${selectedSpoke}`);
      setProductLikes(result.data.data);
    }

    async function getProductCheckouts() {
      const result = await axios(`${baseUrl}/api/stats/products/checkouts?siteName=${selectedSpoke}`);
      setProductCheckouts(result.data.data);
    }

    async function getProductsStats() {
      const result = await axios(`${baseUrl}/api/stats/products?days=${days}&siteName=${selectedSpoke}`);
      setProductStats(result.data.data);
    }
    
    if (isLoggedIn) {
      getProductsStats();
      getProductLikes();
      getProductCheckouts();
    }
  }, [isLoggedIn, days, selectedSpoke]);

  return (
    <ProductStatsContext.Provider value={{ productStats, productLikes, productCheckouts, days, setDays }}>
      {children}
    </ProductStatsContext.Provider>
  );
};
