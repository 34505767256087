import React, { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../Contants";
import { useHistory, useLocation, withRouter, Link } from "react-router-dom";

// MUI
import { InputAdornment, 
OutlinedInput,
IconButton, 
Button, 
Typography } from "@material-ui/core";

// Context
import { AppContext } from "../../Context/AppContextProvide";
import { AlertContext } from "../../Context/AlertContextProvider";
import { CompanyLogoContext } from "../../Context/CompanyLogoContextProvider";

// CSS
import "../styles/Signin.css";
import { login, getCustomerCreateAccountStatus } from "../../Actions";

// Icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState("password");
  const { setAuthType, setAccessToken } = useContext(AppContext);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );
  const { companyLogo } = useContext(CompanyLogoContext);
  const history = useHistory();
  const location = useLocation();
  const expTime = new Date().getTime() + (36000000);
  const [newUserAccountRegStatus, setNewUserAccountRegStatus] = useState(0);

  useEffect(() => {
		getCustomerCreateAccountStatus()
		.then((result) => {
			setNewUserAccountRegStatus(Number(result.data.data[0].app_value));
		})
	})

  const handleUsernameChange = (e) => setUsername(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleKeyPress = (target) => {
    if (target.charCode === 13 && !isDisabled()) {
      onSubmit();
    };
  }

  const onSubmit = () => {
    // let now = new Date();
    login(username, password)
      .then((result) => {
        setAuthType("local");
        setAccessToken(result.data.token);
        localStorage.setItem("accessToken", result.data.token);
        localStorage.setItem("authType", "local");
        localStorage.setItem("tokenExpiration", expTime);
        localStorage.setItem("sessionTime", expTime); 
        setTimeout(() => {
          // location.state && location.state.from
          //   ? history.push(location.state.from.pathname)
          //   : 
            history.push("/home");
        }, 500);
      })
      .catch((err) => {
        setMessageType("error");
        setMessage("Invalid username/password");
        handleAlertOpen(true);
      });
  };

  const isDisabled = () => {
    return !(username.length && password.length);
  };

  const handleClickShowPassword = () => {
    showPass === "password" ? setShowPass("text") : setShowPass("password");
  };

  return (
    <div className="signin__container">
      <div className="signin__local" onKeyPress={handleKeyPress}>
        <div className="signin__container-header">
        <Typography variant="h2" align="center" className="signin__header-text">
          Login
        </Typography>
        </div>
        <label 
          className="register__label"
          htmlFor="confirm_pwd">
          User Name:
        </label>
        <OutlinedInput
          value={username}
          onChange={handleUsernameChange}
          className="signin__username"
          variant="outlined"
          type="text"
        />
         <label 
          className="register__label"
          htmlFor="confirm_pwd">
          Password:
        </label>
        <OutlinedInput
          value={password}
          onChange={handlePasswordChange}
          className="signin__password"
          label="password"
          type={showPass}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
              onClick={handleClickShowPassword}
              >
              {showPass === "text" ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
            }
        />
        <div><hr /></div>
        <div className="signin_local-btn-container">
          <Button
            disabled={isDisabled()}
            onClick={onSubmit}
            className="sigin__local-btn"
            variant="contained"
          >
            Login
          </Button>
        </div>
        <div className="signin__google">
        <div className="signin__google-btn">
          <button className="loginBtn loginBtn--google">
            <a className="login__google" href={`${baseUrl}/api/auth/google`}>
              Login with Google
            </a>
          </button>
        </div>
        {/* <Divider variant="middle" /> */}
      </div>
        <div style={{textAlign:"center", textDecoration:"none", marginBottom:25}}>
          {newUserAccountRegStatus === 1 ? (
          <span>
          <Link to="/forgotpass" style={{textDecoration:"none", color:"#4E564E", fontSize:15}}>Forgot Password?</Link>
          {"  |  "}
          <Link to="/register" style={{textDecoration:"none", color:"#4E564E", fontSize:15}}>New User</Link>
          </span>
          ) : <Link to="/forgotpass" style={{textDecoration:"none", color:"#4E564E", fontSize:15}}>Forgot Password?</Link>}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
