import React, { useContext, useState } from "react";

// MUI
import { Tooltip, 
Switch,
Dialog,
Button,
DialogActions,
DialogTitle,
DialogContent,
TextField,
FormControl,
Grid,
FormLabel
 } from "@material-ui/core";

// actions
import { createRole, setSampleAutoCheckoutStatus, editRolePrefix } from "../../../Actions";

// Icon
// import AddIcon from "@material-ui/icons/Add";

// styles
import "../../styles/Admin.css";

// dependents
import { AdminContext } from "../../../Context/AdminContextProvider";
import { AlertContext } from "../../../Context/AlertContextProvider";
import { AppContext } from "../../../Context/AppContextProvide";

// component
const CreateRole = (props) => {
  const [open, setOpen] = useState(props.open);
  const [selectedValue] = useState("local");
  const [roleName, setRole] = useState("");
  const [roleDescription, setRoleDescription] =  useState("");
  const { setUpdate } = useContext(AdminContext);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );
  const { setRolePrefix } = useContext(AppContext);
  const switchToolTip = 'Toggle to show role prefix before price tag on the product page. (E.g "Retail" - Price)'
  const checkoutSwitchToolTip = 'Allow this role to checkout samples without requesting permission';
  const reminderSwitchToolTip = `Allow this role to receive reminders about samples they have checked out`;
  const [switchState, setSwitchState] = useState(false);
  const [checkoutSwitchState, setCheckoutSwitchState] = useState(false);
  const [reminderSwitchState, setReminderSwitchState] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetvars = () => {
    setRole("");
    setRoleDescription("");
  };

  const handleClose = () => {
    setOpen(false);
    resetvars();
  };

  const handleSubmit = async (type) => {
    if (type === "local") {
        await createRole(roleName, roleDescription, switchState, checkoutSwitchState, reminderSwitchState)
        .then((res) => {
            const message = res.data.message;
            setMessageType("success");
            setMessage(message);
            setUpdate(true);
            handleAlertOpen();
            // need to return role_id from created role or do insert on server side
            // if (switchState === true) {
            //   setRolePrefix('1');
            //   editRolePrefix(props.role.role_id, '1');
            // } else if (switchState === false) {
            //   setRolePrefix('0');
            //   editRolePrefix(props.role.role_id, '0');
            // };
        
            // if (checkoutSwitchState === true) {
            //   setSampleAutoCheckoutStatus(props.role.role_id, '1');
            // } else if (switchState === false) {
            //   setSampleAutoCheckoutStatus(props.role.role_id, '0');
            // };
        })
        .catch((err) => {
            const message = "User Role Already Exists!";
            setMessageType("error");
            setMessage(message);
            handleAlertOpen();
        });
    }
  };

  const canCreateRole = () => {
    if (roleName.length && roleDescription.length) {
      return false;
    }
    return true;
  };

  const renderRoleLabel = () => {
    return (
    <div>User Role:</div>
    )};

  const renderAddRole = () => {
    return (
    <div>
        <TextField 
        label="Role"
        fullWidth
        variant="outlined"
        required
        type="text"
        name="role"
        value={roleName}
        onChange={(e) => setRole(e.target.value)}
        >
        </TextField>
        </div>
        )};

  const renderRoleDescLabel = () => {
    return (
    <div>Role Description:</div>
    )};
  
  const renderAddRoleDesc = () => {
    return (
    <div>
        <TextField 
        variant="outlined"
        label="Role Description"
        fullWidth
        required
        type="text"
        multiline={true}
        name="role description"
        value={roleDescription}
        onChange={(e) => setRoleDescription(e.target.value)}
        >
        </TextField>
        </div>
    )};

  const renderForm = () => {
    return  (
      <form>
        <table>
          <tbody>
            <tr className="admin__create-user-table-row">
              {/* <td>{renderRoleLabel()}</td> */}
              <td className="admin__create-user-table-divider">{renderAddRole()}</td>
            </tr>
            <tr className="admin__create-user-table-row">
              {/* <td>{renderRoleDescLabel()}</td> */}
              <td className="admin__create-user-table-divider">{renderAddRoleDesc()}</td>
            </tr>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-divider">
                <FormControl>
                  <FormLabel>Enable Price Label Role Prefix?</FormLabel>
                  <Grid className="mnd__tax-switch" component="label" container spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Tooltip arrow={true} title={switchToolTip} >
                        <Switch
                          checked={switchState}
                          onChange={(e) => setSwitchState(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </FormControl>
              </td>
            </tr>
            <tr>
              <td className="admin__create-user-table-divider">
                <FormControl>
                  <FormLabel>Enable Auto Sample Checkouts?</FormLabel>
                  <Grid className="mnd__tax-switch" component="label" container spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Tooltip arrow={true} title={checkoutSwitchToolTip} >
                        <Switch
                          checked={checkoutSwitchState}
                          onChange={(e) => setCheckoutSwitchState(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </FormControl>
              </td>
            </tr>
            <tr>
              <td className="admin__create-user-table-divider">
                <FormControl>
                  <FormLabel>Enable Sample Reminders?</FormLabel>
                  <Grid className="mnd__tax-switch" component="label" container spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Tooltip arrow={true} title={reminderSwitchToolTip} >
                        <Switch
                          checked={reminderSwitchState}
                          onChange={(e) => setReminderSwitchState(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </FormControl>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  };

  return (
    <div>
       <Button
         onClick={handleOpen} 
         className="user__pw-change-button"
         variant="contained"
         >
        Add Role
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          className="Admin__create-user-header"
          id="alert-dialog-title"
        >
          {"Create Role"}
        </DialogTitle>
        <DialogContent dividers>
          {/* <Paper className="Admin_create-user-form"> */}

          <FormControl component="fieldset">
          </FormControl>
          {renderForm()}
          {/* </Paper> */}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            autoFocus
            variant="contained"
            >
            Cancel
          </Button>
          <Button
            onClick={(e) => handleSubmit(selectedValue)}
            className="Admin__create-user"
            variant="contained"
            autoFocus
            style={{marginLeft: 20}}
            disabled={canCreateRole()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateRole;