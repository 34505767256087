import React, { useContext, useEffect, useState } from "react";

// MUI
import { InputAdornment,
IconButton, 
DialogActions,
DialogContent,
DialogContentText,
Dialog,
DialogTitle,
Typography,
OutlinedInput,
Button  } from "@material-ui/core";

// styles
import "../styles/Signin.css";

// Icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';

// Actions
import { resetPassword } from "../../Actions";

// dependents
import { Link, useHistory } from "react-router-dom";
import { AlertContext } from "../../Context/AlertContextProvider";

// component
const ResetPass = () => {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const resetToken = window.location.href.split("/")[4];
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [showPass, setShowPass] = useState("password");
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const [validMatch, setValidMatch] = useState(false);
  const [validPassword, setValidPasswordd] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  useEffect(() => {
		setValidPasswordd(PWD_REGEX.test(newPassword));
		setValidMatch(newPassword === confirmPassword);
	}, [newPassword, confirmPassword]);


  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePass = () => {
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleNewUsername = (event) => {
    setUsername(event.target.value);
  }

  const handleNewPassword = (event) => {
    const value = event.target.value;
    setNewPassword(value);
  };

  const handleConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
  };

  const isValidPassword = () => {
    if (newPassword !== confirmPassword) return true;
    else return false;
  };

  const isValidNewPassword = () => {
    if (newPassword.length < 8) return true;
    else return false;
  };

  const isDisabled = () => {
    if (newPassword.length < 8) {
      return true;
    } else if (confirmPassword !== newPassword) {
      return true;
    }  else if (!username) {
        return true;
    } else if (newPassword.length > 8 && confirmPassword === newPassword) {
      return false;
    }
  };

  const handleClickShowPassword = () => {
    showPass === "password" ? setShowPass("text") : setShowPass("password");
  };

  const handleKeyPress = (target) => {
    if (target.charCode === 13 && !isDisabled()) {
      handleSubmit();
    };
  }

  const handleSubmit = () => {
    resetPassword(resetToken, username, newPassword, confirmPassword)
      .then((result) => {
        setMessageType("success");
        setMessage("Password Changed!!");
        handleAlertOpen(true);
        handleChangePass();
        history.push("/signin");
      })
      .catch((err) => {
        setMessageType("error");
        setMessage("Error changing password");
        handleAlertOpen(true);
        setOpen(true);
      });
  };

  const renderError = () => {
    return (
      <div>
         <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Password Reset
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6">
            There was an error resetting your password. Please try again.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/forgotpass">
            <Button onClick={handleClose} style={{color:"#FF682C", width:150, backgroundColor:"#f4f9e9"}} variant="contained">
              Ok
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
      </div>
    )
  };

  
  return (
    <div className="signin__container">
      <div className="signin__google">
        <div className="signin__google-btn">
          {/*<button className="loginBtn loginBtn--google">
            <a className="login__google" href={`${baseUrl}/api/auth/google`}>
              <a className="login__google" href={`/api/auth/google`}> 
              Login with Google
            </a>
          </button>*/}
        </div>
        {/* <Divider variant="middle" /> */}
      </div>

      <div className="signin__local" onKeyPress={handleKeyPress}>
        <div className="signin__container-header">
        <Typography variant="h2" align="center" className="signin__header-text">
          New Password
        </Typography>
        </div>
        <label 
          className="register__label"
          htmlFor="confirm_pwd">
          User Name:
        </label>
        <OutlinedInput
          value={username}
          onChange={handleNewUsername}
          placeholder="Username"
          variant="filled"
          type="text"
          className="signin__username"
        />
         <label 
          className="register__label"
          htmlFor="confirm_pwd">
          Password:
        </label>
        <OutlinedInput
          error={isValidNewPassword()}
          label="Password"
          value={newPassword}
          onChange={handleNewPassword}
          placeholder="Password"
          variant="filled"
          type={showPass}
          aria-invalid={validPassword ? 'false' : 'true'}
          onFocus={() => setPasswordFocus(true)}
							onBlur={() => setPasswordFocus(false)}
          helperText={
            isValidNewPassword()
              ? "password must be at least 8 letters long"
              : null
          }
          className="signin__password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
              onClick={handleClickShowPassword}
              >
              {showPass === "text" ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              {validPassword ? <CheckIcon className="valid"/> : <ClearIcon className="invalid"/>}
            </InputAdornment>
            }
        />
        <p
            id="pwdnote"
            className={passwordFocus && !validPassword ? 'instructions' : 'hide'}
          >
            8 to 24 characters.
            <br />
            Must include uppercase and lowercase letters, a number and a
            special character.
            <br />
            Allowed special characters:{' '}
            <span aria-label="exclamation mark">!</span>{' '}
            <span aria-label="at symbol">@</span>{' '}
            <span aria-label="hashtag">#</span>{' '}
            <span aria-label="dollar sign">$</span>{' '}
            <span aria-label="percent">%</span>
          </p>
        <label 
          className="register__label"
          htmlFor="confirm_pwd">
          Confirm Password:
        </label>
        <OutlinedInput
          error={isValidPassword()}
          label="Confirm password"
          value={confirmPassword}
          onChange={handleConfirmPassword}
          placeholder="Confirm Password"
          variant="filled"
          type={showPass}
          aria-invalid={validMatch ? 'false' : 'true'}
          helperText={isValidPassword() ? "passwords do not match" : null}
          className="signin__password"
          onFocus={() => setMatchFocus(true)}
					onBlur={() => setMatchFocus(false)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
              onClick={handleClickShowPassword}
              >
              {showPass === "text" ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              {validMatch && confirmPassword ? <CheckIcon className="valid"/> : <ClearIcon className="invalid"/>}
            </InputAdornment>
            }
        />
        <p
            id="pwdnote"
            className={matchFocus && !validMatch ? 'instructions' : 'hide'}
          >
            8 to 24 characters.
            <br />
            Must include uppercase and lowercase letters, a number and a
            special character.
            <br />
            Allowed special characters:{' '}
            <span aria-label="exclamation mark">!</span>{' '}
            <span aria-label="at symbol">@</span>{' '}
            <span aria-label="hashtag">#</span>{' '}
            <span aria-label="dollar sign">$</span>{' '}
            <span aria-label="percent">%</span>
          </p>
        <div><hr /></div>
        <div className="signin_local-btn-container">
       <Button
          onClick={handleSubmit}
          style={{float:"right", marginLeft:20, marginTop:55, color:"#09962a", backgroundColor:"#f4f9e9"}}
          variant="contained"
          autoFocus
          disabled={isDisabled()}
        >
          Confirm
        </Button>
        <Button 
          onClick={handleChangePass}  
          autoFocus
          variant="contained"
          style={{float:"right", marginLeft:20, marginTop:55, color:"#FF0000", backgroundColor:"#f4f9e9"}}
          >
          Cancel
        </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;