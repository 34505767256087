import React, { useState} from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

// styles
import "../styles/Admin.css";

// denpendents
// import { getDataExportList } from "../../Actions";
import Upload from "./Uploads";
import Export from "./Export";

const ImportExport = () => {
  const [value, setValue] = useState("Uploads");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="admin__container">
      <Paper className="admin__tab-controller">
        {/* Full screen menu */}
        <Tabs
          TabIndicatorProps={{style: {backgroundColor: "#FF682C"}}}
          className="admin__full-screen-tabs"
          value={value}
          onChange={handleChange}

          centered
        >
          <Tab className="admin__tabs" value="Uploads" label="Price List Import"/>
          <Tab className="admin__tabs" value="FileExport" label="File Export"/>
        </Tabs>
        {/* Mobile menu */}
        <Tabs
          TabIndicatorProps={{style: {backgroundColor: "#FF682C"}}}
          className="admin__mobile-tabs"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          orientation="vertical"
        >
          <Tab className="admin__mobile-tabs" value="Uploads" label="Price List Upload"/>
          <Tab className="admin__mobile-tabs" value="FileExport" label="File Export"/>
        </Tabs>
      </Paper>
      <TabPanel value={value} index="Uploads">
        <Upload  />
      </TabPanel>
      <TabPanel value={value} index="FileExport">
        <Export/>
      </TabPanel>
    </div>
  );
};

export default ImportExport;