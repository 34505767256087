import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// components
import Product from "./Components/Product/Product";
import Header from "./Components/Layout/Header";
import Auth from "./Components/Auth";
import Products from "./Components/Product/Products";

// Auth route

import AuthRoute from "./Util/AuthRoute";
import AdminAuthRoute from "./Util/AdminAuthRoute";
import CombinedAuthRoute from "./Util/CombinedAuthRoute";
import RegistrationAuthRoute from "./Util/RegistrationAuthRoute";

// styles
import "./App.css";

// context
import { ProductContextProvider } from "./Context/ProductContext";
import { AppContextProvider } from "./Context/AppContextProvide";
import { ProductsViewedContextProvider } from "./Context/ProductsViewedContextProvider";
import { AdminTaxContextProvider } from "./Context/AdminTaxContextProvider";
import { AdminCompanyMarginContextProvider } from "./Context/AdminCompanyMarginContextProvider";
import { BulletinContextProvider } from "./Context/BulletinContextProvider";
import { AdminContextProvider } from "./Context/AdminContextProvider";
import { AnnouncementsContextProvider } from "./Context/AnnouncementsContextProvider";
import { AlertProvider } from "./Context/AlertContextProvider";
import { UserAnnouncementsContextProvider } from "./Context/UserAnnouncementsContextProvider";
import { ProductStatsContextProvider } from "./Context/ProductsStatsContextProvider";
import { CompanyStatsContextProvider } from "./Context/CompaniesStatsContextProvider";
import { UserBulletinContextProvider } from "./Context/UserBulletinContextProvider";
import { PrintQueueContextProvider } from "./Context/PrintQueueContextProvider";
import { AdminLoadedMarginContextProvider } from "./Context/AdminLoadedMarginContextProvider";
import { AdminMaterialClassMarginContextProvider } from "./Context/AdminMaterialClassMarginContextProvider";
import { ProductDisclaimerContextProvider } from "./Context/ProductDisclaimerContextProvider";
import { TaxDisclaimerContextProvider } from "./Context/TaxDisclaimerContextProvider";
import { ProductsWithSearchContextProvider } from "./Context/ProductsWithSearchContextProvider";
import { CompanyLogoContextProvider } from "./Context/CompanyLogoContextProvider";
import { LookBackContextProvider } from "./Context/LookBackContextProvider";
import { HubSpokeContextProvider } from "./Context/HubSpokeContextProvider";

// axios config
// eslint-disable-next-line no-unused-vars
import axiosConfig from "./Util/axiosConfig";
import Notfound from "./Components/Notfound";
import Home from "./Components/LandingPage/Home";
import Admin from "./Components/Admin/Admin";
import Stats from "./Components/LandingPage/Stats/Stats";
import User from "./Components/User/User";
import Signin from "./Components/LandingPage/Signin";
import ImportExport from "./Components/ImportExport/index";
import Settings from "./Components/Settings/Settings";
import FileHistory from "./Components/LandingPage/FileHistory/FileHistory";
import About from "./Components/About/About";
import Register from "./Components/User/Register";
import ConfirmUser from "./Components/User/ConfirmUser";
import ForgotPass from "./Components/LandingPage/ForgotPass";
import ResetPass from "./Components/LandingPage/ResetPass";
import CustomerHome from "./Components/Customer/CustomerHome";
import ManageSamples from "./Components/Samples/ManageSamples";
import { ProgressProvider } from "./Context/ProgressContextProvider";
import LeadManagement from "./Components/LeadManagement/LeadManagement";

const App = () => {
  // const { user } = React.useContext(AppContext);
  return (
    <div className="App">
      <AlertProvider>
        <ProgressProvider>
          <Router>
            <AppContextProvider>
              <CompanyLogoContextProvider>
                <Header />
              </CompanyLogoContextProvider>
              <div className="app-content">
                <Switch>
                  <Route component={() => <Auth />} path="/auth" />
                  {/*  */}
                  <Route component={() => <CompanyLogoContextProvider><Signin /></CompanyLogoContextProvider>} path="/signin" />
                  {/*  */}
                  <Route component={() => <CompanyLogoContextProvider><RegistrationAuthRoute><Register /></RegistrationAuthRoute></CompanyLogoContextProvider>} path="/register" />
                  {/*  */}
                  <Route component={() => <CompanyLogoContextProvider><ConfirmUser /></CompanyLogoContextProvider>} path="/confirm-user" />
                  {/*  */}
                  <Route component={() => <ForgotPass />} path="/forgotpass" />
                  <Route component={() => <ResetPass />} path="/resetpass" />
                  <AuthRoute
                    path="/home"
                    exact
                    component={() => (
                      <HubSpokeContextProvider>
                        <LookBackContextProvider>
                          <CompanyStatsContextProvider>
                            <ProductStatsContextProvider>
                              <UserAnnouncementsContextProvider>
                                <UserBulletinContextProvider>
                                  <Home />
                                </UserBulletinContextProvider>
                              </UserAnnouncementsContextProvider>
                            </ProductStatsContextProvider>
                          </CompanyStatsContextProvider>
                        </LookBackContextProvider>
                      </HubSpokeContextProvider>
                    )}
                  />
                  
                  <Route
                    path="/user"
                    exact
                    component={() => <User />}
                  ></Route>

                  <AuthRoute
                    path="/lead-management"
                    exact
                    component={() => <LeadManagement />}
                  ></AuthRoute>

                  <AdminAuthRoute
                    path="/filehistory"
                    exact
                    component={() => (
                      <FileHistory />
                    )}
                  />

                  <AuthRoute
                    path="/stats"
                    exact
                    component={() => (
                      <HubSpokeContextProvider>
                        <ProductsViewedContextProvider>
                          <CompanyStatsContextProvider>
                            <ProductStatsContextProvider>
                              <Stats />
                            </ProductStatsContextProvider>
                          </CompanyStatsContextProvider>
                        </ProductsViewedContextProvider>
                      </HubSpokeContextProvider>
                    )}
                  />

                  <AdminAuthRoute
                    path="/admin"
                    exact
                    component={() => {
                      return (
                        <BulletinContextProvider>
                          <AdminContextProvider>
                            <AnnouncementsContextProvider>
                              <AdminTaxContextProvider>
                                <AdminCompanyMarginContextProvider>
                                  <AdminLoadedMarginContextProvider>
                                    <AdminMaterialClassMarginContextProvider>
                                      <CompanyLogoContextProvider>
                                        <ProductDisclaimerContextProvider>
                                          <TaxDisclaimerContextProvider>
                                            <Admin />
                                          </TaxDisclaimerContextProvider>
                                        </ProductDisclaimerContextProvider>
                                      </CompanyLogoContextProvider>
                                    </AdminMaterialClassMarginContextProvider>
                                  </AdminLoadedMarginContextProvider>
                                </AdminCompanyMarginContextProvider>
                              </AdminTaxContextProvider>
                            </AnnouncementsContextProvider>
                          </AdminContextProvider>
                        </BulletinContextProvider>
                      );
                    }}
                  />

                  <AuthRoute
                    component={() => {
                      return (
                        <ProductsWithSearchContextProvider>
                          <PrintQueueContextProvider>
                            <ProductContextProvider>
                              <Products />
                            </ProductContextProvider>
                          </PrintQueueContextProvider>
                        </ProductsWithSearchContextProvider>
                      );
                    }}
                    path="/products"
                    exact
                  />

                  <AdminAuthRoute
                    path="/importexport"
                    exact
                    component={() => {
                      return (
                        <AdminContextProvider>
                          <HubSpokeContextProvider>
                            <ImportExport />
                          </HubSpokeContextProvider>
                        </AdminContextProvider>
                      );
                    }}
                  />  

                  <AdminAuthRoute
                    path="/settings"
                    exact
                    component={() => {
                      return (
                        <CompanyLogoContextProvider>
                          <Settings />
                        </CompanyLogoContextProvider>
                      );
                    }}
                  />

                  <AuthRoute
                    path="/about"
                    exact
                    component={() => {
                      return (
                          <CompanyLogoContextProvider>
                            <About />
                          </CompanyLogoContextProvider>
                      );
                    }}
                  />  

                  <CombinedAuthRoute 
                    path="/customer-home"
                    exact
                    component={() => {
                      return (
                      <CustomerHome />
                      )
                    }}
                  />

                  <AuthRoute
                  path="/manage-samples"
                  exact
                  component={() => {
                    return (
                      <ManageSamples />
                    )
                  }}
                  />

                  <Route
                    component={() => {
                      return (
                        <PrintQueueContextProvider>
                          <ProductContextProvider>
                            <HubSpokeContextProvider>
                              <ProductStatsContextProvider>
                                <Product />
                              </ProductStatsContextProvider>
                            </HubSpokeContextProvider>
                          </ProductContextProvider>
                        </PrintQueueContextProvider>
                      );
                    }}
                    path="/product/:productId"
                  />
                  <Route path="*" exact component={Notfound}></Route>
                </Switch>
              </div>
            </AppContextProvider>
          </Router>
        </ProgressProvider>
      </AlertProvider>
    </div>
  );
};

export default App;
