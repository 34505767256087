import React, { useState, useEffect, useContext } from "react";

//MUI
import {
  Paper,
  Button,
  Grid,
  NativeSelect,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Card
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import GetAppIcon from "@material-ui/icons/GetApp";
import Autocomplete from "@material-ui/lab/Autocomplete";


// Style
import "../styles/Uploads.css";

// Actions
import {
  getDataExportPreview,
  getManufacturers,
  getDistributors,
  getMaterials,
  getRoles,
  getDataExportList
} from "../../Actions";

// Context
import { ProgressContext } from "../../Context/ProgressContextProvider";

const FileExport = () => {
  const XLSX = require('xlsx');
  const [uploadStatus, setUploadStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [header, setHeader] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedMan, setSelectedMan] = useState([]);
  const [selectedMat, setSelectedMat] = useState([]);
  const [selectedDist, setSelectedDist] = useState([]);
  const [selectedStyle] = useState([]);
  const [formatName, setFormatName] = useState("");
  const [manufacturerList, setManufacturerList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [distributorList, setDistributorList] = useState([]);
  // const [styleList, setStyleList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [manDisabled, setManDisabled] = useState(false);
  const [matDisabled, setMatDisabled] = useState(false);
  const [distDisabled, setDistDisabled] = useState(false);
  // const [styleDisabled, setStyleDisabled] = useState(false);
  const [autoManCompleteLabel, setManAutoCompleteLabel] = useState('Manufacturer');
  const [autoMatCompleteLabel, setMatAutoCompleteLabel] = useState('Material Class');
  const [autoDistCompleteLabel, setDistAutoCompleteLabel] = useState('Distributor');
  // const [autoStyleCompleteLabel, setStyleAutoCompleteLabel] = useState('Style');
  var today = new Date().toISOString().replace(/T/, " ").replace(/\..+/, "");
  const { handleProgressOpen, handleProgressClose } = useContext(ProgressContext);

  useEffect(
    () => {

      getDistributors()
      .then((result) => {
        setDistributorList(result.data.data);
      })
      const eList = new Promise(resolve => {
        resolve(getDataExportList());
      });
      eList.then(list => {
        setExportList(list.data.result);
      });

      const rList = new Promise(resolve => {
        resolve(getRoles());
      });
      rList.then(r => {
        setRoles(r.data.roleList);
      });

      const manList = new Promise(resolve => {
        resolve(getManufacturers());
      });
      manList.then(m => {
        setManufacturerList(m.data.data);
      });

      const matList = new Promise(resolve => {
        resolve(getMaterials());
      });
      matList.then(material => {
        setMaterialList(material.data.data);
      });

      // const styleList = new Promise(resolve => {
      //   resolve(getStyles());
      // });
      // styleList.then(style => {
      //   setStyleList(style.data.data);
      // });
    },
    [setSelectedFormat]
  );

  const handleRoleSelectChange = event => {
    setItems([]);
    setHeader([]);
    setButtonVisible(false);
    setSelectedRole(event.target.value);
    setRoleName(JSON.parse(event.target.value).role_name);
  };

  const handleOutputSelectChange = event => {
    setItems([]);
    setHeader([]);
    setButtonVisible(false);
    setSelectedFormat(JSON.parse(event.target.value).data_export_id);
    setFormatName(JSON.parse(event.target.value).name);
  };

  const handleManufacturerSelectChange = (event, value) => {
    setItems([]);
    setHeader([]);
    setButtonVisible(false);
    setSelectedMan(value);
  };

  const handleMaterialSelectChange = (event, value) => {
    setItems([]);
    setHeader([]);
    setButtonVisible(false);
    setSelectedMat(value);
  };

  const handleDistributorSelectChange = (event, value) => {
    setItems([]);
    setHeader([]);
    setButtonVisible(false);
    setSelectedDist(value);
  };

  // const handleStyleSelectChange = (event, value) => {
  //   setItems([]);
  //   setHeader([]);
  //   setButtonVisible(false);
  //   setSelectedStyle(value);
  // };

  const exportDisclaimer = result => {
    const noFormat = "Please select the output format.";
    const noRole = "Please select a role.";
    const noMatch = "No results matching your search criteria.";
    // const noMan = "Please select at least one manufacturer.";
    if (selectedFormat.length === 0) {
      setUploadStatus(noFormat);
      setOpen(true);
    } else if (selectedRole.length === 0) {
      setUploadStatus(noRole);
      setOpen(true);
    // } else if (selectedMan.length === 0) {
    //   setUploadStatus(noMan);
    //   setOpen(true);
    } else if (result.length === 0) {
      setUploadStatus(noMatch);
      setOpen(true);
    }
  };

  const handlePreview = () => {
    if (selectedRole.length === 0 || selectedFormat.length === 0) {
      exportDisclaimer();
    } else {
      handleProgressOpen();
      // setShowProgress(true);
      getDataExportPreview(
        selectedFormat,
        "preview",
        selectedRole,
        JSON.stringify(selectedMan),
        JSON.stringify(selectedMat),
        JSON.stringify(selectedStyle),
        JSON.stringify(selectedDist)
      )
        .then(result => {
          if (result.statusText === "OK") {
            if (result.data.result.length === 0) {
              exportDisclaimer(result.data.result);
              handleProgressClose();
              // setShowProgress(false);
            } else {
              result.data.result.forEach(resultValues => {
                items.push(Object.values(resultValues));
              });
              setHeader(Object.keys(result.data.result[0]));
              // setShowProgress(false);
              handleProgressClose();
              setButtonVisible(true);
            }
          }
        })
        .catch(err => {
          console.log(err);
          // setShowProgress(false);
          handleProgressClose();
          setButtonVisible(false);
        });
    }
  };

  const handleExportExcel = () => {
    handleProgressOpen();
    // setShowProgress(true);
    getDataExportPreview(
      selectedFormat,
      "download",
      selectedRole,
      JSON.stringify(selectedMan),
      JSON.stringify(selectedMat),
      JSON.stringify(selectedStyle),
      JSON.stringify(selectedDist)
    )
      .then(result => {
        if (result.statusText === "OK") {
          // setShowProgress(false);
          handleProgressClose();
          handleXLSX(result.data.result);
          setHeader([]);
          setItems([]);
          setButtonVisible(false);
        }
      })
      .catch(err => {
        console.log(err);
        // setShowProgress(false);
        handleProgressClose();
        setButtonVisible(false);
      });
  };

  const handleExportCsv = () => {
    // setShowProgress(true);
    handleProgressOpen();
    getDataExportPreview(
      selectedFormat,
      "download",
      selectedRole,
      JSON.stringify(selectedMan),
      JSON.stringify(selectedMat),
      JSON.stringify(selectedStyle),
      JSON.stringify(selectedDist)
    )
      .then(result => {
        if (result.statusText === "OK") {
          // setShowProgress(false);
          handleProgressClose();
          handleCSV(result.data.result);
          setHeader([]);
          setItems([]);
          setButtonVisible(false);
        }
      })
      .catch(err => {
        console.log(err);
        // setShowProgress(false);
        handleProgressClose();
        setButtonVisible(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleXLSX = headers => {
    // var temp =
    //   "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
    //   encodeURIComponent(headers);

      var wb = XLSX.utils.book_new();
      var sheetOne = XLSX.utils.json_to_sheet(headers);
      XLSX.utils.book_append_sheet(wb, sheetOne, "Price List Export");
      XLSX.writeFile(wb, `${roleName}-${formatName.toLocaleLowerCase()}-${today}.xlsx`);
    var download = document.createElement("a");
    //download.href = temp;
    download.download = `${roleName}-${formatName.toLocaleLowerCase()}-${today}.xlsx`;
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
  };

  const handleCSV = headers => {
    // var temp =
    //   "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
    //   encodeURIComponent(headers);

      var wb = XLSX.utils.book_new();
      var sheetOne = XLSX.utils.json_to_sheet(headers);
      XLSX.utils.book_append_sheet(wb, sheetOne, "Price List Export");
      XLSX.writeFile(wb, `${roleName}-${formatName.toLocaleLowerCase()}-${today}.csv`);
    var download = document.createElement("a");
    //download.href = temp;
    download.download = `${roleName}-${formatName.toLocaleLowerCase()}-${today}.csv`;
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
  };

  const addAllMan = () => {
    if (manDisabled === true) {
      setItems([]);
      setHeader([]);
      setButtonVisible(false);
      setManDisabled(false);
      setManAutoCompleteLabel("Select a manufacturer");
    } else {
      setItems([]);
      setHeader([]);
      setButtonVisible(false);
      setSelectedMan([]);
      setManDisabled(true);
      setManAutoCompleteLabel("All manufacturers selected");
    }
  };

  const addAllMat = () => {
    if (matDisabled === true) {
      setItems([]);
      setHeader([]);
      setButtonVisible(false);
      setMatDisabled(false);
      setMatAutoCompleteLabel("Select a material class");
    } else {
      setItems([]);
      setHeader([]);
      setButtonVisible(false);
      setSelectedMat([]);
      setMatDisabled(true);
      setMatAutoCompleteLabel("All material classes selected")
    }
  };

  const addAllDist = () => {
    if (distDisabled === true) {
      setItems([]);
      setHeader([]);
      setButtonVisible(false);
      setDistDisabled(false);
      setMatAutoCompleteLabel("Select a distributor");
    } else {
      setItems([]);
      setHeader([]);
      setButtonVisible(false);
      setSelectedMat([]);
      setDistDisabled(true);
      setDistAutoCompleteLabel("All distributors classes selected")
    }
  };

  // const addAllStyles = () => {
  //   if (styleDisabled === true) {
  //     setItems([]);
  //     setHeader([]);
  //     setButtonVisible(false);
  //     setStyleDisabled(false);
  //     // setStyleAutoCompleteLabel("Select a style");
  //   } else {
  //     setItems([]);
  //     setHeader([]);
  //     setButtonVisible(false);
  //     setSelectedStyle([]);
  //     setStyleDisabled(true);
  //     // setStyleAutoCompleteLabel("All styles selected")
  //   }
  // };

  const manButton = () => {
    return manDisabled === true
      ? <Button className="export-btn" onClick={addAllMan} variant="contained">
          Select Multiple Manufacturers
        </Button>
      : <Button className="export-btn" onClick={addAllMan} variant="contained">
          Select All Manufacturers
        </Button>;
  };

  const matButton = () => {
    return matDisabled === true
      ? <Button className="export-btn" onClick={addAllMat} variant="contained">
          Select Multiple Material Classes
        </Button>
      : <Button className="export-btn" onClick={addAllMat} variant="contained">
          Select All Material Classes
        </Button>;
  };

  const distButton = () => {
    return distDisabled === true
      ? <Button className="export-btn" onClick={addAllDist} variant="contained">
          Select Multiple Distributors
        </Button>
      : <Button className="export-btn" onClick={addAllDist} variant="contained">
          Select All Distributors
        </Button>;
  };

  // const styleButton = () => {
  //   return styleDisabled === true
  //     ? <Button id="export-btn" color={"primary"} onClick={addAllStyles}>
  //         Select Multiple Styles
  //       </Button>
  //     : <Button id="export-btn" color={"primary"} onClick={addAllStyles}>
  //         Select All Styles
  //       </Button>;
  // };

  const renderSubmit = () => {
    return buttonVisible === true
      ? <div>
          <Button 
            className="export-btn" 
            onClick={handleExportExcel}
            autoFocus 
            variant="contained"
            endIcon={<GetAppIcon />}
          >
            Export Excel
          </Button>
          <Button 
            className="export-btn" 
            onClick={handleExportCsv}
            autoFocus 
            variant="contained"
            endIcon={<GetAppIcon />}
          >
            Export CSV
          </Button>
        </div>
      : <div />;
  };

  return (
    <div>
      <Paper className="upload-title">
        <FolderIcon className="upload-icon" />
        File Export
      </Paper>
      <Paper id="bg-paper" elevation={10}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignContent="center"
        >
            <Card className="export-instructions-paper" elevation={10}>
              <div className="card-content">
                <h1 className="file-upload-instructions"> File Export instructions</h1>
                <p>1. Select the role for pricing data.</p>
                <p>2. Select output format.</p>
                <p>3. Select at least one manufacturer or use the button to select all. </p><h4>(Selecting all can take several minutes to prepare and download the spreadsheet)</h4>
                <p>4. Select at least one material class or use the button to select all.</p>
                <p>5. Press the PREVIEW button (This will show the first 20 entries).</p>
                <p>6. Press the SUBMIT button.</p>
              </div>
            </Card>
          <Grid item xs={5}>
            <Paper className="export-paper" elevation={4}>
              <form className="export-form">
                <Grid container>
                  <NativeSelect
                    className="export-form-item"
                    onChange={handleRoleSelectChange}
                    defaultValue={"Select Role"}
                  >
                    <option disabled>Select Role</option>
                    {roles.map((role, index) => {
                      return (
                        <option key={index} value={JSON.stringify(role)}>
                          {" "}{role.role_name}{" "}
                        </option>
                      );
                    })}
                  </NativeSelect>

                  <NativeSelect
                    className="export-form-item"
                    onChange={handleOutputSelectChange}
                    defaultValue={"Select Output Format"}
                  >
                    <option disabled>Select Output Format</option>
                    {exportList.map((list, index) => {
                      return (
                        <option key={index} value={JSON.stringify(list)}>
                          {" "}{list.name}{" "}
                        </option>
                      );
                    })}
                  </NativeSelect>
                  <Autocomplete
                    className="export-form-item"
                    disabled={manDisabled}
                    multiple
                    options={manufacturerList}
                    getOptionLabel={option => option.manufacturer_name}
                    value={selectedMan}
                    getOptionDisabled={(options) => (selectedMan.length > 4 ? true : false)}
                    onChange={handleManufacturerSelectChange}
                    renderInput={params =>
                      <TextField {...params} label={autoManCompleteLabel} />}
                  />
                  {manButton()}

                  <Autocomplete
                    className="export-form-item"
                    disabled={distDisabled}
                    multiple
                    options={distributorList}
                    getOptionLabel={option => option.distributor_name}
                    value={selectedDist}
                    getOptionDisabled={(options) => (selectedDist.length > 4 ? true : false)}
                    onChange={handleDistributorSelectChange}
                    renderInput={params =>
                      <TextField {...params} label={autoDistCompleteLabel} />}
                  />
                  {distButton()}

                  <Autocomplete
                    className="export-form-item"
                    disabled={matDisabled}
                    multiple
                    options={materialList}
                    getOptionLabel={option => option.material_class}
                    value={selectedMat}
                    onChange={handleMaterialSelectChange}
                    renderInput={params =>
                      <TextField {...params} label={autoMatCompleteLabel}/>}
                  />
                  {matButton()}
                  {/* <Autocomplete
                    className="export-form-item"
                    disabled={styleDisabled}
                    multiple
                    options={styleList}
                    getOptionLabel={option => option.trade_name}
                    value={selectedStyle}
                    onChange={handleStyleSelectChange}
                    renderInput={params =>
                      <TextField {...params} label={autoStyleCompleteLabel}/>}
                  />
                  {styleButton()} */}
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <Paper className="btn-preview-paper" elevation={18}>
          <Button className="btn" 
          onClick={handlePreview} 
          autoFocus
          variant="contained">
            Preview
          </Button>
          {renderSubmit()}
        </Paper>
      </Paper>

      <table className="table_container">
        <thead>
          <tr>
            {header.map((h, index) =>
              <th id="header" key={index}>
                {h}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {items.map((d, i) =>
            <tr className="verify-upload-table" key={i}>
              {d.map((col, c) =>
                <td className="columns" key={c}>
                  {col}
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Export status"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {uploadStatus}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileExport;
