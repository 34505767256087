import React, { useContext, useState } from "react";

// MUI
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
// import Alert from "@material-ui/lab/Alert";

// actions
import { createLocation } from "../../Actions/index";

// styles
import "../styles/Admin.css";

// dependents
import { AdminContext } from "../../Context/AdminContextProvider";
import { AlertContext } from "../../Context/AlertContextProvider";
import { FormLabel, Grid, Switch, Tooltip, Typography } from "@material-ui/core";

// component
const CreateLocation = (props) => {
  const [open, setOpen] = useState(props.open);

  const [selectedValue] = useState("local");
  const [location, setLocation] = useState("");
  const [locationDescription, setLocationDescription] =  useState("");

  const [customerLocationName, setCustomerLocationName] = useState("");
  const [customerLocationPhone, setCustomerLocationPhone] = useState("");
  const [customerLocationEmail, setCustomerLocationEmail] = useState("");
  const [reminderSwitchState, setReminderSwitchState] = useState(false);
  const reminderSwitchToolTip = `Allow this location to send reminders about samples users have checked out`;

  const { setUpdate } = useContext(AdminContext);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const resetvars = () => {
    setLocation("");
    setLocationDescription("");
  };

  const handleClose = () => {
    setOpen(false);
    resetvars();
  };

  const handleSubmit = (type) => {
    if (type === "local") {
        createLocation(location, locationDescription, reminderSwitchState, customerLocationName, customerLocationPhone, customerLocationEmail)
        .then((res) => {
            const message = res.data.message;
            setMessageType("success");
            setMessage(message);
            setUpdate(true);
            handleAlertOpen();
        })
        .catch((err) => {
            const message = "Location Already Exists!";
            setMessageType("error");
            setMessage(message);
            handleAlertOpen();
        });
    }
  };

  const canCreateLocation = () => {
    if (location.length && locationDescription.length) {
      return false;
    }
    return true;
  };

  const renderForm = () => {
    return  (
      <form>
        <table>
          <tbody>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-divider">
                <TextField 
                  label="Location"
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  name="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                </TextField>
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-divider">
                <TextField 
                  label="Location Description"
                  fullWidth
                  variant="outlined"
                  required
                  type="text"
                  multiline={true}
                  name="location description"
                  value={locationDescription}
                  onChange={(e) => setLocationDescription(e.target.value)}
                >
                </TextField>
              </td>
            </tr>
            <tr>
              <td className="admin__create-user-table-divider">
                <FormControl>
                  <FormLabel>Enable Sample Reminders?</FormLabel>
                  <Grid className="mnd__tax-switch" component="label" container spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Tooltip arrow={true} title={reminderSwitchToolTip} >
                        <Switch
                          checked={reminderSwitchState}
                          onChange={(e) => setReminderSwitchState(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </FormControl>
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-divider">
                <Typography variant="h6" style={{marginTop: "20px"}}>Customer Facing Contact Info</Typography>
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-divider">
                <TextField 
                  label="Location Name"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="location name"
                  value={customerLocationName}
                  onChange={(e) => setCustomerLocationName(e.target.value)}
                >
                </TextField>
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-divider">
                <TextField 
                  label="Phone Number"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="location phone number"
                  value={customerLocationPhone}
                  onChange={(e) => setCustomerLocationPhone(e.target.value)}
                >
                </TextField>
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-divider">
                <TextField 
                  label="Email"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="location email"
                  value={customerLocationEmail}
                  onChange={(e) => setCustomerLocationEmail(e.target.value)}
                >
                </TextField>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  };

  return (
    <div>
       <Button
         onClick={handleOpen} 
         className="user__pw-change-button"
         variant="contained"
         >
        Add Location
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          className="Admin__create-user-header"
          id="alert-dialog-title"
        >
          {"Create Location"}
        </DialogTitle>
        <DialogContent dividers>
          {/* <Paper className="Admin_create-user-form"> */}

          <FormControl component="fieldset">
          </FormControl>
          {renderForm()}
          {/* </Paper> */}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            autoFocus
            variant="contained"
            className="user__pw-change-button"
            >
            Cancel
          </Button>
          <Button
            onClick={(e) => handleSubmit(selectedValue)}
            className="Admin__create-user"
            variant="contained"
            autoFocus
            disabled={canCreateLocation()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateLocation;