import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Actions 
import { getUserCheckoutList, checkin } from "../../Actions";

// Styles
import "../styles/Checkout.css";

// Icons
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WarningIcon from '@material-ui/icons/Warning';
import Collapse from '@material-ui/core/Collapse';
import StyleIcon from '@material-ui/icons/Style';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';


// MUI 
import { 
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

import UserDetails from "./UserDetails";
import { sortObjectsByAttribute } from "../../Util/utilsFunctions";

const PendingReturn = () => {

    const [checkoutList, setCheckoutList] = useState([]);
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(undefined);
    const [userFilter, setUserFilter] = useState(null);
    const [sortName, setSortName] = useState(false);
    const [sortLocation, setSortLocation] = useState(false);
    const [sortStyle, setSortStyle] = useState(false);
    const [sortDaysOut, setSortDaysOut] = useState(false);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("sample_checkout_id");
    const [userOpen, setUserOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userPicture, setUserPicture] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getUserCheckoutList()
        .then((result) => {
            setCheckoutList(result.data.checkout_status);
        })
    },[value])

    useEffect(() => {
      setPage(0);
    }, [rowsPerPage, userFilter, order, orderBy])

    const handleSort = (e) => {
        switch (e) {
            case "Name":
                if(sortName === false) { 
                    setOrder("desc");
                    setOrderBy("name")
                    setSortName(true);
                    setSortLocation(false);
                    setSortDaysOut(false);
                    setSortStyle(false);
                } else {
                    setSortName(false);
                    setOrder("asc");
                }
            break;
            case "Location":
                if(sortLocation=== false) { 
                    setOrder("desc");
                    setOrderBy("location_name")
                    setSortLocation(true);
                    setSortDaysOut(false);
                    setSortStyle(false);
                    setSortName(false);
                } else {
                    setSortLocation(false);
                    setOrder("asc");
                }
            break;
            case "Style":
                if(sortStyle === false) { 
                    setOrder("desc");
                    setOrderBy("style_name")
                    setSortLocation(false);
                    setSortDaysOut(false);
                    setSortStyle(true);
                    setSortName(false);
                } else {
                    setSortStyle(false);
                    setOrder("asc");
                }
            break;
            case "Days Out":
                if(sortDaysOut === false) { 
                    setOrder("desc");
                    setOrderBy("days_checked_out")
                    setSortLocation(false);
                    setSortDaysOut(true);
                    setSortStyle(false);
                    setSortName(false);
                } else {
                    setSortDaysOut(false);
                    setOrder("asc");
                }
            break;
            default:
                setSortLocation(false);
                setSortDaysOut(false);
                setSortStyle(false);
                setSortName(false);
            }
    }

    const handleClick = (index) => {
        open === index ? setOpen(undefined) : setOpen(index);
    };

    const handleApproveReturn = (productId, sampleCheckoutId) => {
        checkin(productId, sampleCheckoutId)
        .then((result) => {
            setValue(Number(value) + 1);
        })
        .catch((err) => {
            console.log(err);
        })
    };

    const handleUserOpen = (userId, name, email, phone, picture) => {
      setUserId(userId);
      setUserName(name);
      setUserEmail(email);
      setUserPhone(phone);
      setUserPicture(picture);
      setUserOpen(true);
    };
  
    const handleUserClose = () => {
      setUserId(undefined);
      setUserOpen(false);
    }

    const renderUserDetails = () => {
        return (
            <Dialog
            open={userOpen}
            onClose={handleUserClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
            >
            <DialogTitle id="alert-dialog-title">
                User Information
            </DialogTitle>
            <DialogContent>
                <UserDetails userId={userId} name={userName} email={userEmail} phone={userPhone} picture={userPicture} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUserClose} variant="contained" style={{margin:10, width:80}}>
                Ok
                </Button>
            </DialogActions>
            </Dialog>
        );
    };

    const renderSearchList = (filteredList, view) => {
      const sortedList = filteredList.sort((prev, curr) => sortObjectsByAttribute(prev, curr, "asc", "name"));
      const filterOptions = [];
      
      sortedList?.filter(c => !!c.name && (!!c.email || !!c.phone)).forEach(c => {
        if (filterOptions.find(u => u.email === c.email && u.phone === c.phone)) return;
        filterOptions.push({
          email: c.email,
          name: c.name,
          phone: c.phone
        });
      });

      const autocompleteStyles = {
        display: "inline-block", 
        width: "100%", 
        maxWidth: "400px",
        margin: view === "f" ? "20px 5px" : "20px 0px"
      }

      return (
        <Autocomplete
          value={userFilter}
          onChange={(event, user) => {
            setUserFilter(user ?? null);
          }}
          options={filterOptions}
          getOptionLabel={(option) => option?.name ?? ''}
          getOptionSelected={(option, value) => option?.email === value?.email && option?.phone === value?.phone}
          style={autocompleteStyles}
          renderOption={(option) => (
            <span style={{borderBottom: "1px solid rgba(0, 0, 0, 0.42)", width: "100%", paddingBottom: "5px"}}>
              <b>{option.name}</b>
              <br></br>
              {option.email}
              <br></br>
              {option.phone}
            </span>
          )}
          renderInput={(params) => <TextField {...params} label="Filter by customer" variant="filled" inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}/>}
        />
      )
    }

    const getFilteredList = (list, status) => {
      return list?.filter(c => {
        if (!userFilter) return c.status === status;
        return c.status === status && c.email === userFilter.email
      });
    }

    const checkoutRows = (paginatedList, view) => {
      if (view === 'm') {
        return paginatedList?.map((checkout, index) => (
          <List key={"mobile-" + checkout.sample_checkout_id}>
            <ListItem button onClick={() => handleClick(checkout.sample_checkout_id)} className="checkout__list-menu">
              <ListItemText primary={checkout.name} secondary={<Typography className="checkout__list-item-secondary">{checkout.style_name}</Typography>} />
              {open === checkout.sample_checkout_id ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {open === checkout.sample_checkout_id ? (
              <Collapse in={true} timeout="auto" unmountOnExit>
                <List style={{backgroundColor:"#FFF"}}>
                <ListItem>
                    <ListItemIcon>
                    <MyLocationIcon />
                    </ListItemIcon>
                    <ListItemText primary={checkout.location_name} />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                    <StyleIcon />
                    </ListItemIcon>
                    <Link to={`/product/${checkout.product_uuid}`} className="favorite__links" target="blank">
                        <ListItemText primary={<Typography className="checkout__list-item-stylename">{checkout.style_name}</Typography>} />
                    </Link>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemIcon>
                    { checkout.days_checked_out > 10 && checkout.days_checked_out <= 15 ? 
                        <DateRangeIcon style={{color:"#EED202"}}/> :
                        checkout.days_checked_out >= 16 ?
                        <DateRangeIcon style={{color:"#D0342C"}}/> :
                        <DateRangeIcon />
                    }
                    </ListItemIcon>
                    <ListItemText primary={checkout.days_checked_out} />
                </ListItem>
                <Divider />
                <ListItem>
                <Button onClick={() => {handleApproveReturn(checkout.product_uuid, checkout.sample_checkout_id)}}>
                        <ThumbUpIcon style={{color:"#23B246", marginRight:35}}/>
                    </Button>
                    <ListItemText primary="Approve?" />
                </ListItem>
                <Divider />
                <Button
                    onClick={() => {handleUserOpen(checkout.user_id, checkout.name, checkout.email, checkout.phone, checkout.picture)}}
                    variant="text"
                    style={{textDecoration:"underline", marginLeft:-10}}
                    >
                    <ListItem>
                        <ListItemIcon>
                        <PersonIcon/>
                        </ListItemIcon>
                        <ListItemText primary="View user details" />
                    </ListItem>
                    </Button>
                    <Divider />
                </List>
              </Collapse>
            ) : <></>}
          </List>
        ))
      } 

      return paginatedList?.map((checkout, index) => (
        <TableRow key={"desktop-" + checkout.sample_checkout_id} className="pending__table-row">
          <TableCell>
            <Button
              onClick={() => {handleUserOpen(checkout.user_id, checkout.name, checkout.email, checkout.phone, checkout.picture)}}
              variant="text"
              style={{textDecoration:"underline"}}
            >
              {checkout.name}
            </Button>
          </TableCell>
          <TableCell>
            {checkout.location_name}
          </TableCell>
          <TableCell>
            <Link to={`/product/${checkout.product_uuid}`} className="favorite__links" target="blank">
              {checkout.style_name}
            </Link>
          </TableCell>
          <TableCell>
            {checkout.days_checked_out} 
            {checkout.days_checked_out > 2 && checkout.days_checked_out <= 6 ? 
              <WarningIcon style={{paddingLeft:20, color:"#EED202"}}/> :
              checkout.days_checked_out >= 7 ?
                <WarningIcon style={{paddingLeft:20, color:"#D0342C"}}/> :
                <></>
            }
          </TableCell>
          <TableCell>
            <Button onClick={() => {handleApproveReturn(checkout.product_uuid, checkout.sample_checkout_id)}}>
              <ThumbUpIcon style={{color:"#23B246"}}/>
            </Button>
          </TableCell>
        </TableRow>
      ))
    }

    const handleChangePage = (e, page) => {
      setPage(page);
    }
  
    const handleChangeRowsPerPage = (e) => {
      setRowsPerPage(parseInt(e.target.value));
    }

    const paginationControls = (filteredList, view) => {
      const rowsPerPageOptions = [5, 10, 25];
      
      if (view === "m") {
        const buttonStyles = {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
          margin: "0px 0px 0px 10px"
        };
        const isFirstPage = page === 0;
        const isLastPage = ((page + 1) * rowsPerPage) >= filteredList.length;
        const currentStart = Math.max(page * rowsPerPage, 1);
        const currentEnd = Math.min((page * rowsPerPage) + rowsPerPage, filteredList.length);

        return (
          <div style={{margin: "0px 0px 20px", maxWidth: "400px"}}>
            <FormControl>
              <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
              <Select
                labelId="rows-per-page-label"
                id="rows-per-page"
                value={rowsPerPage}
                style={{width: "125px"}}
                variant="filled"
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                {rowsPerPageOptions.map(o => {
                  return (
                    <MenuItem key={o} value={o}>{o}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <div style={{float: "right"}}>
              <Typography style={{display: "inline-block", verticalAlign: "middle", margin: "0px", textAlign: "center"}}>{currentStart} - {currentEnd} of {filteredList.length}</Typography>
              <IconButton variant="text" disabled={isFirstPage} style={buttonStyles} onClick={() => setPage(isFirstPage ? 0 : page - 1)}><ChevronLeft /></IconButton>
              <IconButton variant="text" disabled={isLastPage} style={buttonStyles} onClick={() => setPage(isLastPage ? page : page + 1)}><ChevronRight /></IconButton>
            </div>
          </div>
        )
      } 

      return (
        <TableRow>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            colSpan={5}
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            style={{borderTop: "1px solid rgba(224, 224, 224, 1)"}}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableRow>
      )
    }

    if (!checkoutList.length) return <></>; 

    const filteredCheckoutList = getFilteredList(checkoutList, 2);
    const sortedCheckoutList = filteredCheckoutList.sort((prev, curr) => sortObjectsByAttribute(prev, curr, order, orderBy));
    const paginatedRows = sortedCheckoutList.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);

    return (
        <div>
            {renderUserDetails()}
            {/* Full resolution */}
            <div className="pending__container-full">
                <Typography
                className="header-text"
                variant="h4">
                    Returns Pending
                </Typography>
                <Paper className="pending__table">
                    {renderSearchList(filteredCheckoutList, "f")}
                    <Table className="pending__table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h5" className="checkout__header-typography">
                                        Name
                                    </Typography>
                                    <Button variant="text" onClick={() => handleSort("Name")}>
                                        {sortName === true ? <ExpandLess /> : <ExpandMore />}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5" className="checkout__header-typography">
                                        Location
                                    </Typography>
                                    <Button variant="text" onClick={() => handleSort("Location")}>
                                        {sortLocation === true ? <ExpandLess /> : <ExpandMore />}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5" className="checkout__header-typography">
                                        Style
                                    </Typography>
                                    <Button variant="text" onClick={() => handleSort("Style")}>
                                        {sortStyle === true ? <ExpandLess /> : <ExpandMore />}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5" className="checkout__header-typography">
                                        Days Pending
                                    </Typography>
                                    <Button variant="text" onClick={() => handleSort("Days Out")}>
                                        {sortDaysOut === true ? <ExpandLess /> : <ExpandMore />}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5" className="checkout__header-typography">
                                        Approve?
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {checkoutRows(paginatedRows, "f")}
                        </TableBody>
                        <TableFooter>
                          {paginationControls(filteredCheckoutList, "f")}
                        </TableFooter>
                    </Table>
                </Paper>
            </div>

            {/* Mobile site */}
            <div className="pending__container-mobile">
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader" className="checkouts__list-sub-header">
                    Returns Pending
                  </ListSubheader>
                }
              >
                {renderSearchList(filteredCheckoutList, "m")}
                {paginationControls(filteredCheckoutList, "m")}
                {checkoutRows(paginatedRows, "m")}
              </List>
            </div>
</div>
    );
};

export default PendingReturn;