import { Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../Context/AlertContextProvider";
import axios from "axios";
import { baseUrl } from "../../Contants";
import { Close, CloseRounded } from "@material-ui/icons";
import ViewLeadModal from "./ViewLeadModal";
import ViewNotesModal from "./ViewNotesModal";
import ViewCheckoutsModal from "./ViewCheckoutsModal";
import ViewFavoritesModal from "./ViewFavoritesModal";
import ChangeStatusDialog from "./ChangeStatusDialog";
import ChangeAssignmentDialog from "./ChangeAssignmentDialog";
import ExportLeadDataDialog from "./ExportLeadDataDialog";

function ValidatedInput(props) {
  const [inputError, setInputError] = useState();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputError(undefined);
    setInputValue(props.value);
  }, [props.value])

  const validateField = (newValue) => {
    let errMessage;

    setInputValue(newValue);

    if (newValue && props.regex && !props.regex.test(newValue)) {
      errMessage = "Current value does not match the required formatting";
    
      if (props.format) errMessage += " (ex. " + props.format + ")";
      
      setInputError(errMessage);
      return;
    }

    setInputError(errMessage);
    props.updateField(props.attribute, newValue);
  }

  return (
    <TextField 
      id={props.id} 
      label={props.label} 
      placeholder={props.format}
      value={inputValue} 
      error={inputError ? true : false}
      helperText={inputError}
      required={props.required ?? false}
      style={{width: "100%"}}
      onInput={(e) => validateField(e.target.value)}
    />
  )
}

export default function CreateLeadForm (props) {
  const formType = {
    find: "find",
    create: "create",
    both: "both"
  }
  const leadModalStateType = {
    closed: "closed",
    match: "match",
    lead: "lead"
  }

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const [leads, setleads] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [rsaList, setRsaList] = useState([]);
  const [selectedRsa, setSelectedRsa] = useState("0");
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("1");
  const [selectedLead, setSelectedLead] = useState({});
  const [selectedModalLeadId, setSelectedModalLeadId] = useState();
  const [selectedModalLead, setSelectedModalLead] = useState();
  const [leadModalState, setLeadModalState] = useState(leadModalStateType.closed);
  const [mobileModalType, setMobileModalType] = useState("");
  const [newLead, setNewLead] = useState({});
  const [assignToMe, setAssignToMe] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState(props.formType === formType.create ? formType.create : formType.find);

  const clearForm = () => {
    if(selectedFunction === formType.find) {
      setSelectedLead({});
    } else {
      setNewLead({});
      setAssignToMe(false);
      setSelectedRsa("0");
      setSelectedStatus("1");
    }
  }

  const getLeads = async () => {
    const result = await axios.get(`${baseUrl}/api/leads`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error retreiving leads");
      handleAlertOpen();
      return;
    }

    const filteredLeads = result.data.leadList;
    setleads([...filteredLeads]);
  }

  const assignLead = async (lead_id, rsa_id) => {
    if (!lead_id || !rsa_id) {
      setMessageType("error");
      setMessage("Error assigning lead");
      handleAlertOpen();
      return;
    }

    const result = await axios.post(`${baseUrl}/api/leads/${lead_id}/assign/${rsa_id}`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error assigning lead");
      handleAlertOpen();
      return;
    }

    setMessageType("success");
    setMessage("Lead has been assigned!");
    handleAlertOpen();
    getLeads();
  }

  const getStatuses = async () => {
    const result = await axios.get(`${baseUrl}/api/leads/status`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error retrieving statuses");
      handleAlertOpen();
      setLeadStatuses([]);
      return;
    }

    setLeadStatuses([...result.data.userStatusList]);
  }

  const getRsas = async () => {
    const result = await axios.get(`${baseUrl}/api/rsas`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error retrieving RSAs");
      handleAlertOpen();
      setRsaList([]);
      return;
    }

    setRsaList([...result.data.rsaList]);
  }

  useEffect(() => {
    if (props.isOpen) {
      getLeads();
      getRsas();
      getStatuses();
    }
  }, [props.isOpen]);

  useEffect(() => {
    const checkWindowSize = () => {
      window.matchMedia("screen and (max-width: 1050px)").matches ? setIsMobile(true) : setIsMobile(false);
    }

    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);
    return () => window.removeEventListener("resize", checkWindowSize);
  }, [])

  const updateNewLead = (value, attribute) => {
    const tempNewLead = { ...newLead };
    tempNewLead[attribute] = value;
    setNewLead({ ...tempNewLead });
  }

  const updateSelectedLead = (data) => {
    setSelectedLead({ ...data });
  }

  const toggleSelectedFunction = (value) => {
    if (value) setSelectedFunction(value);
  }

  const getStrippedNumber = (number) => {
    const strippedNumber = number?.replace(/\D+/g, "");

    if (!strippedNumber) return '';
    
    return strippedNumber.length > 11 ? strippedNumber.substring(strippedNumber.length - 11) : strippedNumber;
  }

  const validateForm = async () => {
    if (selectedFunction === formType.find) {

      if (!selectedLead.lead_id) {
        setMessageType("error");
        setMessage("No lead was selected");
        handleAlertOpen();
        return;
      }

      setSelectedModalLeadId(selectedLead.lead_id);
      setLeadModalState(leadModalStateType.lead)
    } else {

      if (!newLead) {
        setMessageType("error");
        setMessage("No data has been inputted for the new lead");
        handleAlertOpen();
        return;
      } 

      if (!newLead.name || (!newLead.email && !newLead.phone) || !selectedStatus) {
        setMessageType("error");
        setMessage("Some of the required fields have blank or invalid inputs");
        handleAlertOpen();
        return;
      }

      if (props.user.access_type_cd === "a" && !selectedRsa) {
        setMessageType("error");
        setMessage("A RSA assignment option has not been selected");
        handleAlertOpen();
        return;
      }

      const leadMatch = leads.find(i => i.name === newLead.name && (i.email === newLead.email || i.phone === newLead.phone));

      if (leadMatch) {
        setSelectedModalLeadId(leadMatch.lead_id);
        setLeadModalState(leadModalStateType.match)
        return;
      }

      const rsaId = props.user.access_type_cd === "a" ? selectedRsa : (assignToMe ? props.user.user_id : undefined);

      const result = await axios.post(`${baseUrl}/api/leads/create`, { 
        name: newLead.name, 
        email: newLead.email, 
        phone: newLead.phone, 
        statusId: selectedStatus, 
        rsaId 
      });

      if (result.status !== 200) {
        setMessageType("error");
        setMessage("Error retrieving new lead");
        handleAlertOpen();
        return;
      } else {
        setMessageType("success");
        setMessage("New lead created");
        handleAlertOpen();
      }

      if (result.data.user_id) {
        await getLeads();
        setSelectedModalLeadId(result.data.user_id);
        setLeadModalState(leadModalStateType.lead)
        return;
      }
    }
  }

  useEffect(() => {
    if (!selectedModalLeadId) {
      setSelectedModalLead(undefined);
      return;
    }

    const lead = leads.find(i => i.lead_id === selectedModalLeadId);
    setSelectedModalLead(lead);
  }, [leads, selectedModalLeadId])

  const rsaOptions = rsaList.map(rsa => {
    return (
      <MenuItem value={rsa.userId} key={rsa.userId}>
        <Typography>
          <b>{rsa.name}</b>
          <br></br>
          {rsa.email}
        </Typography> 
      </MenuItem>
    )
  });
  const leadStatusOptions = leadStatuses.map(s => {
    return (<MenuItem key={s.status_cd} value={s.user_status_id} aria-label={s.status}>{s.status}</MenuItem>)
  })  

  let header = "";
  switch (props.formType) {
    case formType.both:
      header = "Lead Search/Creation";
    break;
    case formType.create:
      header = "Lead Creation";
    break;
    case formType.find:
      header = "Lead Search";
    break;
  }

  let numberUrl;

  if (selectedModalLead) {
    const strippedNumber = getStrippedNumber(selectedModalLead.phone)
    numberUrl = "tel:" + (strippedNumber.length === 11 ? "+" + strippedNumber : strippedNumber);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} style={{position: "relative"}}>
          <Typography variant="h4">{header}</Typography>
          { props.isModal ? (
            <IconButton 
              onClick={props.handleOnClose} 
              aria-label="close" 
              style={{
                position: "absolute", 
                padding: "4px", 
                top: "15px",
                right: "5px"
              }}
            >
              <Close />
            </IconButton> 
          ) : undefined }
        </Grid>
        { 
          props.formType === formType.both ? (
            <Grid item xs={12} md={12} lg={12}>
              <ToggleButtonGroup value={selectedFunction} exclusive="true" onChange={(e, value) => toggleSelectedFunction(value)} style={{backgroundColor: "#FFFFFF", width: "100%"}}>
                <ToggleButton value="find" aria-label="find lead" style={{width: "50%"}}>
                  Find Lead
                </ToggleButton>
                <ToggleButton value="create" aria-label="create lead" style={{width: "50%"}}>
                  Create Lead
                </ToggleButton>
              </ToggleButtonGroup>
              <Typography style={{marginTop: "30px"}}>
                { selectedFunction === formType.create ? (
                    "Fill out the form below to create a new lead. Please note that this will only create a new lead if no match is found when submitted."
                  ) : (
                    "Fill out the form below to find a lead. If you are unable to find an autocomplete option based on your input then a new lead may need to be created."
                  )
                }
              </Typography>
            </Grid>
          ) : undefined 
        }
        {
          selectedFunction !== formType.create ? (
            <>
              <Grid item xs={12} md={6} lg={6}>
                <Autocomplete
                  value={selectedLead}
                  options={leads}
                  getOptionLabel={(option) => option.name || ""}
                  getOptionSelected={(option, value) => option.lead_id === value}
                  renderOption={(option) => (
                    <span id={option.lead_id} style={{borderBottom: "1px solid rgba(0, 0, 0, 0.42)", width: "100%", paddingBottom: "5px"}}>
                      <b>{option.name}</b>
                      <br></br>
                      {option.email}
                      <br></br>
                      {getStrippedNumber(option.phone)}
                    </span>
                  )}
                  renderInput={(params) => <TextField {...params} label="Name" inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}/>}
                  onChange={(e, data) => updateSelectedLead(data)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Autocomplete
                  value={selectedLead}
                  options={leads}
                  getOptionLabel={(option) => option.email || ""}
                  getOptionSelected={(option, value) => option.lead_id === value.lead_id}
                  renderOption={(option) => (
                    <span id={option.lead_id} style={{borderBottom: "1px solid rgba(0, 0, 0, 0.42)", width: "100%", paddingBottom: "5px"}}>
                      <b>{option.email}</b>
                      <br></br>
                      {option.name}
                      <br></br>
                      {getStrippedNumber(option.phone)}
                    </span>
                  )}
                  renderInput={(params) => <TextField {...params} label="Email" inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}/>}
                  onChange={(e, data) => updateSelectedLead(data)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Autocomplete
                  value={selectedLead}
                  options={leads}
                  getOptionLabel={(option) => getStrippedNumber(option.phone) || ""}
                  getOptionSelected={(option, value) => option.lead_id === value.lead_id}
                  renderOption={(option) => (
                    <span id={option.lead_id} style={{borderBottom: "1px solid rgba(0, 0, 0, 0.42)", width: "100%", paddingBottom: "5px"}}>
                      <b>{getStrippedNumber(option.phone)}</b>
                      <br></br>
                      {option.name}
                      <br></br>
                      {option.email}
                    </span>
                  )}
                  renderInput={(params) => <TextField {...params} label="Phone Number" inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}/>}
                  onChange={(e, data) => updateSelectedLead(data)}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6} lg={6}>
                <ValidatedInput
                  id="name" 
                  label="Name" 
                  attribute="name"
                  regex={/^\D+(\s\D+)+$/}
                  format="First Last"
                  required={true}
                  value={newLead.name ?? ""}
                  updateField={(attribute, newValue) => updateNewLead(newValue, attribute)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <ValidatedInput
                  id="email" 
                  label="Email" 
                  attribute="email"
                  regex={/^\S+@\S+\.\S+/}
                  format="example@domain.com"
                  value={newLead.email ?? ""}
                  updateField={(attribute, newValue) => updateNewLead(newValue, attribute)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <ValidatedInput
                  label="Phone Number" 
                  attribute="phone"
                  regex={/^(\D*\d){10,11}$/}
                  format="10-11 digits"
                  required={false}
                  value={newLead.phone ?? ""}
                  updateField={(attribute, newValue) => updateNewLead(newValue, attribute)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl style={{width: "100%"}}>
                  <InputLabel id="lead-status-dropdown" required>Status</InputLabel>
                  <Select
                    style={{width: "100%"}}
                    value={selectedStatus}
                    required
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    {leadStatusOptions}
                  </Select>
                </FormControl>
              </Grid>
              { props.user.access_type_cd === "a" ? (
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl style={{width: "100%"}}>
                      <InputLabel id="rsa-list-label" required>Assigned RSA</InputLabel>
                      <Select
                        labelId="rsa-list-label"
                        id="rsa-list"
                        required
                        value={selectedRsa}
                        onChange={(e) => setSelectedRsa(e.target.value)}
                      >
                        <MenuItem aria-label="Unassigned" value={"0"}>
                          <Typography><b>Leave Unassigned</b></Typography>
                        </MenuItem>
                        <MenuItem aria-label="Assign To Me" value={props.user.user_id}>
                          <Typography><b>Assign to me</b></Typography>
                        </MenuItem>
                        {rsaOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                      control={<Checkbox checked={assignToMe} onChange={() => setAssignToMe(!assignToMe)} name="assignToMeCheckbox"/>}
                      label="Assign new lead to me"
                    />
                  </Grid>
                )
              }
            </>
          )
        }
        <Grid item xs={12} md={12} lg={12} style={{textAlign: "right"}}>
          { props.isModal ? (
              <Button variant="outlined" onClick={() => props.handleOnClose()} style={{margin: "10px 0px 10px 15px"}}>Close</Button>
            ) : undefined 
          }
          <Button variant="outlined" onClick={() => clearForm()} style={{margin: "10px 0px 10px 15px", backgroundColor: "#e0e0e0", border: "1px solid #e0e0e0"}}>Clear Form</Button>
          <Button variant="outlined" onClick={() => validateForm()} style={{margin: "10px 0px 10px 15px", backgroundColor: "#142E3E", color: "#28C4FC"}}>{selectedFunction === formType.find ? "Open Lead Info" : "Submit New Lead"}</Button>
        </Grid>
      </Grid>
      <Dialog open={leadModalState === leadModalStateType.match}>
        <DialogContent>
          <Typography variant="h5">Match Found!</Typography>
          <Typography>A matching lead was found based on your input</Typography>
          <div style={{margin: "20px auto"}}>
            <Typography variant="h6">{selectedModalLead?.name}</Typography>
            <Typography>{selectedModalLead?.email}</Typography>
            <Typography>{selectedModalLead?.phone}</Typography>
            <Typography style={{marginTop: "10px"}}>RSA: {selectedModalLead?.rsa_name || "Unassigned"}</Typography>
            <Typography>Lead Status: {selectedModalLead?.status}</Typography>
          </div>
          <div style={{textAlign: "right"}}>
            <Button 
              variant="outlined" 
              onClick={() => {
                setSelectedModalLeadId(undefined);
                setLeadModalState(leadModalStateType.closed)
              }}
            >
              Close
            </Button>
            <Button 
              variant="outlined" 
              onClick={() => {
                setLeadModalState(leadModalStateType.lead)
              }} 
              style={{margin: "20px 0px 20px 15px", backgroundColor: "#142E3E", color: "#28C4FC"}}
            >
              Open Matching Lead
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {isMobile ? (
        <>
          <Dialog open={leadModalState === leadModalStateType.lead}>
            <DialogContent>
              <div>
                <CloseRounded 
                  style={{float: "right"}} 
                  onClick={() => {
                    setSelectedModalLeadId(undefined);
                    setLeadModalState(leadModalStateType.closed)
                  }}
                ></CloseRounded>
                <Typography variant="h5">{selectedModalLead?.name}</Typography>
              </div>
              <div style={{margin: "20px auto"}}>
                <div style={{marginBottom: "20px"}}>
                  <Typography style={{margin: "0px auto"}} paragraph><a href={"mailto:" + selectedModalLead?.email}>{selectedModalLead?.email}</a></Typography>
                  <Typography style={{margin: "0px auto"}} paragraph><a href={numberUrl}>{selectedModalLead?.phone}</a></Typography>
                </div>
                <Typography style={{margin: "0px auto"}} paragraph><b>Status:</b> {selectedModalLead?.status}</Typography>
                <Typography style={{margin: "0px auto"}} paragraph><b>Assigned To:</b> {selectedModalLead?.rsa_name}</Typography>
                { props.user.access_type_cd === "a" ? (
                    <Button 
                      variant="contained"
                      style={{margin: "20px 20px 0px 0px"}}
                      onClick={() => {
                        setMobileModalType("assignment");
                      }}
                    >
                      {selectedModalLead?.rsa_user_id ? "Reassign Lead" : "Assign Lead"}
                    </Button>
                  ) : undefined
                }
                {
                  !selectedModalLead?.rsa_user_id ? (
                    <Button 
                      variant="contained"
                      style={{margin: "20px 20px 0px 0px"}}
                      onClick={() => assignLead(selectedModalLead?.lead_id, props.user.user_id)}
                    >
                      Assign Lead to Me
                    </Button>
                  ) : undefined
                }
                { props.user.access_type_cd === "a" || selectedModalLead?.rsa_user_id === props.user.user_id ? (
                    <Button 
                      variant="contained"
                      style={{margin: "20px 20px 0px 0px"}}
                      onClick={() => {
                        setMobileModalType("status");
                      }}
                    >
                      Update Status
                    </Button>
                  ) : undefined
                }
                <Button 
                  variant="contained" 
                  style={{margin: "20px 20px 0px 0px"}}
                  onClick={() => {
                    setMobileModalType("notes");
                  }} 
                >
                  View Notes
                </Button>
                <Button 
                  variant="contained" 
                  style={{margin: "20px 20px 0px 0px"}}
                  onClick={() => {
                    setMobileModalType("checkouts");
                  }} 
                >
                  View Checkouts
                </Button>
                <Button 
                  variant="contained" 
                  style={{margin: "20px 20px 0px 0px"}}
                  onClick={() => {
                    setMobileModalType("favorites");
                  }} 
                >
                  View Favorites
                </Button>
                <Button 
                  variant="contained"
                  style={{margin: "20px 20px 0px 0px"}}
                  onClick={() => {
                    setMobileModalType("export");
                  }}
                >
                  Export Lead Data
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <ViewNotesModal 
            className="lead-management__mobile-modal" 
            lead={selectedLead} 
            user={props.user} 
            isModalOpen={mobileModalType === "notes"}
            handleOnClose={() => {
              setMobileModalType("");
            }}
          />
          <ViewCheckoutsModal 
            className="lead-management__mobile-modal" 
            lead={selectedLead} 
            isModalOpen={mobileModalType === "checkouts"}
            handleOnClose={() => {
              setMobileModalType("");
            }}
          />
          <ViewFavoritesModal 
            className="lead-management__mobile-modal" 
            lead={selectedLead} 
            isModalOpen={mobileModalType === "favorites"}
            handleOnClose={() => {
              setMobileModalType("");
            }}
          />
          <ChangeStatusDialog 
            lead={selectedLead}
            user={props.user}
            isStatusDialogOpen={mobileModalType === "status"} 
            handleOnClose={() => {
              setMobileModalType("");
            }}
            refreshParent={() => getLeads()}
          />
          <ChangeAssignmentDialog 
            lead={selectedLead}
            user={props.user}
            isAssignDialogOpen={mobileModalType === "assignment"} 
            handleOnClose={() => {
              setMobileModalType("");
            }}
            refreshParent={() => getLeads()}
          />
          <ExportLeadDataDialog 
            isOpen={mobileModalType === "export"}
            lead={selectedLead}
            handleOnClose={() => {
              setMobileModalType("");
            }}
          />
        </>
      ) : (
        <ViewLeadModal 
          className="lead-management__desktop-modal" 
          lead={selectedModalLead} 
          user={props.user} 
          isModalOpen={leadModalState === leadModalStateType.lead}
          handleOnClose={() => {
            setSelectedModalLeadId(undefined);
            setLeadModalState(leadModalStateType.closed)
          }}
          refreshParent={() => getLeads()}
        />
      )}
    </>
  )
}