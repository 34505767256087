import React, { useState, createContext, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../Contants";
import { AppContext } from "./AppContextProvide";

export const PrintQueueContext = createContext();

export const PrintQueueContextProvider = ({ children }) => {
  const { user } = useContext(AppContext);
  const [queue, setQueue] = useState([]);
  const [update, setUpdate] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const isLoggedIn = user.user_id;

  useEffect(() => {
    async function getProductQueue() {
      const result = await axios(`${baseUrl}/api/user/queue?sort=${switchState}`);
      setQueue(result.data.data);
      setUpdate(false);
    }
    if (isLoggedIn) {
      getProductQueue(switchState);
    }
  }, [isLoggedIn, update, switchState]);

  return (
    <PrintQueueContext.Provider value={{ queue, setUpdate, switchState, setSwitchState }}>
      {children}
    </PrintQueueContext.Provider>
  );
};
