import React, { useContext, useEffect, useState } from "react";
import { getUser } from "../../helpers/index";

// MUI
import {Box,
        Tab,
        Tabs,
        Grid,
        Paper,
        Fab
      } from "@material-ui/core";

// styles
import "../styles/Home.css";

// dependends
import Announcements from "./Announcements";
import { CompanyStatsContext } from "../../Context/CompaniesStatsContextProvider";
import { ProductStatsContext } from "../../Context/ProductsStatsContextProvider";
import SimpleStats from "./SimpleStats";
import Bulletins from "./UserBulletin/Bulletins";
import ManualFiles from "./LookBack/ManualFiles";
import { listS3Files, getLatestB2B } from "../../Actions";
import { baseUrl } from "../../Contants";
import SimpleFileHistory from "./SimpleFileHistory";
import { Camera } from "@material-ui/icons";
import ScanningModal from "./Scanner/ScanningModal";


const Home = () => {
  const [value, setValue] = useState("FileHistory");
  const { companyStats } = useContext(CompanyStatsContext);
  const { productStats } = useContext(ProductStatsContext);
  const [fileList, setFileList] = useState([]);
  const [latest, setLatest] = useState([]);
  const [userAccessType] = useState(getUser().access_type_cd);
  const [showScannerModal, setShowScannerModal] = useState(false);

  useEffect(() => {
     getLatestB2B()
        .then((result) => {
            setLatest(result.data.latest)
        })

    listS3Files().then((result) => {
      setFileList(result.data.fileList);
    })
    .catch((err) => {
      console.log(err);
    });

    if (companyStats) {
      companyStats.sort((a, b) => a.times_viewed - a.times_viewed);
    }
    if (productStats) {
      productStats.sort((a, b) => a.times_viewed - a.times_viewed);
    }
  }, [productStats, companyStats]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      "aria-controls": `wrapped-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  return (

    <div className="home__content">
      {/* <div className="home__info-cards"></div> */}
      <Grid container spacing={3} className="home-grid">
        <Grid item xs={6}>
      <div className="home__bulletin">
        <Paper className="home__papers" elevation={1}>
          <Bulletins />
        </Paper>
      </div>
      </Grid>
      <Grid item xs={6}>
        <div className="home__bulletin">
          <Paper className="home__papers" elevation={1}>
            <Announcements />
          </Paper>
        </div>
        </Grid>
         <Grid item xs={6} className="file-history-grid">
         {userAccessType === 'ro' ? <div></div>  : 
         <div className="home__lookback">
          <Paper elevation={1} >
          <Tabs
          TabIndicatorProps={{style: {backgroundColor: "#FF682C", color: "#FF682C"}}}
          className="full-screen-tabs"
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab value="manualfile" label="Manual Files" {...a11yProps("manualfile")} />
          <Tab value="FileHistory" label="B2B Files" {...a11yProps("FileHistory")} />
        </Tabs>
        <Tabs
          TabIndicatorProps={{style: {backgroundColor: "#FF682C", color: "#FF682C"}}}
          className="mobile-tabs"
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab value="manualfile" label="Manual Files" {...a11yProps("manualfile")} />
          <Tab value="FileHistory" label="B2B Files" {...a11yProps("FileHistory")} />
        </Tabs>
          </Paper>
        </div>
        }
        {userAccessType === 'ro' ? <div></div>  : 
        <Paper elevation={1} className="home__papers" style={{overflow:"auto"}}>
          <TabPanel value={value} index="FileHistory">
            <SimpleFileHistory file_history={latest}/>
          </TabPanel>
          <TabPanel value={value} index="manualfile">
            <ManualFiles fileList={fileList} url={baseUrl}/>
          </TabPanel>
          </Paper> 
        }
        </Grid> 
        <Grid item xs={6}>
        <div className="home__bulletin">
          <Paper  className="home__papers" elevation={1}>
            <SimpleStats />
          </Paper>
        </div>
        </Grid>
        </Grid>
        <Fab size="medium" variant="extended" style={{zIndex: "20", position: "fixed", bottom: "0px", right: "0px", padding: "0px 12px", color: "#28C4FC", backgroundColor: "#142E3E", margin: "20px"}} onClick={() => setShowScannerModal(true)}>
          <Camera fontSize="small" style={{marginRight: "5px"}}/> 
          Scan QR
        </Fab> 
        <ScanningModal isOpen={showScannerModal} handleOnClose={() => setShowScannerModal(false)}/>
    </div>
  );
};

export default Home;
