import React from "react";
import QRcode from "qrcode.react";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";

// styles
import "./MockProduct.css";

export default function MockProduct(props) {
  return (
    <div className="mock-product-container">
      <div className="mock-product-info">
        <table>
          <tbody>
            <tr>                 
              <td colSpan={2}>
                {props.prog === true ?
                <div className="loading">
                  DATA LOADING... <CircularProgress size={50} className="loading" />
                </div> :
                <div className="loading">
                  Please login for pricing
                </div>}
              </td>
            </tr>
            <tr>
              <td>Manufacturer</td>
              <td>
                <div className="mock-text"></div>
              </td>
            </tr>
            <tr>
              <td>Floor Type</td>
              <td>
                <div className="mock-text"></div>
              </td>
            </tr>

            <tr>
              <td>Style Number</td>
              <td>
                <div className="mock-text"></div>
              </td>
            </tr>

            <tr>
              <td>Style Name</td>
              <td>
                <div className="mock-text"></div>
              </td>
            </tr>

            <tr>
              <td>Price</td>
              <td>
                <div className="mock-text"></div>
              </td>
            </tr>

            <tr>
              <td>Room Size</td>
              <td>
                <div className="mock-text"></div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className="mock-text"></div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p className="product-notes">
            **Prices do not include tax or other incidentals
          </p>
        </div>

        <div className="qrcode">
          <QRcode
            value={`https://www.showroompricing.com/`}
            size={160}
            bgColor={"#dedcdc"}
          />
        </div>
      </div>
    </div>
  );
}
