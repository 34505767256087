import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../Contants";

export const CompanyLogoContext = createContext();

export const CompanyLogoContextProvider = ({ children }) => {
  const [companyLogo, setCompanyLogo] = useState("");
  const [update, setUpdate] = useState(false);
  const [logoLink, setLogoLink] = useState("http://www.yoursite.com");
  const [logoLinkData, setLogoLinkData] = useState([]);

  useEffect(() => {
    let abortController = new AbortController(); 
    async function getCompanyLogo() {
      const result = await axios(`${baseUrl}/api/app/company/logo`);
      setCompanyLogo(result.data.companyLogo);
      setUpdate(false);
    }

    async function getLogoLink() {
      const result = await axios(`${baseUrl}/api/admin/logolink/get`);
      setLogoLink(result.data.data[0].logo_link);
      setLogoLinkData(result.data.data);
    }

    getCompanyLogo();
    getLogoLink();
    return () => {  
      abortController.abort();  
      } 
  }, [update]);

  return (
    <CompanyLogoContext.Provider value={{ companyLogo, setUpdate, logoLink, logoLinkData }}>
      {children}
    </CompanyLogoContext.Provider>
  );
};
