import React, { useState, useEffect, useContext } from "react";

// Actions
import {
    deleteCodeTranslation, 
    setNewCodeTranslation, 
    setNewUom,
    deleteMasterUom } from "../../Actions";

// MUI
import {
    makeStyles, 
    TextField,
    Button,
    List,
    ListItem,
    Divider,
    Collapse,
    ListItemText,
    IconButton ,
    ListItemSecondaryAction,
    Typography } from "@material-ui/core";

// Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';


// Context
import { AlertContext } from "../../Context/AlertContextProvider";

const UomSettings = (props) => {
    const [uomArray, setUomArray] = useState(props.uomArray);
    const [codeTranslationArray, setCodeTranslationArray] = useState(props.codeTranslationArray);
    const [value, setValue] = useState(0);
    const [disabled, setDisabled] = useState(undefined);
    const [codeTranslation, setCodeTranslation] = useState({});
    const [tempCodeTranslation, setTempCodeTranslation] = useState('');
    const [uomType, setUomType] = useState('');
    const [uomTypeCd, setUomTypeCd] = useState('');
    let tempMatClassList = [];
    const [open, setOpen] = useState(undefined);
    const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

    const useStyles = makeStyles((theme) => ({
        tableCells: {
            fontWeigth: "lighter"
        },
        textField: {
            margin: 10
        },
        addButton: {
            float: "right",
            margin: 20,
            color: "#FF682C"
        },
        deleteButton: {
            color: "#D10000"
        },
        editButton: {
            color:"green"
        },
        materialClassList: {
            background: "#142E3E",
            color: "#28C4FC",
            margin:3,
            width:600,
            [theme.breakpoints.down('sm')]:{
                width:'99%',
              }
        },
        materialClassSubList: {
            color:"#000",
            background:"#DCDCDC"
        }
      }));

      const classes = useStyles();

    useEffect(() => {
        uomArray.sort((a, b) => {if(a.uom_type > b.uom_type){
        return 1 } if(a.uom_type < b.uom_type) {
            return -1} 
        return 0})
        
        codeTranslationArray.forEach(element => {
            tempMatClassList[element["code_translation_id"]] = element["source_value"]
        })
        setCodeTranslation(tempMatClassList);
    }, [value])

    const isDisabled = () => {
        if (uomType === '') {
          return true;
        } else if (uomTypeCd === '') {
          return true;
        } else {
          return false;
        }
      };

    const cdIsDisabled = () => {
        if (tempCodeTranslation === '') {
            return true;
        } else {
            return false;
        }
    };

    const handleClick = (index) => {
        open === index ? setOpen(undefined) : setOpen(index);
    };

    const handleDeleteUomType = (id, type) => {
       deleteCodeTranslation(id, type, "UOM")
       .then(() => {
        setMessageType("success");
        setMessage("UOM deleted");
        handleAlertOpen(true);
        uomArray.forEach((row, index) => {
            if (id === row.material_class_cd_id) { 
                uomArray.splice(index, 1)
                setValue(value + 1);
            }

        });
        codeTranslationArray.forEach((row, index) => {
            if (id === row.source_value) { 
                codeTranslationArray.splice(index, 1)
                setValue(value + 1);
            }
        });
       })
       .catch((err) => {
        console.log(err);
        setMessageType("error");
        setMessage("Error deleting UOM");
        handleAlertOpen(true);
       });
    };

    const handleAddCodeTranslation = (uomTypeCd) => {

        if (codeTranslationArray.filter(cd => cd.source_value === tempCodeTranslation).length > 0) {
            setMessageType("error");
            setMessage("UOM type exists");
            handleAlertOpen(true);
        } else {
            setNewCodeTranslation("UOM", tempCodeTranslation, uomTypeCd)
            .then(() => {
                setMessageType("success");
                setMessage("UOM type added");
                handleAlertOpen(true);
                codeTranslationArray.push({"target_value":uomTypeCd ,"source_value":tempCodeTranslation});
                setValue(value + 1);
            })
            .catch((err) => {
                console.log(err);
                setMessageType("error");
                setMessage("Error adding UOM type");
                handleAlertOpen(true);
            });
        };
    };

    const handleAddUom = () => {
        if (uomArray.filter(cd => cd.uom_type === uomType).length > 0 ||
        uomArray.filter(cd => cd.uom_type === uomTypeCd).length > 0) {
            setMessageType("error");
            setMessage("UOM/code exists");
            handleAlertOpen(true);
        } else {
            setNewUom(uomType, uomTypeCd)
            .then(() => {
                setMessageType("success");
                setMessage("unit of measure added");
                handleAlertOpen(true);
                setValue(value + 1);
                uomArray.push({"uom_type_cd":uomTypeCd ,"uom_type":uomType});
            })
            .catch((err) => {
                console.log(err);
                setMessageType("error");
                setMessage("Error adding unit of measure");
                handleAlertOpen(true);
            });
        };
    };

    const handleNewCodeTranslation = (e) => {
        setTempCodeTranslation(e.target.value.toUpperCase());
    };

    const handleNewUomTypeCd = (e) => {
        setUomTypeCd(e.target.value.toUpperCase());
    };

    const handleNewUomType = (e) => {
        setUomType(e.target.value.toUpperCase());
    };

    const handleDeleteMasterUom = (id) => {
        deleteMasterUom(id)
        .then(() => {
            setMessageType("success");
            setMessage("UOM type deleted");
            handleAlertOpen(true);
            uomArray.forEach((row, index) => {
                if (id === row.uom_type_cd_id) { 
                    uomArray.splice(index, 1)
                    setValue(value + 1);
                }
    
            });
        })
        .catch((err) => {
            console.log(err);
            setMessageType("error");
            setMessage("Error deleting UOM type");
            handleAlertOpen(true);
        });
    };

    return (
        <div>
            <TextField variant="filled" label="Unit of Measure" onChange={handleNewUomType} className={classes.textField}></TextField>
            <TextField variant="filled" label="UOM Type CD" onChange={handleNewUomTypeCd} className={classes.textField}></TextField>
            <Button variant="contained" disabled={isDisabled()} className={classes.addButton} onClick={handleAddUom}>Add</Button>
            <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            >
            {uomArray.sort().map((row, index) => {
                return (
                <List key={index} className={classes.materialClassList}>
                    <ListItem button onClick={() => handleClick(index)}>
                        <ListItemText primary={row.uom_type}/>
                        {open === index ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Divider />
                    {open === index ? 
                    <Collapse in={true} timeout="auto" unmountOnExit>
                    <List className={classes.materialClassSubList} key={index}>
                        <ListItem>
                            <ListItemText  secondary="New UOM Type CD"/>
                            <TextField variant="filled" label="UOM Type CD" className={classes.textField} onChange={handleNewCodeTranslation}></TextField>
                            <Button variant="contained" disabled={cdIsDisabled()} className={classes.addButton} onClick={e => handleAddCodeTranslation(row.uom_type_cd)}>Add</Button>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <Typography variant="h5">
                                Delete UOM:
                            </Typography>
                            <Button variant="contained" className={classes.addButton} onClick={() => handleDeleteMasterUom(row.uom_type_cd_id)}>Delete</Button>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText  primary="Master UOM Type Code" secondary={row.uom_type_cd}/>
                        </ListItem>
                        <Divider />
                        {codeTranslationArray.map((row, i) => {
                            return(
                                row.target_value === uomArray[index].uom_type_cd &&
                                <div key={i}>
                                <ListItem>
                                    <ListItemText primary={"Alt. UOM Type Code"} secondary={row.source_value}/>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteUomType(row.source_value, "delete")}>
                                        <DeleteIcon className={classes.deleteButton}/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider/>
                                </div>
                            )
                        })}
                        <Divider />
                        <></>
                    </List>
                    </Collapse> :
                    <></>}
                    </List>
                    )})}
                </List>
        </div>
    )
};

export default UomSettings;