import React, { useState } from "react";

// MUI
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

// styles
import "../styles/CustomComponents.css";

const CustomSelect = (props) => {
  const { option, data, property, setUpdate } = props;
  const [value, setValue] = useState(option);

  const handleAccessChange = (e) => {
    const tValue = e.target.value;
    setValue(tValue);
    setUpdate(tValue);
  };

  return (
    <div className="cutsome__comp-select">
      <InputLabel id="demo-simple-select-label">{property}</InputLabel>
      <Select
        className="custom__select"
        labelId="demo-simple-select-label"
        id="demo-simple-select-placeholder-label"
        value={value}
        onChange={handleAccessChange}
        displayEmpty
      >
        {data.map((opt, i) => {
          return (
            <MenuItem key={i} value={opt}>
              {opt}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default CustomSelect;
