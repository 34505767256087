import React, { useEffect, useState, useContext } from "react";
import { Button, Dialog, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { AppContext } from "../../Context/AppContextProvide";
import axios from "axios";
import { baseUrl } from "../../Contants";
import { ProductStatsContext } from "../../Context/ProductsStatsContextProvider";
import { Close } from "@material-ui/icons";

export default function ProductStatsDialog(props) {
  const { user } = useContext(AppContext);
  const { days } = useContext(ProductStatsContext);
  const isLocationSelectable = user.access_type_cd === "a" && !!props.showLocationSelection;
  const [selectedDayRange, setSelectedDayRange] = useState(30);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [locations, setLocations] = useState([]);
  const [showLocationDropdown, setShowLocationDropdown] = useState(isLocationSelectable);
  const [ranks, setRanks] = useState();
  const dayRanges = [7, 30, 60, 90, 120];
  
  useEffect(() => {
    const getLocations = async () => {
      const result = await axios.get(`${baseUrl}/api/admin/locations`);

      if (result.status !== 200) {
        setLocations([]);
        setShowLocationDropdown(false);
        return;
      }

      setLocations(result.data.data);
      setShowLocationDropdown(true);
    }

    if (!props.isOpen) return;

    if (days) setSelectedDayRange(days);

    if (isLocationSelectable && props.isOpen) {
      getLocations();
    } else {
      setLocations([]);
    }
  }, [props.isOpen]);

  useEffect(() => {
    const getRanks = async () => {
      let url = `${baseUrl}/api/stats/products/ranks?`;
      url += `dayInterval=${selectedDayRange}`;
      url += `&locationId=${selectedLocation}`;
      url += `&productId=${props.product.product_uuid}`;
      url += `&manufacturerName=${props.product.manufacturer_name}`;
      url += `&materialClass=${props.product.material_class}`;
      const result = await axios.get(url);
      setRanks(result.data.data); 
    }

    if (props.product) {
      getRanks(); 
    }
    
  }, [selectedDayRange, selectedLocation, props.product]);

  const dayRangeOptions = dayRanges.map(o => {
    return <MenuItem key={o} value={o}>{o}</MenuItem>
  });
  const locationOptions = locations.map(l => {
    return (
      <MenuItem key={l.location_name} aria-label={l.location_name} value={l.location_id}>
        {l.location_name}
      </MenuItem>
    )
  });

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleOnClose}
      aria-labelledby="Product Stats Dialog"
      aria-describedby="Product Stats Dialog"
    >
      <DialogContent>
        <IconButton 
          onClick={props.handleOnClose} 
          aria-label="close" 
          style={{
            position: "absolute", 
            padding: "4px", 
            top: "20px",
            right: "20px"
          }}
          >
          <Close />
        </IconButton>
        <Typography variant="h6" style={{marginRight: "30px"}}>
          {props.product?.trade_name} {props.product?.style ? `(${props.product?.style})` : ""}
        </Typography>
        <Typography style={{marginBottom: "20px"}}>
          {props.product?.manufacturer_name} / {props.product?.material_class}
        </Typography>
        <Typography style={{marginBottom: "20px"}}>Customer specific stats for product views and rankings based on the selected timeframe{user.access_type_cd === "a" ? " and location options" : ""}.</Typography>

        <Grid container style={{marginBottom: "20px", justifyContent: "center"}} spacing={2}>
          <Grid xs={12} md={showLocationDropdown ? 6 : 12} lg={showLocationDropdown ? 6 : 12} style={{marginBottom: "15px"}} item>
            <FormControl variant="filled" style={{width: "100%", minWidth: "190px", maxWidth: "300px"}}>
              <InputLabel id="day-list-label">Days</InputLabel>
              <Select
                labelId="day-list-label"
                id="day-list"
                value={selectedDayRange}
                onChange={(e) => setSelectedDayRange(e.target.value)}
                label="Days"
              >
                {dayRangeOptions}
              </Select>
            </FormControl>
          </Grid>
          { showLocationDropdown ? (
              <Grid xs={12} md={6} lg={6} style={{marginBottom: "15px"}} item>
                <FormControl variant="filled" style={{width: "100%", minWidth: "190px", maxWidth: "300px"}}>
                  <InputLabel id="location-list-label">Location List</InputLabel>
                  <Select
                    labelId="location-list-label"
                    id="location-list"
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    label="Location List"
                  >
                    <MenuItem aria-label="All Locations" value="0">
                      All Locations
                    </MenuItem>
                    {locationOptions}
                  </Select>
                </FormControl>
              </Grid>
            ) : undefined 
          }
          
          <Grid xs={6} md={4} lg={4} item>
            <div style={{border: "1px solid black", borderRadius: "4px", textAlign: "center", padding: "10px", height: "80px "}}>
              <Typography style={{height: "50%"}} paragraph><b>Times Viewed</b></Typography>
              {ranks?.timesViewed ? ranks?.timesViewed : 0 }
            </div>
          </Grid>
          <Grid xs={6} md={4} lg={4} item>
            <div style={{border: "1px solid black", borderRadius: "4px", textAlign: "center", padding: "10px", height: "80px"}}>
              <Typography style={{height: "50%"}} paragraph><b>Material Class Rank</b></Typography>
              {ranks?.timesViewed ? ranks?.materialClassRank + " / " + ranks.materialClassCount : "N/A"}
            </div>
          </Grid>
          <Grid xs={6} md={4} lg={4} item>
            <div style={{border: "1px solid black", borderRadius: "4px", textAlign: "center", padding: "10px", height: "80px"}}>
              <Typography style={{height: "50%"}} paragraph><b>Manufacturer Rank</b></Typography>
              {ranks?.timesViewed ? ranks?.manufacturerRank + " / " + ranks.manufacturerCount : "N/A"}
            </div>
          </Grid>
          <Grid xs={6} md={4} lg={4} item>
            <div style={{border: "1px solid black", borderRadius: "4px", textAlign: "center", padding: "10px", height: "80px"}}>
              <Typography style={{height: "50%"}} paragraph><b>Overall Product Rank</b></Typography>
              {ranks?.timesViewed ? ranks?.productRank + " / " + ranks.productCount : "N/A"}
            </div>
          </Grid>
        </Grid>

        <div style={{marginBottom: "20px", textAlign: "right"}}>
          <Button 
            variant="outlined"
            onClick={props.handleOnClose}
            style={{margin: "5px 0px 5px 0px"}}
          >
            Close
          </Button>  
          <Button 
            variant="outlined" 
            href={`/product/${props.product?.product_uuid}`}
            target="_blank"
            style={{
              margin: "5px 0px 5px 15px", 
              color: "#28C4FC",
              backgroundColor: "#142E3E"
            }}
          >
            Product Page
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}