import React, { useState, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../../Contants";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

import { download, JsonToCsvConverter } from "../../Util/utilsFunctions";
import { AlertContext } from "../../Context/AlertContextProvider";
import { getAllLikesByCustomerId, getUserCheckoutListByCustomerId } from "../../Actions";

export default function ExportLeadDataDialog (props) {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  const handleDownload = async (downloadType) => {
    if (!props.lead?.lead_id) {
      setMessageType("error");
      setMessage("Error retrieving lead data.");
      handleAlertOpen();
      return;
    }

    const date = new Date();
    let data; 
    let filename = props.lead.name.replace(' ', '-');

    switch(downloadType) {
      case "info": 
        filename += '_info';

        data = [{
          name: props.lead.name,
          phone: props.lead.phone,
          email: props.lead.email,
          location_name: props.lead.location_name,
          status: props.lead.status,
          role_name: props.lead.role_name,
          rsa_name: props.lead.rsa_name,
        }];

        break;
      case "notes": 
        filename += '_notes-history';

        const notesResult = await axios(`${baseUrl}/api/leads/${props.lead.lead_id}/notes`);
        data = notesResult.data.leadNoteList.map(record => {
          return {
            name: props.lead.name,
            email: props.lead.email,
            phone: props.lead.phone,
            note: record.note,
            created_dttm: record.created_dttm,
            last_modified_dttm: record.last_modified_dttm,
            created_by: record.create_by,
            last_updated_by: record.last_updated_by,
          }
        });

        break;
      case "checkout": 
        filename += '_checkout-history';
        
        const checkoutResults = await getUserCheckoutListByCustomerId(props.lead.lead_id);
        data = checkoutResults.data.checkout_status.map(record => {
          return {
            status_name: record.status_name,
            name: record.name,
            email: record.email,
            phone: record.phone,
            location_name: record.location_name,
            style_name: record.style_name,
            material_class: record.material_class,
            manufacturer_name: record.manufacturer_name,
            check_out_dttm: record.check_out_dttm,
            check_in_dttm: record.check_in_dttm,
            days_checked_out: record.days_checked_out,
          }
        });

        break;
      case "favorites": 
        filename += '_favorites-history';

        const favoritesResult = await getAllLikesByCustomerId(props.lead.lead_id);
        data = favoritesResult.data.likes.map(record => {
          return {
            name: record.name,
            email: record.email,
            phone: record.phone,
            liked_dttm: record.liked_dttm,
            style_name: record.style_name,
            style_number: record.style_number,
            material_class: record.material_class,
            manufacturer_name: record.manufacturer_name,
            color_name: record.color_name,
            sku: record.sku,
            retail_price: record.retail_price,
            uom: record.uom,
          }
        });

        break;
    }

    filename += '_' + date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();

    if (!data) {
      setMessageType("error");
      setMessage("Error exporting lead data.");
      handleAlertOpen();
      return;
    }

    const csvData = JsonToCsvConverter(data);
    download(filename, csvData);
  };

  return (
    <Dialog 
      open={props.isOpen}
      onClose={() => props.handleOnClose}
      aria-labelledby="Export Lead Dialog"
      aria-describedby="Dialog for exporting different lead aspects"
    >
      <DialogTitle id="export-lead-dialog-title">Lead Data Export</DialogTitle>
      <DialogContent>
        <Typography style={{marginBottom: "10px"}}>Please click any of the following links to download the specific data as a .csv file.</Typography>
        <div style={{textAlign: "center"}}>
          <Button 
            variant="contained" 
            onClick={() => handleDownload("info")}
            style={{
              color: "#28C4FC",
              backgroundColor: "#142E3E",
              width: "80%",
              maxWidth: "200px",
              margin: "20px 10px 0px 10px",
            }}
          >
            User Info
          </Button>
          <Button 
            variant="contained" 
            onClick={() => handleDownload("notes")}
            style={{
              color: "#28C4FC",
              backgroundColor: "#142E3E",
              width: "80%",
              maxWidth: "200px",
              margin: "20px 10px 0px 10px",
            }}
          >
            Notes History
          </Button>
          <Button 
            variant="contained" 
            onClick={() => handleDownload("checkout")}
            style={{
              color: "#28C4FC",
              backgroundColor: "#142E3E",
              width: "80%",
              maxWidth: "200px",
              margin: "20px 10px 0px 10px",
            }}
          >
            Checkout History
          </Button>
          <Button 
            variant="contained" 
            onClick={() => handleDownload("favorites")}
            style={{
              color: "#28C4FC",
              backgroundColor: "#142E3E",
              width: "80%",
              maxWidth: "200px",
              margin: "20px 10px 0px 10px",
            }}
          >
            Favorites History
          </Button>
        </div>
      </DialogContent>
      <DialogActions style={{padding: "30px 24px"}}>
        <Button 
          variant="contained" 
          onClick={() => props.handleOnClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}