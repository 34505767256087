import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../Contants";

// styles
import "../styles/LeadManagement.css";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";

import { AlertContext } from "../../Context/AlertContextProvider";

export default function ChangeAssignmentDialog(props) {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const [rsaList, setRsaList] = useState([]);
  const [selectedRsa, setSelectedRsa] = useState("");
  const [rsaError, setRsaError] = useState();

  const getRsas = async () => {
    const result = await axios.get(`${baseUrl}/api/rsas`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error retrieving RSAs");
      handleAlertOpen();
      setRsaList([]);
      return;
    }

    setRsaError(undefined);
    setRsaList([...result.data.rsaList]);
  }

  const assignLead = async (lead_id, rsa_id) => {
    if (!lead_id || !rsa_id) {
      setMessageType("error");
      setMessage("Error assigning lead");
      handleAlertOpen();
      return;
    }

    const result = await axios.post(`${baseUrl}/api/leads/${lead_id}/assign/${rsa_id}`);

    if (result.status !== 200) {
      setMessageType("error");
      setMessage("Error assigning lead");
      handleAlertOpen();
      return;
    }

    setMessageType("success");
    setMessage("Lead assigned!");
    handleAlertOpen();
    props.refreshParent();
    props.handleOnClose();
  }

  useEffect(() => {
    if (!props.isAssignDialogOpen) return;

    getRsas();
  }, [props.isAssignDialogOpen])

  useEffect(() => {
    setSelectedRsa(props.lead?.rsa_user_id ?? "");
  }, [props.lead])

  const rsaOptions = rsaList.map(rsa => {
    return (
      <MenuItem value={rsa.userId} key={"rsa-" + rsa.userId}>
        <Typography>
          <b>{rsa.name}</b>
          <br></br>
          {rsa.email}
        </Typography> 
      </MenuItem>
    )
  });

  return (
    <Dialog
      open={props.isAssignDialogOpen}
      onClose={props.handleOnClose}
      aria-labelledby="New Note Dialog"
      aria-describedby="New Note Dialog"
    >
      <DialogTitle id="new-note-dialog-title">{ props.lead?.rsa_user_id ? "Transfer Lead" : "Assign Lead" }</DialogTitle>
      <DialogContent className="lead-management__dialog-content">
        <FormControl variant="filled" style={{width: "100%", minWidth: "190px"}}>
          <InputLabel id="rsa-list-label">RSA List</InputLabel>
          <Select
            labelId="rsa-list-label"
            id="rsa-list"
            value={selectedRsa}
            onChange={(e) => setSelectedRsa(e.target.value)}
          >
            <MenuItem aria-label="None" value="">
              <em>Please Select One Of The Following RSAs</em>
            </MenuItem>
            {rsaOptions}
          </Select>
        </FormControl>
        { rsaError ? (
            <Typography style={{margin: "20px 0px"}} paragraph>{rsaError}</Typography>
          ) : undefined
        }
      </DialogContent>
      <DialogActions style={{padding: "15px 24px"}}>
        <Button 
          variant="outlined"
          onClick={props.handleOnClose}
        >
          Cancel
        </Button>  
        <Button 
          variant="outlined" 
          onClick={() => assignLead(props.lead.lead_id, selectedRsa)} 
          style={{marginLeft: "20px", color: "rgba(9, 150, 42, 0.7)"}}
        >
          { props.lead?.rsa_user_id ? "Transfer Lead" : "Assign Lead" }
        </Button>
      </DialogActions>
    </Dialog>
  )
}