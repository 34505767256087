import { makeStyles } from "@material-ui/core";

const CustomerHomeStyles = makeStyles(theme => ({
    header: {
        background: '#142E3E',
        textAlign: 'center',
        borderRadius: 5,
        color: '#28C4FC',
        padding: 5,
        marginBottom: 50
    },
    headerText: {
        [theme.breakpoints.up('md')]: {
            fontSize: 30,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 20,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 15,
        }
     },
  }));


  export {CustomerHomeStyles};
