import React, { useState, createContext, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../Contants";
import { AppContext } from "./AppContextProvide";

export const HubSpokeContext = createContext();

export const HubSpokeContextProvider = ({ children }) => {
  const { user } = useContext(AppContext);
  const [spokeList, setSpokeList] = useState([]);
  const [selectedSpoke, setSelectedSpoke] = useState('');
  const isLoggedIn = user.user_id;

  useEffect(() => {
    async function getSpokeList() {
      const result = await axios(`${baseUrl}/api/hub/spokes`);
      setSpokeList(result.data.spokeList.map(a => a.name));
    }

    if (isLoggedIn) {
        getSpokeList();
    }
  }, [isLoggedIn]);

  return (
    <HubSpokeContext.Provider value={{ setSelectedSpoke, spokeList, selectedSpoke }}>
      {children}
    </HubSpokeContext.Provider>
  );
};
