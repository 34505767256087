import React, { useEffect, useState } from "react";

// Actions 
import { approveCheckout, cancelSampleCheckout, checkin, getUserCheckoutListByCustomerId } from "../../Actions";

//Styles
import "../styles/Checkout.css";
import { Button, Divider, FormControl, IconButton, InputLabel, Link, ListItemText, MenuItem, Select, TableRow, Typography, ListItem, List, Collapse, TablePagination } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, ChevronLeft, ChevronRight, DateRange, ExpandLess, ExpandMore, ThumbDown, ThumbUp } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export default function CheckoutHistory(props) {
  const [checkoutList, setCheckoutList] = useState([]);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("style_name");
  const [statusFilter, setStatusFilter] = useState("0");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getCheckoutListForUser = async (lead) => {
    const result = await getUserCheckoutListByCustomerId(lead.lead_id);
    setCheckoutList([...result.data.checkout_status]);
  }

  useEffect(() => {
    if (props.lead && refresh) {
      getCheckoutListForUser(props.lead);
      setRefresh(false);
    }
  }, [props.lead, refresh])

  const filterList = (list, status) => {
    if (status === 0) return list;

    return list.filter(i => i.status === (status - 1));
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleClick = (index) => {
    open === index ? setOpen(undefined) : setOpen(index);
  };

  const handleApproveCheckout = (productId, sampleCheckoutId) => {
    approveCheckout(productId, sampleCheckoutId)
      .then((result) => {
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const handleCancelCheckout = (sampleCheckoutId) => {
    cancelSampleCheckout(sampleCheckoutId)
      .then((result) => {
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const handleReturn = (checkoutId, productUuid) => {
    checkin(productUuid, checkoutId)
      .then((result) => {
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleApproveReturn = (productId, sampleCheckoutId) => {
    checkin(productId, sampleCheckoutId)
      .then((result) => {
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const checkoutRows = (paginatedList, view) => {
    if (view === 'm') {
      return paginatedList?.map((checkout, index) => (
        <React.Fragment key={checkout.sample_checkout_id}>
          <List style={{ padding: "0px" }}>
            <ListItem button onClick={() => handleClick(checkout.sample_checkout_id)} className="checkout__list-menu">
              <ListItemText primary={<Typography className="checkout__list-item-secondary">{checkout.style_name}</Typography>} secondary={checkout.location_name + " - " + checkout.status_name} />
              {open === checkout.sample_checkout_id ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {open === checkout.sample_checkout_id ? (
              <Collapse in={true} timeout="auto" unmountOnExit>
                <section style={{ backgroundColor: "#FFF", padding: "8px 16px"}}>
                  <Link to={`/product/${checkout.product_uuid}`} className="favorite__links" target="blank">
                    <Typography style={{fontWeight: "bold"}} className="checkout__list-item-stylename">{checkout.style_name}</Typography>
                  </Link>
                  <Typography>{checkout.manufacturer_name} / {checkout.material_class}</Typography>
                  <Typography style={{margin: "15px 0px"}}>
                    {checkout.days_checked_out > 10 && checkout.days_checked_out <= 15 ?
                      <DateRange style={{ color: "#EED202", verticalAlign: "middle", marginRight: "8px" }} /> :
                      checkout.days_checked_out >= 16 ?
                        <DateRange style={{ color: "#D0342C", verticalAlign: "middle", marginRight: "8px" }} /> :
                        <DateRange style={{ verticalAlign: "middle", marginRight: "8px" }}/>
                    }
                    {checkout.status === 1 ? "Days Checked Out" : "Days Pending"} - {checkout.days_checked_out}
                  </Typography>
                  { checkout.status === 0 ? (
                      <>
                        <Button variant="contained" style={{ color: "white", backgroundColor: "#23B246", borderColor: "#23B246", margin: "5px 15px 10px 0px" }} onClick={() => { handleApproveCheckout(checkout.product_uuid, checkout.sample_checkout_id) }}>
                          <ThumbUp style={{ color: "white", marginRight: "15px" }} /> Approve Checkout
                        </Button>
                        <Button variant="contained" style={{ color: "white", backgroundColor: "red", borderColor: "red", margin: "5px 15px 10px 0px" }} onClick={() => { handleCancelCheckout(checkout.sample_checkout_id) }}>
                          <ThumbDown style={{ color: "white", marginRight: "15px" }} /> Deny Checkout
                        </Button>
                      </>
                    ) : undefined
                  }
                  { checkout.status === 1 ? (
                      <Button variant="contained" style={{ backgroundColor: "#e0e0e0", borderColor: "#e0e0e0", margin: "5px 15px 10px 0px" }} onClick={() => {handleReturn(checkout.sample_checkout_id, checkout.product_uuid)}}>
                        Return Sample
                      </Button>
                    ) : undefined
                  }
                  { checkout.status === 2 ? (
                      <Button variant="contained" style={{ color: "white", backgroundColor: "#23B246", borderColor: "#23B246", margin: "5px 15px 10px 0px" }} onClick={() => {handleApproveReturn(checkout.product_uuid, checkout.sample_checkout_id)}}>
                        <ThumbUp style={{ color:"white", marginRight: "15px" }}/> Approve Return
                      </Button>
                    ) : undefined
                  }
                </section>
              </Collapse>
            ) : <></>}
          </List>
          <Divider></Divider>
        </React.Fragment>
      ))
    }
  }

  const handleChangePage = (e, page) => {
    setPage(page);
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
  }

  const paginationControls = (filteredList, view) => {
    const rowsPerPageOptions = [5, 10, 25];

    if (view === "m") {
      const buttonStyles = {
        backgroundColor: "rgba(0, 0, 0, 0.09)",
        margin: "0px 0px 0px 10px"
      };
      const isFirstPage = page === 0;
      const isLastPage = ((page + 1) * rowsPerPage) >= filteredList.length;
      const currentStart = Math.max(page * rowsPerPage, 1);
      const currentEnd = Math.min((page * rowsPerPage) + rowsPerPage, filteredList.length);

      return (
        <div style={{ margin: "0px 0px 20px", maxWidth: "400px" }}>
          <FormControl>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page"
              value={rowsPerPage}
              style={{ width: "125px" }}
              variant="filled"
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {rowsPerPageOptions.map(o => {
                return (
                  <MenuItem key={o} value={o}>{o}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <div style={{ float: "right" }}>
            <Typography style={{ display: "inline-block", verticalAlign: "middle", margin: "0px", textAlign: "center" }}>{currentStart} - {currentEnd} of {filteredList.length}</Typography>
            <IconButton variant="text" disabled={isFirstPage} style={buttonStyles} onClick={() => setPage(isFirstPage ? 0 : page - 1)}><ChevronLeft /></IconButton>
            <IconButton variant="text" disabled={isLastPage} style={buttonStyles} onClick={() => setPage(isLastPage ? page : page + 1)}><ChevronRight /></IconButton>
          </div>
        </div>
      )
    }

    return (
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={5}
          count={filteredList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableRow>
    )
  }

  if (!checkoutList?.length) return (
    <Typography>No checkout history currently available for this user</Typography>
  )

  const sortOptions = [
    {
      name: "Location",
      attribute: "location_name",
    }, 
    {
      name: "Style Name",
      attribute: "style_name",
    },
    {
      name: "Status",
      attribute: "status_name",
    },
    {
      name: "Days Checked Out/Pending",
      attribute: "days_checked_out",
    }
  ];
  const sortMenuItems = sortOptions.map(i => {
    return (
      <MenuItem key={i.attribute} value={i.attribute}>
        {i.name}
      </MenuItem>
    )
  })

  const filteredCheckoutList = filterList(checkoutList, parseInt(statusFilter));
  const sortedCheckoutList = stableSort(filteredCheckoutList, getComparator(order, orderBy));
  const paginatedRows = sortedCheckoutList.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);

  return (
    <>
      <div style={{display: "inline-block", margin: "10px 20px 20px 0px", verticalAlign: "middle", width: "60%", minWidth: "325px"}}>
        <ToggleButtonGroup value={statusFilter} aria-label="status filter" exclusive={true} onChange={(e, value) => {if(value) setStatusFilter(value)}} style={{height: "56px", width: "100%"}}>
          <ToggleButton value="0" aria-label="all history" style={{width: "25%"}}>
            All
          </ToggleButton>
          <ToggleButton value="1" aria-label="pending only" style={{width: "25%"}}>
            Pending
          </ToggleButton>
          <ToggleButton value="2" aria-label="checked out only" style={{width: "25%"}}>
            Checked Out
          </ToggleButton>
          <ToggleButton value="3" aria-label="returned only" style={{width: "25%"}}>
            Returned
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div style={{display: "inline-block", maxWidth: "500px", marginBottom: "10px", verticalAlign: "middle"}}>
        <FormControl variant="filled" style={{width: "150px", marginBottom: "10px", marginRight: "10px"}}>
          <InputLabel id="checkout-sort-list-label">Sort By</InputLabel>
          <Select
            labelId="checkout-sort-list-label"
            id="checkout-sort-list"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            variant="filled"
          >
            {sortMenuItems}
          </Select>
        </FormControl>
        <ToggleButtonGroup value={order} aria-label="sort order" exclusive={true} onChange={(e, value) => {if(value) setOrder(value)}} style={{ height: "56px", marginBottom: "10px"}}>
          <ToggleButton value="asc" aria-label="ascending" style={{width: "50%"}}>
            <ArrowUpward /> Asc
          </ToggleButton>
          <ToggleButton value="desc" aria-label="descending" style={{width: "50%"}}>
            <ArrowDownward /> Desc
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {paginationControls(filteredCheckoutList, "m")}
      <div style={{ overflowY: "auto", backgroundColor: "#EEEEEE", height: "80%", maxHeight: "450px" }}>
        {checkoutRows(paginatedRows, "m")}
      </div>
    </>
  )
}