import React from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";


// MUI
import OutlinedInput from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Context
import { AlertContext } from "../../Context/AlertContextProvider";

// CSS
import "../styles/Signin.css";
import { forgotPassword } from "../../Actions";

const Signin = () => {
  const history = useHistory();
  const [username, setUsername] = React.useState("");
  const { handleAlertOpen, setMessageType, setMessage } = React.useContext(
    AlertContext
  );

  const handleUsernameChange = (e) => setUsername(e.target.value);

  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      onSubmit();
    };
  }

  const onSubmit = () => {
    forgotPassword(username)
      .then((result) => {
        setMessageType("success");
        setMessage("Password reset instructions sent to email.");
        handleAlertOpen(true);
        history.push("/signin");
      })
      .catch((err) => {
        setMessageType("error");
        setMessage("Invalid username", err);
        handleAlertOpen(true);
      });
  };

  return (
    <div className="signin__container">
      <div className="signin__local" onKeyPress={handleKeyPress}>
        <div className="signin__container-header">
        <Typography variant="h2" align="center" className="signin__header-text">
          Forgot Password
        </Typography>
        </div>
        <label 
          className="register__label"
          htmlFor="confirm_pwd">
          User Name:
        </label>
        <OutlinedInput
          value={username}
          onChange={handleUsernameChange}
          className="signin__username"
          variant="outlined"
          type="text"
        />
        <div><hr /></div>
        <div className="signin_local-btn-container">
          <Button
            onClick={onSubmit}
            className="sigin__local-btn"
            variant="contained"
            disabled={!username ? true : false}
          >
            Submit
          </Button>
      </div>
    </div>
  </div>
  );
};

export default withRouter(Signin);