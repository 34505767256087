import React, { useState, createContext, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../Contants";
import { AppContext } from "./AppContextProvide";

export const UserBulletinContext = createContext();

export const UserBulletinContextProvider = ({ children }) => {
  const { user } = useContext(AppContext);
  const [bulletins, setBulletins] = useState([]);
  const [update, setUpdate] = useState(false);

  // //accesstypes
  const isLoggedIn = user.user_id;

  useEffect(() => {
    async function getBulletins() {
      const result = await axios(`${baseUrl}/api/user/bulletins`);
      setBulletins(result.data.data);
    }
    if (isLoggedIn) {
      getBulletins();
      setUpdate(false);
    }
  }, [isLoggedIn, update]);

  return (
    <UserBulletinContext.Provider value={{ bulletins, setUpdate }}>
      {children}
    </UserBulletinContext.Provider>
  );
};
